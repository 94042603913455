//---------------------------------------------------------------------------------------------------//
// import

//---------------------------------------------------------------------------------------------------//
// logic

/*
    let obj_language__trans_data = {
        category_1: {
            category_n: {
                trans_key_1: {
                    lang_code: 'transration text',
                    en: 'english arg_0({0}), arg_1({1})',
                    ko: '한글 인자_0({0}), 인자_1({1})',
                },
                trans_key_n: {
                    lang_code: 'transration text',
                    en: 'english arg_0({0}), arg_1({1})',
                    ko: '한글 인자_0({0}), 인자_1({1})',
                },
            },
        },
    };
*/

let s_lang__default = "en"; // en cn

let obj_lang__data = {
  language: {
    en: "English",
    ko: "한국어",
  },
  confirm: {
    en: "Confirm",
    ko: "확인",
  },
  cancel: {
    en: "Cancel",
    ko: "취소",
  },
  close: {
    en: "Close",
    ko: "닫기",
  },
  noDataText: {
    en: "No data",
    ko: "데이터없음",
  },
};

//---------------------------------------------------------------------------------------------------//
// export

export { s_lang__default, obj_lang__data };
