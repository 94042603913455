
import { Field, ErrorMessage, GenericValidateFunction } from "vee-validate";
import { defineComponent, computed, reactive, toRefs, watch, onMounted } from "vue";
import { v4 } from "uuid";

export default defineComponent({
  name: "FwCheckboxCard",
  components: {
    Field,
    ErrorMessage,
  },
  props: {
    class: {
      type: String,
      default: "",
    },
    modelValue: {
      // v-model(필수)
      type: [Array, Boolean],
      default: false,
    },
    name: {
      // 이름
      type: String,
      default: () => v4(),
    },
    rules: {
      // 유효성검사 함수
      type: Function,
      default: () => true,
    },
    showValid: {
      // 유효성검사 알림 메시지 보이기
      type: Boolean,
      default: false,
    },
    onIcon: {
      // active시 icon
      type: String,
      default: "check",
    },
    offIcon: {
      // inactive시 icon
      type: String,
      default: "checkbox-blank-outline",
    },
    reverse: {
      // 체크박스 위치 반전
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["click", "update:modelValue"],

  setup(props, { emit }) {
    const stateValid = reactive({
      inputValue: false,
      isValid: true,
    });

    function onClick(): void {
      if (props.disabled) {
        return;
      }

      stateValid.inputValue = !props.modelValue;
      emit("update:modelValue", !props.modelValue);
    }

    // ==========================================
    // rules 실행
    const rulesExecutor = (): GenericValidateFunction => {
      return props.rules(stateValid.inputValue);
    };

    // ==========================================
    // class & style

    const classes = computed(() => {
      return [
        props.class,
        props.reverse ? "reverse" : "",
        props.modelValue ? `fw-checkbox-card__checked no-hover` : "fw-checkbox-card__not-checked",
      ];
    });

    const onIconNone = computed(() => {
      return props.onIcon !== "none" && props.modelValue;
    });

    const offIconNone = computed(() => {
      return props.offIcon !== "none" && !props.modelValue;
    });

    const iconIsShow = computed(() => {
      return (props.onIcon && props.modelValue) || (props.offIcon && !props.modelValue);
    });

    const iconCheckbox = computed(() => {
      return props.modelValue ? props.onIcon : props.offIcon;
    });

    watch(
      () => stateValid.inputValue,
      (_val) => {
        emit("update:modelValue", _val);
      }
    );

    onMounted(() => {
      stateValid.inputValue = props.modelValue as boolean;
    });

    return {
      Field,
      ErrorMessage,
      rulesExecutor,
      ...toRefs(stateValid),

      classes,
      onClick,

      iconCheckbox,
      iconIsShow,
      onIconNone,
      offIconNone,
    };
  },
});
