<template>
  <div v-if="!mobile" class="fw-stock-info d-flex align-center flex-wrap px-6 py-4">
    <div class="market-symbol-wrap mr-5">
      <fw-symbol-img class="img-token" :asset-code="model.tokenCode" :folder-path="pathSymbol" size="40" />
      <fw-symbol-img class="img-market" :asset-code="model.marketCode" :folder-path="pathSymbol" size="40" />
    </div>

    <div>
      <p class="text-body-xl font-weight-bold text-uppercase mb-1">{{ model.tokenCode }} / {{ model.marketCode }}</p>
      <div class="d-flex align-center flex-wrap gap-2">
        <fw-num-formatter
          :class="computedGetPriceColor"
          class="text-title-xs font-weight-medium"
          :value="model.closePrice"
          :precision="model.tokenPrecision"
        />

        <div class="d-flex align-center" :class="computedGetPriceColor">
          <span class="font-weight-regular"> {{ computedGetPriceSign }} </span>
          <fw-num-formatter :value="model.changeRate" :precision="model.tokenPrecision" unit="%" sign />
        </div>

        <span :class="computedGetPriceColor">
          <fw-num-formatter
            class="font-weight-regular"
            :value="model.changePrice"
            :precision="model.tokenPrecision"
            sign
          />
        </span>
      </div>
    </div>

    <div class="l-spacer" />

    <div v-if="!$fw.display.smAndDown" class="d-flex flex-wrap text-right gap-5">
      <div>
        <div class="d-flex align-center flex-wrap mb-2">
          <p class="text-desc font-weight-regular mr-2">
            {{ $tt("stock_info.high_price") }}
          </p>
          <div class="l-spacer" />
          <p class="font-weight-regular text-rise">
            <fw-num-formatter
              class="font-weight-regular text-rise"
              :value="model.highPrice"
              :precision="model.tokenPrecision"
            />
          </p>
        </div>
        <div class="d-flex align-center flex-wrap">
          <p class="text-desc font-weight-regular mr-2">
            {{ $tt("stock_info.low_price") }}
          </p>
          <div class="l-spacer" />
          <p class="font-weight-regular text-fall">
            <fw-num-formatter
              class="font-weight-regular text-fall"
              :value="model.lowPrice"
              :precision="model.tokenPrecision"
            />
          </p>
        </div>
      </div>
      <div>
        <div class="d-flex align-center flex-wrap mb-2">
          <p class="text-desc font-weight-regular mr-2">
            {{ $tt("stock_info.volume_amount") }}
            <span class="text-uppercase"> ({{ model.tokenCode }}) </span>
          </p>
          <div class="l-spacer" />
          <p class="font-weight-regular">
            <fw-num-formatter
              class="font-weight-regular"
              :value="model.volumeAmount"
              :precision="model.tokenPrecision"
            />
          </p>
        </div>
        <div class="d-flex align-center flex-wrap">
          <p class="text-desc font-weight-regular mr-2">
            {{ $tt("stock_info.volume_price") }}
            <span> ($) </span>
          </p>
          <div class="l-spacer" />
          <p class="font-weight-regular">
            <fw-num-formatter class="font-weight-regular" :value="model.volumePrice" :precision="5" />
          </p>
        </div>
      </div>

      <!-- <div class="d-flex align-center justify-center">
        <fw-icon icon="star-outline" color="secondary" class="cursor-pointer" />
      </div> -->
    </div>
  </div>

  <div v-else>
    <div class="d-flex align-center flex-wrap gap-2">
      <fw-num-formatter
        :class="computedGetPriceColor"
        class="text-title-xs font-weight-medium"
        :value="model.closePrice"
        :precision="model.tokenPrecision"
        :unit="model.tokenCode"
      />

      <span :class="computedGetPriceColor">
        <fw-num-formatter
          class="font-weight-regular"
          :value="model.changePrice"
          :precision="model.tokenPrecision"
          sign
        />
      </span>

      <div class="d-flex align-center" :class="computedGetPriceColor">
        <span class="font-weight-regular"> {{ computedGetPriceSign }} </span>
        <fw-num-formatter :value="model.changeRate" :precision="model.tokenPrecision" unit="%" sign />
      </div>
    </div>
    <div class="d-flex align-center">
      <span class="text-desc mr-2"> ≈ $ - </span>
      <div class="l-spacer" />
      <span class="text-desc">
        <!-- Vol: $2031.32023 -->
      </span>
    </div>
  </div>
</template>

<script>
import { computed, ref, reactive, onMounted, toRefs } from "vue";

let obj_lang = {
  stock_info: {
    market_pair: {
      en: "Market Pair",
      ko: "마켓페어",
    },
    high_price: {
      en: "24h High",
      ko: "24시간 최고가",
    },
    low_price: {
      en: "24h Low",
      ko: "24시간 최저가",
    },
    volume_amount: {
      en: "24h Volume",
      ko: "24시간 거래량",
    },
    volume_price: {
      en: "24h Volume",
      ko: "24시간 거래대금",
    },
  },
};

export default {
  name: "CompTradeStockInfo",
  props: {
    "token-code": {
      type: String,
      default: "",
    },
    "market-code": {
      type: String,
      default: "",
    },
    pathSymbol: {
      type: String,
      default: "",
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.$fw.lang.add(obj_lang);
  },

  setup() {
    const state = reactive({
      model: {
        tokenCode: "",
        tokenName: "",
        tokenPrecision: 0,
        marketCode: "",
        marketName: "",
        closePrice: 0,
        changeRate: 0,
        changePrice: 0,
        highPrice: 0,
        lowPrice: 0,
        volumeAmount: 0,
        volumePrice: 0,
      },
      ui: {},
    });

    const api = {
      setStockInfo(
        _tokenCode,
        _tokenName,
        _tokenPrecision,
        _marketCode,
        _marketName,
        _closePrice,
        _changeRate,
        _changePrice,
        _highPrice,
        _lowPrice,
        _volumeAmount,
        _volumePrice
      ) {
        state.model.tokenCode = _tokenCode;
        state.model.tokenName = _tokenName;
        state.model.tokenPrecision = _tokenPrecision;
        state.model.marketCode = _marketCode;
        state.model.marketName = _marketName;
        state.model.closePrice = _closePrice;
        state.model.changeRate = _changeRate;
        state.model.changePrice = _changePrice;
        state.model.highPrice = _highPrice;
        state.model.lowPrice = _lowPrice;
        state.model.volumeAmount = _volumeAmount;
        state.model.volumePrice = _volumePrice;
      },
    };

    const computedGetPriceColor = computed(() => {
      const color_rise = "text-rise";
      const color_fall = "text-fall";
      const color_default = "";

      let change_percent = parseFloat(state.model.changeRate);

      if (isNaN(change_percent) || change_percent == 0.0) {
        return color_default;
      }

      if (change_percent > 0.0) {
        return color_rise;
      } else {
        return color_fall;
      }
    });

    const computedGetPriceSign = computed(() => {
      const color_rise = "▲";
      const color_fall = "▼";
      const color_default = "";

      let change_percent = parseFloat(state.model.changeRate);

      if (isNaN(change_percent) || change_percent == 0.0) {
        return color_default;
      }

      if (change_percent > 0.0) {
        return color_rise;
      } else {
        return color_fall;
      }
    });

    return {
      api,
      computedGetPriceColor,
      computedGetPriceSign,
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss">
@use "@/fw_config/config.scss" as *;

.fw-stock-info {
  background-color: $ctrl-stock-info-background-color;
}
.market-symbol-wrap {
  position: relative;

  .img-market {
    margin-left: -6px !important;
    z-index: 2;
  }
}
</style>
