<template>
  <div class="fw-table-wrapper" :class="classes">
    <div class="fw-table" ref="table">
      <table>
        <slot></slot>
      </table>
    </div>

    <div class="btn-scroll-wrap left" ref="arrow_left" @click="__button__click__move('move_left')">
      <slot name="arrow-left">
        <fw-button class="btn-scroll icon">
          <fw-icon icon="chevron-left"></fw-icon>
        </fw-button>
      </slot>
    </div>

    <div class="btn-scroll-wrap right" ref="arrow_right" @click="__button__click__move('move_right')">
      <slot name="arrow-right">
        <fw-button class="btn-scroll icon">
          <fw-icon icon="chevron-right" size="30"></fw-icon>
        </fw-button>
      </slot>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "FwTable",
  props: {
    "fix-first-col": {
      type: Boolean,
      default: false,
    },

    "fix-header": {
      type: Boolean,
      default: true,
    },

    "striped": {
      type: Boolean,
      default: false,
    },

    "hide-arrow": {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ui: {
        model: {
          total_column: 0,
          idx_current_column: 0,
        },
      },
    };
  },
  computed: {
    classes() {
      return {
        "table-fixed-first-column": this.fixFirstCol,
        "table-fixed-header": this.fixHeader,
        "table-striped": this.striped,
      };
    },
  },
  mounted() {
    this.__loaded();
  },
  methods: {
    __init() {
      //
    },

    __loaded() {
      if (!this.hideArrow) {
        this.event__init();
      }
    },

    __button__click__move(_direction) {
      let scroll_movement;

      let el_table = this.$refs.table;
      let el_tbody = el_table.getElementsByTagName("tbody")[0];
      let arr_el_tr = el_tbody.getElementsByTagName("tr");

      let width__current_column;

      if (this.fixFirstCol == true) {
        width__current_column =
          arr_el_tr[0].getElementsByTagName("td")[this.ui.model.idx_current_column + 1].clientWidth;
      } else {
        width__current_column = arr_el_tr[0].getElementsByTagName("td")[this.ui.model.idx_current_column].clientWidth;
      }

      if (_direction == "move_left") {
        scroll_movement = -width__current_column;

        --this.ui.model.idx_current_column;

        if (this.ui.model.idx_current_column < 0) {
          this.ui.model.idx_current_column = 0;
        }
      } else {
        scroll_movement = width__current_column;
        ++this.ui.model.idx_current_column;

        if (this.ui.model.idx_current_column == this.ui.model.total_column - 1) {
          this.ui.model.idx_current_column = this.ui.model.total_column - 2;
        }
      }

      el_table.scrollBy({
        left: scroll_movement,
        top: 0,
        behavior: "smooth",
      });
    },

    //===============================================================
    // ##event
    event__init() {
      this.event__reg__on_scroll();
      this.event__reg__on_resize();
    },

    event__reg__on_scroll() {
      let el_table = this.$refs.table;

      this.arrow__visibility__check(el_table);

      el_table.addEventListener("scroll", () => {
        this.arrow__init();
      });
    },

    event__reg__on_resize() {
      let el_table = this.$refs.table;

      const resizeObserver = new ResizeObserver(() => {
        this.arrow__init();
      });

      resizeObserver.observe(el_table);
    },

    //===============================================================
    // ##arrow
    arrow__init() {
      try {
        let el_table = this.$refs.table;
        this.ui.model.total_column = el_table.getElementsByTagName("th").length;
        this.arrow__visibility__set();
        this.arrow__visibility__check(el_table);
      } catch (error) {
        //
      }
    },

    // 스크롤이 있는지 확인 후 화살표 추가 및 제거
    arrow__visibility__set() {
      let el_table = this.$refs.table;
      let el_tbody = el_table.getElementsByTagName("tbody")[0];
      let arr_el_tr = el_tbody.getElementsByTagName("tr");

      let width__client = el_table.clientWidth;
      let width__scroll = el_table.scrollWidth;

      let el_arrow_left = this.$refs.arrow_left;
      let el_arrow_right = this.$refs.arrow_right;

      if (width__client < width__scroll) {
        // show
        el_arrow_left.classList.add("visible");
        el_arrow_right.classList.add("visible");

        if (this.fixFirstCol) {
          let el_td_first = arr_el_tr[0].getElementsByTagName("td")[0];
          let width__fiexed_column = el_td_first.clientWidth;
          let arrow_right__left__rem =
            width__fiexed_column / parseFloat(getComputedStyle(document.documentElement).fontSize) + 1 + "rem";

          el_arrow_left.style.left = arrow_right__left__rem;
        } else {
          el_arrow_left.style.left = 0;
        }
      } else {
        // hide
        el_arrow_left.classList.remove("visible");
        el_arrow_right.classList.remove("visible");
      }
    },

    // 스크롤이 좌/우 마지막일 경우 흐림 처리
    arrow__visibility__check(_el_table) {
      let width__scroll = _el_table.scrollWidth;
      let width__offset_scroll = _el_table.offsetWidth;
      let pos__scroll = _el_table.scrollLeft;

      let el_arrow_left = this.$refs.arrow_left;
      let el_arrow_right = this.$refs.arrow_right;

      if (pos__scroll == 0) {
        el_arrow_left.classList.add("scroll-end");
      } else {
        el_arrow_left.classList.remove("scroll-end");
      }

      if (width__scroll <= pos__scroll + width__offset_scroll) {
        el_arrow_right.classList.add("scroll-end");
      } else {
        el_arrow_right.classList.remove("scroll-end");
      }
    },
  },
});
</script>

<style lang="scss">
@use "sass:map";
@use "@/fw/scss/util.scss" as *;
@use "@/fw_config/config.scss" as *;

//------------------------------------------//
// default
$table__cell__pad: 10 !default;
//===========================================//

.fw-table-wrapper {
  position: relative;
  height: inherit;

  &.table-fixed-header {
    thead th {
      position: sticky;
      top: 0px;
    }
  }

  &.table-fixed-first-column {
    th:first-child,
    td:first-child {
      position: sticky;
      left: 0;
    }
  }

  &.table-fixed-first-column.table-fixed-header {
    thead th:first-child {
      position: sticky;
      top: 0px;
      left: 0px;
      z-index: 2;
    }
  }

  &.table-striped {
    tr:nth-of-type(even) {
      td {
        background-color: $ctrl-table-td-background-striped-color;
      }
    }
  }

  .fw-table {
    position: relative;
    overflow: auto;
    height: inherit;

    table {
      width: 100%;
      border-spacing: 0;
      border-collapse: collapse;

      th {
        padding: $ctrl-table-th-padding;
        color: $ctrl-table-th-font-color;
        background-color: $ctrl-table-th-background-color;
      }

      td {
        text-align: center;
        padding: $ctrl-table-td-padding;
        background-color: $ctrl-table-td-background-color;
        color: $ctrl-table-td-font-color;
        border-bottom: px(1) solid $ctrl-table-td-border-color;
      }

      tr {
        &.active {
          td {
            background-color: $ctrl-table-td-background-active-color;
          }
        }
        &:hover {
          td {
            background-color: $ctrl-table-td-background-hover-color;
          }
        }
      }
    }
  }

  .btn-scroll-wrap {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;

    .btn-scroll {
      color: map.get($color-text, "normal");
    }

    &.visible {
      display: block;
    }

    &.left {
      left: rem(10);
    }

    &.right {
      right: rem(10);
    }

    &.scroll-end {
      opacity: 0.3;
    }
  }
}
</style>
