<template>
  <div class="fw-ctrl fw-select no-hover pos-relative" :class="classes" v-ripple>
    <div class="select">
      <slot name="prepend-inner"></slot>
      <slot name="selected" :selected="computedModelValue" }}>
        <span>
          {{ computedModelValue }}
        </span>
      </slot>
      <span v-if="computedModelValue == '' || computedModelValue == null">
        {{ placeholder }}
      </span>
    </div>
    <div>
      <fw-icon class="arrow-icon" icon="chevron-down" />
    </div>

    <fw-dropdown-menu @change="dropdownOnChange" :click-on-close="clickOnClose">
      <div class="option-box overflow-auto" :max-height="optionHeight">
        <slot name="options" :options="options" :onClick="onClickOption">
          <div
            class="option px-3 py-2"
            v-for="(option, key) in options"
            :key="key"
            :class="{ selected: option == computedModelValue }"
            v-ripple
            @click="onClickOption(option)"
          >
            {{ option }}
          </div>
        </slot>
      </div>
    </fw-dropdown-menu>
  </div>
</template>

<script>
import { defineComponent, computed, ref } from "vue";

export default defineComponent({
  name: "FwSelect",
  props: {
    modelValue: {},
    options: {},
    optionHeight: {
      type: [String, Number],
      default: 150,
    },
    clickOnClose: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
    },
    disabled: Boolean,
  },

  emits: ["change", "click", "update:modelValue"],

  setup(props, { emit }) {
    const classes = computed(() => {
      return [
        props.disabled ? "disabled" : "",
        dropdownIsOpen.value ? "focus" : "",
      ];
    });

    let computedModelValue = computed({
      get() {
        return props.modelValue;
      },
      set(_value) {
        emit("update:modelValue", _value);
      },
    });

    function onClick() {
      emit("click");
    }

    function onClickOption(_value) {
      computedModelValue = _value;
      emit("update:modelValue", _value);
      emit("change", _value);
    }

    let dropdownIsOpen = ref(false);

    function dropdownOnChange(_is_open) {
      dropdownIsOpen.value = _is_open;
    }

    return {
      classes,
      computedModelValue,
      onClick,
      onClickOption,
      dropdownIsOpen,
      dropdownOnChange,
    };
  },
});
</script>

<style lang="scss">
@use "sass:map";
@use "@/fw_config/config.scss" as *;

.fw-select {
  display: flex;
  cursor: pointer;

  &:hover {
    filter: none !important;
  }

  .select {
    flex: 1;
    color: map.get($color-text, "normal") !important;
  } 

  .option-box {
    background-color: $ctrl-options-box-background !important;
    border-radius: inherit;
    color: map.get($color-text, "normal") !important;
  }
}
</style>
