<template>
  <div class="fw-tabs" :class="classes" :style="styles">
    <fw-slide :displacement="0.5" :button-between="false" :style="styles">
      <slot></slot>
    </fw-slide>
  </div>
</template>

<script>
import { defineComponent, computed, onMounted } from "vue";
import { makeDimensionProps, useDimension } from "@/fw/composables/dimensions";
import { makeRoundedProps, useRounded } from "@/fw/composables/rounded";

export default defineComponent({
  props: {
    modelValue: {
      type: [String, Number],
      default: "",
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    activeClass: {
      type: String,
      default: "",
    },
    noBottomLine: {
      type: Boolean,
      default: false,
    },
    ...makeDimensionProps(),
    rounded: {
      type: [String, Boolean],
      default: false,
    },
  },
  computed: {
    selected: {
      get() {
        return this.modelValue;
      },
      set(_value) {
        this.$emit("update:modelValue", _value);
      },
    },
  },

  mounted() {
    if (this.modelValue == "" || typeof this.modelValue == "undefined") {
      // this.selected = this.$slots.default()[0].children[0].props.value;
    }
    this.$emit("loaded", this.selected);
  },

  methods: {
    onClickTab(_value) {
      this.selected = _value;
      this.$emit("change", this.selected);
    },
  },

  setup(props, { emit }) {
    const { dimensionStyles } = useDimension(props);
    const { roundedClasses } = useRounded(props);

    const classes = computed(() => {
      return [
        roundedClasses.value,
        {
          "fw-tab--full-width": props.fullWidth,
          "fw-tab--no-bottom-line": props.noBottomLine,
        },
      ];
    });

    const styles = computed(() => {
      return [dimensionStyles.value];
    });

    return {
      classes,
      styles,
    };
  },
});
</script>

<style lang="scss">
@use "sass:map";
@use "@/fw/scss/util.scss" as *;
@use "@/fw_config/config.scss" as *;

.fw-tabs {
  &.fw-tab--full-width {
    .fw-tab {
      flex: 1;
      justify-content: center;
    }
  }

  &.fw-tab--no-bottom-line {
    .fw-tab {
      border-bottom: 0;
    }
  }

  .fw-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem(10) rem(20);
    font-size: rem($ctrl-tab-font-size);
    font-weight: 600;
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
    color: $ctrl-tab-font-color;
    border-bottom: px(2) solid $ctrl-tab-font-color;

    &.selected {
      color: $ctrl-tab-active-font;
      border-bottom: px(2) solid $ctrl-tab-active-font;
    }

    &.selected.synchronize-font-color {
      border-bottom: px(2) solid currentColor !important;
    }

    .router-link-active {
      @at-root .fw-tab:has(.router-link-active) {
        color: $ctrl-tab-active-font !important;
        border-bottom: px(2) solid $ctrl-tab-active-font !important;
      }
    }
  }

  .fw-slide-btn {
    &.fw-slide-btn-prev {
      color: $ctrl-tab-slide-btn-background;
    }

    &.fw-slide-btn-next {
      color: $ctrl-tab-slide-btn-background;
    }
  }

  .fw-slide-container {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
    clip-path: unset !important;
    -webkit-clip-path: unset !important;
  }
}

.fw-tab {
  a {
    text-decoration: none;
    color: inherit;
  }
}
</style>
