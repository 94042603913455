
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "FwTextCard",
  props: {
    value: {},
  },

  emits: ["click"],

  setup(props, { emit }) {
    function onClick(_event: Event): void {
      emit("click", _event);
    }

    return {
      onClick,
    };
  },
});
