import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fw-chip__value py-0 px-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "l-card fw-ctrl fw-chip text-center",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.value), 1)
    ])
  ]))
}