<template>
  <teleport to="body">
    <section id="fw-snackbar--container" :class="[generatedBaseClasses]" class="fw-snackbar">
      <transition-group name="fw-snackbar-message" tag="div">
        <SnackbarMessage
          v-for="message in messages"
          :key="message.id"
          :message="message"
          :message-class="messageClass"
          :color="message.type"
          :background="message.type"
          :dense="dense"
          :border-class="borderClass"
          @dismiss="remove($event, true)"
        />
        <!-- background="snackbar" -->
      </transition-group>
    </section>
  </teleport>
</template>

<script>
import { defineComponent, computed, onMounted, onUnmounted } from "vue";

import SnackbarMessage from "./fw-snackbar-item.vue";

import { messages } from "./fw-snackbar__service.js";
import { EventBus } from "@/fw/js/util";

export default defineComponent({
  components: {
    SnackbarMessage,
  },
  props: {
    top: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    attach: {
      type: [String, HTMLElement],
      default: "body",
    },
    border: {
      type: String,
      default: "",
      validator: (v) => ["top", "bottom", "left", "right", ""].includes(v),
    },
    duration: {
      type: Number,
      default: null,
    },
    messageClass: {
      type: String,
      default: "",
    },
    zIndex: {
      type: Number,
      default: 10000,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    groups: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    background: {
      type: String,
      default: "snackbar",
    },
  },

  emits: ["added", "dismissed", "removed", "cleared"],

  setup(props, { emit }) {
    const generatedBaseClasses = computed(() => {
      return {
        "is-top": props.top,
        "is-bottom": props.top === false && props.bottom,
        "is-left": props.left,
        "is-right": props.left === false && props.right,
        "is-middle": props.top === false && props.bottom === false,
        "is-center": props.left === false && props.right === false,
        "has-shadow": props.shadow,
      };
    });

    const borderClass = computed(() => (props.border ? `border-${props.border}` : ""));

    const hashCode = (s) => Math.abs(s.split("").reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0));

    let messageId = 1;

    onMounted(() => {
      EventBus.$on("add", (ev) => {
        emit("added", ev);
        if (!ev.group) {
          ev.group = hashCode(`${ev.type}${ev.title}${ev.text}`).toString(16);
        }
        // If there's a default duration and no message duration is set, use the default
        if (props.duration && !ev.duration && ev.duration !== 0) {
          ev.duration = props.duration;
        }
        // Find the existing message if one with the same group-key already exists
        const existingGroup = ev.group && messages.value.find((msg) => msg.group === ev.group);

        if (props.groups === false || !existingGroup) {
          const message = {
            ...ev,
            id: messageId,
            count: 1,
          };
          if (props.reverse) {
            messages.value.unshift(message);
          } else {
            messages.value.push(message);
          }
          messageId++;
        } else {
          existingGroup.count++;
        }
      });

      EventBus.$on("clear", () => {
        emit("cleared");
        messages.value = [];
      });
    });

    onUnmounted(() => {
      EventBus.$off("add");
      EventBus.$off("clear");
    });

    const remove = (ev, wasDismissed = false) => {
      if (wasDismissed) {
        emit("dismissed", ev);
      } else {
        emit("removed", ev);
      }

      messages.value = messages.value.filter((message) => {
        return message.id !== ev.id;
      });
    };

    return {
      messages,
      generatedBaseClasses,
      borderClass,
      remove,
    };
  },
});
</script>

<style lang="scss">
@use "sass:map";
@use "@/fw/scss/util.scss" as *;
@use "@/fw_config/config.scss" as *;

/* TODO: Implement BEM properly */

#fw-snackbar--container {
  position: fixed;
  margin: rem(16) rem(16) rem(0) rem(16);
  padding: 0;
  z-index: 10000;
  pointer-events: none;

  &.is-top {
    top: 0;
  }

  &.is-bottom {
    bottom: 0;
  }

  &.is-left {
    left: 0;
  }

  &.is-right {
    right: 0;
  }

  &.is-middle {
    top: 50%;
    transform: translateY(-50%);
  }

  &.is-center {
    left: 50%;
    transform: translateX(-50%);
  }
}

.fw-snackbar-message {
  display: flex;
  position: relative;
  margin-bottom: rem(10);
  background-color: $ctrl-snackbar-background-color;
  border-radius: px($ctrl-snackbar-border-radius);
  color: $ctrl-snackbar-font-color;
  pointer-events: all;

  &-wrapper {
    align-items: center;
    border-radius: inherit;
    display: flex;
    padding: rem(16);
  }

  &-title {
    font-weight: bold;
  }

  &-content {
    display: flex;
    flex-flow: column;
    width: min(50vw, 350px);
  }
}

.fw-snackbar-message-enter-from {
  opacity: 0;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
}
.fw-snackbar-message-leave-from {
  max-height: 1000px;
}
.fw-snackbar .fw-snackbar-message.fw-snackbar-message-leave-to {
  max-height: 0;
  opacity: 0;
  margin-bottom: 0;
  overflow: hidden;

  .fw-snackbar-message-badge {
    opacity: 0;
  }
}
.fw-snackbar-message-enter-active {
  transition: transform 0.3s, opacity 0.3s;
}
.fw-snackbar-message-leave-active {
  transition: max-height 0.45s cubic-bezier(0, 1, 0, 1), opacity 0.3s, margin-bottom 0.3s;
}

@keyframes headShake {
  0% {
    transform: translate(-25%, -25%);
  }

  6.5% {
    transform: translate(calc(-25% - 6px), -25%) rotateY(-9deg);
  }

  18.5% {
    transform: translate(calc(-25% + 5px), -25%) rotateY(7deg);
  }

  31.5% {
    transform: translate(calc(-25% - 3px), -25%) rotateY(-5deg);
  }

  43.5% {
    transform: translate(calc(-25% + 2px), -25%) rotateY(3deg);
  }

  50% {
    transform: translate(-25%, -25%);
  }
}
.shake-baby-shake .fw-snackbar-message-badge {
  animation-timing-function: ease-in-out;
  animation-name: headShake;
  animation-duration: 1s;
  animation-fill-mode: both;
}
</style>
