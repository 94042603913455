<template>
  <div class="d-flex flex-column fill-height">
    <div class="d-flex align-center mb-4">
      <fw-checkbox
        v-model="ui.form.select.order_type.value"
        class="status-primary mr-2"
        value="limit"
        :label="$tt('sell_form.limit')"
      />

      <fw-checkbox v-model="ui.form.select.order_type.value" class="status-primary" value="market" :label="$tt('sell_form.market')" />
    </div>

    <fw-form>
      <fw-input
        v-if="ui.form.select.order_type.value === 'limit'"
        v-model="ui.form.inp.price.value"
        class="size-lg inp-order-form mb-3"
        name="buy_price"
        numberOnly
        price-format
        positive
        reverse
        :placeholder="marketCode"
        :precision="marketPrecision"
        @keyup="__inp__keyup__price"
        @click:clear="__button__click__clear__price"
      >
        <template #prepend-inner>
          <span class="text-desc text-small text-no-wrap ml-2" style="margin-top: 2px">
            {{ $tt("sell_form.price") }}
          </span>
        </template>
      </fw-input>

      <fw-input
        v-model="ui.form.inp.amount.value"
        class="size-lg inp-order-form mb-3"
        name="buy_amount"
        numberOnly
        price-format
        positive
        reverse
        :placeholder="tokenCode"
        :precision="tokenPrecision"
        @keyup="__inp__keyup__amount"
        @click:clear="__button__click__clear__amount"
      >
        <template #prepend-inner>
          <span class="text-desc text-small text-no-wrap ml-2" style="margin-top: 2px">
            {{ $tt("sell_form.amount") }}
          </span>
        </template>
      </fw-input>
    </fw-form>

    <fw-input
      v-model="ui.form.inp.total.value"
      class="size-lg inp-order-form mb-4"
      name="buy_amount"
      numberOnly
      price-format
      positive
      reverse
      :placeholder="marketCode"
      :precision="marketPrecision"
      @keypress="__inp__keypress__total"
      @keyup="__inp__keyup__total"
    >
      <template #prepend-inner>
        <span class="text-desc text-small text-no-wrap ml-2" style="margin-top: 2px">
          {{ $tt("sell_form.total") }}
        </span>
      </template>
    </fw-input>

    <div class="d-flex">
      <div class="l-spacer" />
      <fw-select
        class="mb-5"
        max-width="130"
        v-model="ui.percents.value"
        :options="ui.percents.items"
        placeholder="- %"
        @change="__select__click__percent"
      >
      </fw-select>
    </div>

    <div class="l-spacer" v-if="!$fw.display.smAndDown" />

    <div class="d-flex align-center mb-2">
      <span class="text-desc mr-1">
        {{ $tt("sell_form.available") }}
      </span>
      <div class="l-spacer" />

      <fw-num-formatter
        class="text-desc"
        :value="model.balance"
        :precision="tokenPrecision"
        :unit="tokenCode"
      />
    </div>

    <!-- <div class="d-flex align-center mb-2">
      <span class="text-desc mr-1">
        {{ $tt("sell_form.min_order") }}
      </span>
      <div class="l-spacer" />

      <fw-num-formatter
        class="text-desc"
        :value="0.001"
        :precision="marketPrecision"
        :unit="marketCode"
      />
    </div> -->

    <div class="d-flex align-center mb-2">
      <span class="text-desc mr-1">
        {{ $tt("sell_form.fee") }}
      </span>
      <div class="l-spacer" />

      <fw-num-formatter class="text-desc" :value="model.fee_info.fee" :precision="marketPrecision" unit="%" />
    </div>

    <div>
      <fw-button class="bg-trade-sell w-100 size-xl" @click="__button__click__order">
        <span class="mr-1">
          {{ $tt("sell_form.sell") }}
        </span>

        <span class="text-uppercase">
          {{ tokenCode }}
        </span>
      </fw-button>
    </div>

    <modal-order-confirm
      v-model="ui.modal.order_confirm_info.is_show"
      :order-confirm-info="ui.model.order_confirm_info"
      :path-symbol="pathSymbol"
      @click:continue="__button__click__order_confirm"
    />
  </div>
</template>

<script>
import ModalOrderConfirm from "./fw-order-form-confirm-popup.vue";

let obj_lang = {
  sell_form: {
    market: {
      en: "Market",
      ko: "시장가",
    },

    limit: {
      en: "Limit",
      ko: "지정가",
    },

    available: {
      en: "Available",
      ko: "사용가능",
    },

    min_order: {
      en: "Min Order",
      ko: "최소 주문",
    },

    fee: {
      en: "Fee",
      ko: "수수료",
    },

    price: {
      en: "Price",
      ko: "가격",
    },

    amount: {
      en: "Qty",
      ko: "수량",
    },

    total: {
      en: "Total",
      ko: "총 금액",
    },

    reset: {
      en: "Reset",
      ko: "초기화",
    },

    sell: {
      en: "Sell",
      ko: "매도",
    },

    order_success: {
      en: "Order Success",
      ko: "주문 성공",
    },
  },
};

export default {
  name: "CompOrderSellForm",
  components: {
    ModalOrderConfirm,
  },

  props: {
    "token-code": {
      type: String,
      default: "-",
    },

    "market-code": {
      type: String,
      default: "-",
    },

    "token-name": {
      type: String,
      default: "-",
    },

    "market-name": {
      type: String,
      default: "-",
    },

    "token-precision": {
      type: [Number, String],
      default: 8,
    },

    "market-precision": {
      type: [Number, String],
      default: 8,
    },

    "balance-available": {
      type: [Number, String],
      default: "-",
    },

    "order-type": {
      type: String,
      default: "limit", // limit, market
    },

    "hide-clear-btn": {
      type: Boolean,
      default: false,
    },

    "hide-symbol": {
      type: Boolean,
      default: false,
    },

    "hide-price-ticker": {
      type: Boolean,
      default: false,
    },

    "price-now": {
      type: [Number, String],
      default: "-",
    },

    "path-symbol": {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      model: {
        fee_info: {
          fee: "-", // 수수료
          fee_type: "-", // 수수료 타입
        },
        precision: {
          asset: 8,
          market: 8,
        },

        balance: 0,
      },

      ui: {
        model: {
          order_confirm_info: {
            token_code: "-",
            token_name: "-",
            market_code: "-",
            order_way: "-",
            order_fee: "-",
            order_type: "-",
            order_price: "-",
            order_amount: "-",
            order_total: "-",
          },
        },

        percents: {
          value: "Max",
          items: ["10%", "25%", "50%", "75%", "Max"],
        },

        form: {
          inp: {
            price: {
              value: "",
            },

            amount: {
              value: "",
            },

            total: {
              value: "",
            },
          },

          select: {
            order_type: {
              value: "limit",
              items: ["limit", "market"],
            },
          },
        },

        modal: {
          order_confirm_info: {
            is_show: false,
          },
        },
      },
    };
  },

  created() {
    this.__init();
  },

  mounted() {
    this.__loaded();
  },

  computed: {
    is_disabled__order() {
      let balance_able = this.model.balance;
      let b_balance_able = this.$fw.big(balance_able);

      if (balance_able == 0 || b_balance_able.cmp(0) == -1) {
        return true;
      }

      let total_price = this.$fw.func.num.comma.remove(this.ui.form.inp.total.value);

      if (total_price == 0 || total_price == "") {
        return true;
      }

      let b_total_price = this.$fw.big(total_price);

      if (b_total_price == 0 || b_total_price.cmp(0) == -1) {
        return true;
      }

      if (b_balance_able.cmp(b_total_price) == -1) {
        this.$emit("error:not-enough-balance");
        return true;
      }

      return false;
    },

    is_order_type__limit() {
      return this.ui.form.select.order_type.value == "limit";
    },
  },

  watch: {
    orderType() {
      this.order_type__init();
    },
  },

  methods: {
    __init() {
      this.$fw.lang.add(obj_lang);
    },

    __loaded() {
      this.order_type__init();
    },

    __select__change__order_type() {
      this.$_init_reset();
    },

    __inp__keyup__price() {
      this.calc__total_price();
      this.ui.percents.value = "";
    },

    __inp__keyup__amount() {
      this.calc__total_price();
      this.ui.percents.value = "";
    },

    __inp__keypress__total(_event) {
      _event = _event ? _event : window.event;

      let price = this.ui.form.inp.price.value;
      let order_type = this.ui.form.select.order_type.value;

      if (order_type == "limit" && price == "") {
        _event.preventDefault();
      }

      return true;
    },

    __button__click__clear__price() {
      this.ui.form.inp.price.value = "";
      this.calc__total_price();
    },

    __button__click__clear__amount() {
      this.ui.form.inp.amount.value = "";
      this.calc__total_price();
    },

    __inp__keyup__total(_event) {
      _event = _event ? _event : window.event;
      let order_type = this.ui.form.select.order_type.value;

      try {
        let price;

        if (order_type == "limit") {
          price = this.$fw.func.num.comma.remove(this.ui.form.inp.price.value);
        } else {
          price = this.$fw.func.num.comma.remove(this.priceNow);
        }

        let total_price = this.$fw.func.num.comma.remove(_event.target.value);

        let total_price__removed_comma = this.$fw.func.num.comma.remove(total_price);
        let price__removed_comma = this.$fw.func.num.comma.remove(price);

        let b_total_price = this.$fw.big(total_price__removed_comma);
        let b_price = this.$fw.big(price__removed_comma);

        let b_amount = b_total_price.div(b_price);
        this.ui.form.inp.amount.value = this.$fw.func.num.comma.insert(b_amount.round(this.tokenPrecision));
      } catch (error) {
        // console.log(error);
      }

      return true;
    },

    __select__click__percent(_percent) {
      this.ui.percents.value = _percent;
      this.calc__balance_able__to_amount();
    },

    __button__click__order() {
      // ##임시-데모
      this.$store.commit("UI/showPrepareService");

      // if (this.is_disabled__order) {
      //   return;
      // }

      // this.order_confirm_info__init();
    },

    __button__click__order_confirm() {
      this.$snackbar.add({
        type: "success",
        title: "",
        text: this.$tt("sell_form.order_success"),
      });
      this.ui.modal.order_confirm_info.is_show = false;
    },

    __inp__clear__price() {
      this.ui.form.inp.price.value = "";
      this.calc__total_price();
    },

    __inp__clear__amount() {
      this.ui.form.inp.amount.value = "";
      this.calc__total_price();
    },

    __inp__clear__total() {
      this.ui.form.inp.total.value = "";
      this.ui.form.inp.price.value = "";
      this.ui.form.inp.amount.value = "";
    },

    //======================================================================
    // ##methods

    // 주문폼 - 매수

    // 기본 설정
    $_init() {
      this.$_init_reset();
    },

    // 값 초기화
    $_init_reset() {
      this.ui.form.inp.price.value = "";
      this.ui.form.inp.amount.value = "";
      this.ui.percents.value = "";
      this.ui.form.inp.total.value = 0;
    },

    // 사용가능(매도가능) 잔고 설정
    $_set_available_balance(
      _amount // 현재 토큰 사용가능 잔고
    ) {
      try {
        this.model.balance = this.$fw.big(_amount).round(this.tokenPrecision);
      } catch (e) {
        //
      }
    },

    $_set_fee_info(
      _fee, // 수수료
      _fee_type // 수수료 타입
    ) {
      this.model.fee_info.fee = _fee;
      this.model.fee_info.fee_type = _fee_type;
    },

    // 주문가격 직접 입력 - 함수를 이용해 input box에 입력함
    $_set_order_price(
      _price // 주문 가격
    ) {
      this.ui.form.inp.price.value = this.$fw.func.num.comma.insert(_price);
      this.calc__total_price();
      this.ui.percents.value = "";
    },

    // 주문수량 직접 입력 - 함수를 이용해 input box에 입력함
    $_set_order_amount(
      _amount // 주문 수량
    ) {
      this.ui.form.inp.amount.value = this.$fw.func.num.comma.insert(_amount);
      this.calc__total_price();
      this.ui.percents.value = "";
    },

    //=====================================================================
    // ##order_type
    order_type__init() {
      const def_order_type = this.ui.form.select.order_type.items;
      let props_order_type = this.orderType;
      let valid_props = def_order_type.includes(props_order_type);

      if (valid_props) {
        this.ui.form.select.order_type.value = this.orderType;
      } else {
        this.ui.form.select.order_type.value = "limit";
      }
    },

    //=====================================================================
    // ##calc
    calc__total_price() {
      let price;
      let order_type = this.ui.form.select.order_type.value;
      let amount = this.ui.form.inp.amount.value;

      if (order_type == "limit") {
        price = this.ui.form.inp.price.value;
      } else {
        price = this.priceNow;
      }

      if (price == "") {
        this.ui.form.inp.total.value = "";
        return;
      }

      if (amount == "") {
        this.ui.form.inp.total.value = "";
        return;
      }

      let b_price = this.$fw.big(this.$fw.func.num.comma.remove(price));
      let b_amount = this.$fw.big(this.$fw.func.num.comma.remove(amount));
      let total_price = b_price.mul(b_amount).round(this.marketPrecision);

      this.ui.form.inp.total.value = this.$fw.func.num.comma.insert(total_price);
    },

    calc__balance_able__to_amount() {
      this.calc__balance_able__to_amount__limit();
    },

    calc__balance_able__to_amount__limit() {
      // 설정된 가격 있는지 확인
      let price;
      let order_type = this.ui.form.select.order_type.value;

      if (order_type == "limit") {
        price = this.ui.form.inp.price.value;
      } else {
        price = this.priceNow;
      }

      if (price == "") {
        // 툴팁 표시 ( price 설정 필요 )
        return;
      }

      // 사용가능 잔고 있는지 확인
      let balance_able = this.model.balance;
      let b_balance_able = this.$fw.big(balance_able);

      if (balance_able == 0 || b_balance_able.cmp(0) == -1) {
        // 잔고 없을 경우 없을 경우
        return;
      }

      // 선택 퍼센트 기준 잔고 계산
      let percent;

      if (this.ui.percents.value == "Max") {
        percent = 99.9;
      } else {
        percent = parseInt(this.ui.percents.value);
      }

      percent = this.$fw.big(percent).div(100);

      let b_percent_balance = b_balance_able.mul(percent);

      // 수량 계산
      let price__with_out_comma = this.$fw.func.num.comma.remove(price);

      let b_price = this.$fw.big(price__with_out_comma);
      let result = b_percent_balance.div(b_price).round(this.tokenPrecision);

      this.ui.form.inp.amount.value = result;
      this.calc__total_price();
    },

    //===================================================================================
    // ##order_confirm_info
    order_confirm_info__init() {
      this.ui.model.order_confirm_info = {
        token_code: this.tokenCode,
        token_name: this.tokenName,
        market_code: this.marketCode,
        order_way: "sell",
        order_fee: this.model.fee_info.fee,
        order_type: this.ui.form.select.order_type.value,
        order_price: this.$fw.func.num.comma.remove(this.ui.form.inp.price.value),
        order_amount: this.$fw.func.num.comma.remove(this.ui.form.inp.amount.value),
        order_total: this.$fw.func.num.comma.remove(this.ui.form.inp.total.value),
      };
      this.modal__order_confirm_info__show();
    },

    //===================================================================================
    // ##modal
    modal__order_confirm_info__show() {
      this.ui.modal.order_confirm_info.is_show = true;
    },

    modal__order_confirm_info__hide() {
      this.ui.modal.order_confirm_info.is_show = false;
    },
  },
};
</script>

<style lang="scss">
.inp-emphasize--buy {
  fieldset {
    border-color: var(--v-rise-base) !important;
    border-width: 1px !important;
  }
}

.inp-order-form {
  // placeholder 색상
  input {
    &::placeholder {
      text-transform: uppercase !important;
    }
  }
}
</style>
