import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fw_overlay = _resolveComponent("fw-overlay")!
  const _component_FwDialogFrame = _resolveComponent("FwDialogFrame")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _renderSlot(_ctx.$slots, "activator", _normalizeProps(_guardReactiveProps(_ctx.activatorAttrs))),
    (_ctx.activatedOnce)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_openBlock(), _createBlock(_Teleport, {
            to: "body",
            disabled: _ctx.local || _ctx.disableTeleport
          }, [
            (!_ctx.fullscreen)
              ? (_openBlock(), _createBlock(_component_fw_overlay, {
                  key: 0,
                  ref: "overlayComponent",
                  modelValue: _ctx.isActive,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isActive) = $event)),
                  "z-index": _ctx.zIndex,
                  background: _ctx.overlayBackground,
                  local: _ctx.local,
                  "click-on-close": !_ctx.persistent,
                  onClick: _ctx.onClickOutside
                }, null, 8, ["modelValue", "z-index", "background", "local", "click-on-close", "onClick"]))
              : _createCommentVNode("", true)
          ], 8, ["disabled"])),
          (_openBlock(), _createBlock(_Teleport, {
            to: "body",
            disabled: _ctx.local || _ctx.disableTeleport
          }, [
            _createVNode(_component_FwDialogFrame, {
              ref: "frameComponent",
              isActive: _ctx.isActive,
              "z-index": _ctx.zIndex,
              "max-width": _ctx.fullscreen ? '100%' : _ctx.maxWidth,
              width: _ctx.fullscreen ? '100%' : _ctx.width,
              height: _ctx.height,
              bottom: _ctx.transition == 'bottom-transition' ? true : false,
              scrollable: _ctx.scrollable,
              transition: _ctx.transition,
              depressed: _ctx.depressed,
              fullscreen: _ctx.fullscreen,
              background: _ctx.background,
              "border-radius": _ctx.borderRadius,
              "content-class": _ctx.contentClass,
              local: _ctx.local,
              persistent: _ctx.persistent
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default", { onClose: _ctx.onClose })
              ]),
              _: 3
            }, 8, ["isActive", "z-index", "max-width", "width", "height", "bottom", "scrollable", "transition", "depressed", "fullscreen", "background", "border-radius", "content-class", "local", "persistent"])
          ], 8, ["disabled"]))
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}