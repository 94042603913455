<template>
  <div class="fw-open-orders d-flex align-center mb-4" v-dimension="{ height: 40 }">
    <!-- <fw-checkbox v-model="ui.form.checkbox.all" label="All Pairs" />
    <div class="l-spacer" />
    <span class="text-primary cursor-pointer"> View All > </span> -->
    <div class="l-spacer" />
    <fw-button class="size-sm mr-2" :class="ui.form.checkbox.selected.length? 'status-primary' : 'status-normal'" @click="__button__click__cancel_selected">
      {{ $tt("comp_open_orders.dialog.cancel") }} ({{ ui.form.checkbox.selected.length ? ui.form.checkbox.selected.length : 0 }})
    </fw-button>

    <fw-button class="size-sm status-primary" @click="__button__click__cancel_all">
      {{ $tt("comp_open_orders.cancel_all") }}
    </fw-button>
  </div>

  <fw-scroll>
    <div v-dimension="{ height: scrollHeight }">
      <fw-list-item
        class="d-flex flex-wrap align-center rounded-0 px-3 py-4 cursor-pointer"
        v-for="(open_order, key) in model.open_orders.proc"
        :key="key"
        
      >
        <fw-checkbox class="status-primary mr-2" v-model="ui.form.checkbox.selected" :value="open_order.seq"/>
        <fw-symbol-img class="mr-2" :asset-code="open_order.token_code" :folder-path="pathSymbol" size="42" />
        <div @click="__item__click__open_order(open_order)">
          <p
            class="text-body-xl text-capitalize font-weight-medium mb-1"
            :class="get__orderway_color(open_order.order_way)"
          >
            {{ open_order.order_way }}
          </p>

          <p class="text-body-md text-capitalize text-desc">
            {{ open_order.order_type }}
          </p>
        </div>

        <div class="l-spacer" />

        <div class="d-flex flex-column align-end" @click="__item__click__open_order(open_order)">
          <fw-num-formatter
            class="text-body-xl font-weight-regular mb-1"
            :value="open_order.order_amount"
            :precision="8"
            :unit="open_order.token_code"
            unit-class="font-weight-bold"
          />

          <div class="d-flex align-center text-desc">
            <span class="mr-1 mb-1"> {{ $tt("comp_open_orders.price") }}: </span>

            <span class="mb-1" v-if="open_order.order_type == 'market'">
              {{ $tt("comp_open_orders.market") }}
            </span>

            <fw-num-formatter
              v-if="open_order.order_type == 'limit'"
              class="font-weight-regular mb-1"
              :value="open_order.order_price"
              :precision="8"
              :unit="open_order.market_code"
            />
          </div>
        </div>
      </fw-list-item>
      <fw-no-data v-model="is_show_no_data" />

      <fw-open-orders-detail
        ref="txDetail"
        v-model="ui.modal.transfer_detail.is_show"
        :path-symbol="pathSymbol"
        @click:cancel="__button__click__cancel"
      />
    </div>
  </fw-scroll>
</template>

<script>
import { format } from "date-fns";
import FwOpenOrdersDetail from "./fw-open-orders-detail.vue";

let obj_lang = {
  comp_open_orders: {
    all: {
      en: "All Pairs",
      ko: "모든 페어",
    },

    cancel_all: {
      en: "Cancel All",
      ko: "모두 취소",
    },
    price: {
      en: "Price",
      ko: "가격",
    },

    market: {
      en: "Market",
      ko: "마켓",
    },
    dialog: {
      // 전체 취소 확인
      system_message: {
        en: "System Message",
        ko: "시스템 메시지",
      },
      cancel_order_message: {
        en: "Are you sure you want to cancel this order?",
        ko: "주문을 취소하시겠습니까?",
      },
      cancel_order_all_message: {
        en: "Are you sure you want to cancel all orders?",
        ko: "전체 주문을 취소하시겠습니까?",
      },
      cancel_all: {
        en: "Cancel All",
        ko: "전체 취소",
      },
      cancel: {
        en: "Cancel",
        ko: "취소",
      },
      close: {
        en: "Close",
        ko: "닫기",
      },

      // 주문내역 없을경우 메시지
      no_orders: {
        en: "No Open Orders",
        ko: "주문내역이 없습니다.",
      },
    },
  },
};

export default {
  name: "FwOpenOrders",

  components: {
    FwOpenOrdersDetail,
  },

  props: {
    "path-symbol": {
      type: String,
      default: undefined,
    },
    scrollHeight: {
      type: Number,
      default: 330,
    },
  },

  data() {
    return {
      model: {
        open_orders: {
          raw: [],
          proc: {},
        },
      },

      ui: {
        form: {
          checkbox: {
            all: false,
            selected: [],
          },
        },

        modal: {
          transfer_detail: {
            is_show: false,
          },
        },
      },
    };
  },

  created() {
    this.__init();
  },

  mounted() {
    this.__loaded();
  },

  computed: {
    is_show_no_data() {
      return this.model.open_orders.proc.length == 0;
    },
  },

  watch: {
    //
  },

  methods: {
    format,

    __init() {
      this.$fw.lang.add(obj_lang);
    },

    __loaded() {
      //
    },

    get__orderway_color(_order_way) {
      if (_order_way == "buy") {
        return "text-buy";
      } else if (_order_way == "sell") {
        return "text-sell";
      }
    },

    __item__click__open_order(_open_order) {
      this.ui.modal.transfer_detail.is_show = true;

      this.$refs.txDetail.set_transfer_details(
        _open_order.seq,
        _open_order.token_code,
        _open_order.token_name,
        _open_order.market_code,
        _open_order.order_way,
        _open_order.order_type,
        _open_order.order_price,
        _open_order.order_amount,
        _open_order.pending_amount,
        _open_order.order_fee,
        _open_order.tx_hash,
        _open_order.total_price,
        _open_order.order_status,
        _open_order.time
      );
    },

    __button__click__cancel(_open_order) {
      let simple_modal_option = {
        title: this.$tt("comp_open_orders.dialog.system_message"),
        text: this.$tt("comp_open_orders.dialog.cancel_order_message"),
        btn_left_text: this.$tt("comp_open_orders.dialog.cancel"),
        btn_right_text: this.$tt("comp_open_orders.dialog.close"),
        click_left: () => {
          this.$emit("click:cancel", _open_order);
          this.ui.modal.transfer_detail.is_show = false;
        },
      };

      this.$dialog.show(simple_modal_option);
    },

    __button__click__cancel_selected() {
      if (this.ui.form.checkbox.selected.length == 0) {
        let simple_modal_option = {
          title: this.$tt("comp_open_orders.dialog.system_message"),
          text: this.$tt("comp_open_orders.dialog.no_orders"),
        };

        this.$dialog.show(simple_modal_option);
        return;
      }

      let simple_modal_option = {
        title: this.$tt("comp_open_orders.dialog.system_message"),
        text: this.$tt("comp_open_orders.dialog.cancel_order_all_message"),
        btn_left_text: this.$tt("comp_open_orders.dialog.cancel_all"),
        btn_right_text: this.$tt("comp_open_orders.dialog.close"),
        click_left: () => {
          this.$emit("click:cancel", this.ui.form.checkbox.selected);
        },
      };

      this.$dialog.show(simple_modal_option);
    },

    __button__click__cancel_all() {
      if (this.model.open_orders.proc.length == 0) {
        let simple_modal_option = {
          title: this.$tt("comp_open_orders.dialog.system_message"),
          text: this.$tt("comp_open_orders.dialog.no_orders"),
        };

        this.$dialog.show(simple_modal_option);
        return;
      }

      let simple_modal_option = {
        title: this.$tt("comp_open_orders.dialog.system_message"),
        text: this.$tt("comp_open_orders.dialog.cancel_order_all_message"),
        btn_left_text: this.$tt("comp_open_orders.dialog.cancel_all"),
        btn_right_text: this.$tt("comp_open_orders.dialog.close"),
        click_left: () => {
          this.$emit("click:cancel-all");
        },
      };

      this.$dialog.show(simple_modal_option);
    },

    //=======================================================
    // ##activity

    $_init_reset() {
      this.model.open_orders.raw = [];
      this.model.open_orders.proc = [];
    },

    $_set_open_order_item(
      _seq, // 주문번호
      _token_code, // 토큰 코드
      _token_name, // 토큰 이름
      _market_code, // 마켓 코드
      _order_way, // 주문 방향
      _order_type, // 주문 타입
      _order_price, // 주문 가격
      _order_amount, // 주문 수량
      _pending_amount, // 주문 수량
      _fee, // 수수료
      _tx_hash, // 거래 해시
      _total_price, // 주문 총액
      _order_status, // 주문 상태
      _order_time // 주문 시간
    ) {
      let obj_open_order = {
        seq: _seq,
        token_code: _token_code,
        token_name: _token_name,
        market_code: _market_code,
        order_way: _order_way,
        order_price: _order_price,
        order_amount: _order_amount,
        time: _order_time,
        order_status: _order_status,
        order_type: _order_type,
        tx_hash: _tx_hash,
        pending_amount: _pending_amount,
        order_fee: _fee,
        total_price: _total_price,
      };

      this.model.open_orders.raw.push(obj_open_order);
      this.open_orders__proc();
    },

    open_orders__proc() {
      this.model.open_orders.proc = [...this.model.open_orders.raw];
    },
  },
};
</script>

<style lang="scss">
@use "@/fw_config/config.scss" as *;

.fw-order-book {
  background-color: $ctrl-order-book-background-color;
}
</style>
