<template>
  <div class="l-card fw-text-card no-hover" @click="onClick">
    <slot>
      <span class="fw-text-card__value py-0 px-2" :class="contentClass">{{ value }}</span>
    </slot>
  </div>
</template>
<script>
import { computed, onMounted } from "vue";

export default {
  name: "FwTextCard",
  props: {
    value: {},
    ripple: {
      type: Boolean,
      default: false,
    },
    contentClass: {
      type: String,
      default: "",
    },
    opacity: {
      type: [Number, String],
      default: 0.15,
    },
  },

  emits: ["click"],

  setup(props, { emit }) {
    onMounted(() => {
      textCard.init();
    });

    const textCard = {
      init() {
        try {
          let el = document.querySelector(":root");
          el.style.setProperty("--opacity", props.opacity);
        } catch (error) {
          () => {};
        }
      },

      event: {
        click() {
          emit("click");
        },
      },
    };

    return {
      textCard,
    };
  },
};
</script>
<style lang="scss">
@use "sass:map";
@use "@/fw_config/config.scss" as *;
@import "@/fw/scss/mixin.scss";

.fw-text-card {
  position: relative !important;
  @include status-outlined-mixin(map.get($color-status, "normal"));

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    width: 100%;
    height: 100%;
    background: currentColor;
    opacity: var(--opacity);
    border-radius: inherit;
    pointer-events: none;
  }
}
</style>
