
import { defineComponent, reactive, toRefs, computed } from "vue";
import Slider from "@vueform/slider";

export default defineComponent({
  name: "FwSlider",

  components: {
    Slider,
  },

  props: {
    modelValue: {
      type: Number,
    },

    min: {
      type: Number,
      default: 0,
    },

    max: {
      type: Number,
      default: 100,
    },

    step: {
      type: Number,
      default: 1,
    },

    prefix: {
      type: String,
      default: "",
    },

    suffix: {
      type: String,
      default: "",
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    alwaysTooltip: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["update:modelValue"],

  setup(props, { emit }) {
    const sliderState = reactive({
      dotCurrentStep: 0,
      isSliding: false,
    });

    // v-model
    const computedModelValue = computed({
      get() {
        return props.modelValue;
      },

      set(_val) {
        emit("update:modelValue", _val);
      },
    });

    const tooltipFormatInfo = computed(() => {
      return {
        suffix: props.suffix,
        prefix: props.prefix,
      };
    });

    const classes = computed(() => {
      return {
        tooltip: `slider-tooltip pt-1`,
        touchArea: `slider-touch-area rounded-circle`,
        active: `slider-active shadow-0`,
      };
    });

    return {
      ...toRefs(sliderState),
      computedModelValue,
      classes,
      tooltipFormatInfo,
    };
  },
});
