<template>
  <div class="fw-stock-list d-flex flex-column pos-relative py-3" v-dimension="{ height: 'inherit' }">
    <div v-if="!hideToolbar" class="d-flex align-center mb-4 px-5">
      <fw-input
        v-model="ui.form.inp.search.value"
        :placeholder="$tt('stock_list.market_pair')"
        clearable
        @input="__inp__search__market"
      >
        <template #append-inner>
          <div class="px-2">
            <fw-icon icon="magnify" size="20" color="desc" />
          </div>
        </template>
      </fw-input>
      <div class="l-spacer" />

      <!-- <fw-icon class="cursor-pointer" color="secondary" icon="close" /> -->
    </div>

    <fw-tabs
      class="pos-sticky offset-top px-5 mb-5"
      v-model="ui.tab.selected"
      active-class="text-primary"
      full-width
      style="z-index: 10"
    >
      <fw-tab
        class="text-uppercase"
        v-for="market in model.market_list"
        :key="market.value"
        :value="market.value"
        @click="__select__change__market"
      >
        {{ market.text }}
      </fw-tab>
    </fw-tabs>

    <fw-carousel class="px-5 mb-5" hide-dot hideArrow>
      <fw-button
        class="size-sm mr-2"
        v-for="(filter, idx) in ui.filter.items"
        :key="idx"
        :class="filter.value == ui.filter.selected ? 'status-primary' : ''"
        @click="__icon__click__sort(filter.value)"
        @touch="__icon__click__sort(filter.value)"
      >
        {{ filter.label }}
      </fw-button>
    </fw-carousel>

    <fw-scroll>
      <fw-list-item
        v-for="(stock, idx) in model.stock_list.proc"
        :key="idx"
        class="d-flex align-center bg-transparent cursor-pointer pa-0 px-5"
        @click="__row__click__pair(stock)"
        min-height="80"
      >
        <div class="mr-4" v-dimension="{ height: '40', width: '40' }">
          <fw-symbol-img :asset-code="stock.asset_code" :folder-path="pathSymbol" size="40" />
        </div>

        <div>
          <p class="text-body-xl text-uppercase font-weight-medium mb-1">
            {{ stock.asset_code }}/{{ stock.market_code }}
          </p>

          <div class="d-flex align-center font-weight-regular text-desc mb-0">
            <span class="mr-1"> Vol. </span>
            <fw-num-formatter class="text-break" :value="stock.volume" :precision="stock.asset_precision" />
          </div>
        </div>

        <div class="l-spacer" />

        <div class="text-right">
          <fw-num-formatter
            :class="ui__change_color__get(stock.change)"
            class="text-body-xl text-break font-weight-medium mb-1"
            :value="stock.price"
            :precision="stock.asset_precision"
          />

          <fw-num-formatter
            :class="ui__change_color__get(stock.change)"
            class="text-uppercase font-weight-thin text-desc mb-0"
            :value="stock.change"
            :precision="2"
            unit="%"
            sign
            style="letter-spacing: 1.5px"
          />
        </div>
      </fw-list-item>
    </fw-scroll>
    <fw-no-data v-model="is_no_data_show" />
  </div>
</template>

<script>
import { computed, ref, reactive, onMounted, toRefs } from "vue";
//-------------------------------------
// define local consts
const def_sort_way = {
  asc: "asc", // 오름차순
  desc: "desc", // 내림차순
};

const def_a_is_not_exists = -1;

let obj_lang = {
  stock_list: {
    market_list: {
      en: "Market List",
      ko: "마켓",
    },
    all: {
      en: "All",
      ko: "전체",
    },

    pair: {
      en: "Pair",
      ko: "종목",
    },

    favorite: {
      en: "Favorite",
      ko: "즐겨찾기",
    },

    price: {
      en: "Price",
      ko: "현재가",
    },

    change: {
      en: "Change",
      ko: "변화율",
    },

    volume: {
      en: "Volume",
      ko: "거래대금",
    },

    market_pair: {
      en: "Market Pair",
      ko: "마켓 페어",
    },
  },
};

export default {
  components: {},
  props: {
    "storage-key-favorite": {
      // 즐겨찾기 스토리지 키값
      type: String,
      default: "ui__stock_list_user_favorite",
    },

    "path-symbol": {
      type: String,
      default: "",
    },

    rounded: {
      type: [String, Number],
      default: 0,
    },

    elevation: {
      type: [Number, String],
      default: 0,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    "show-row-line": {
      type: Boolean,
      default: false,
    },

    "hide-search-bar": {
      type: Boolean,
      default: false,
    },

    "hide-favorite-btn": {
      type: Boolean,
      default: false,
    },

    "hide-market-tab": {
      type: Boolean,
      default: false,
    },

    hideToolbar: {
      type: Boolean,
      default: false,
    },

    "show-asset-name": {
      type: Boolean,
      default: false,
    },

    height: {
      type: [String, Number],
      default: "auto",
    },

    width: {
      type: [String, Number],
      default: "auto",
    },
  },

  data() {
    return {
      model: {
        stock_list: {
          raw: {},
          proc: [],
        },

        market_list: [
          {
            text: "All",
            value: "all",
            order: 0,
          },
        ],
        favo_list: [],
      },

      ui: {
        form: {
          inp: {
            search: {
              value: "",
            },
          },
        },

        filter: {
          selected: "all",
          items: [
            {
              value: "all",
              label: "All",
            },
            {
              value: "volume", //볼륨
              label: "Volume",
            },
            {
              value: "change", // 변화량
              label: "Gainer",
            },
            // {
            //   value: "big_dipper",
            //   label: "Big dipper",
            // }
          ],
        },

        tab: {
          selected: "all",
        },

        sort: {
          selected: "",
          way: def_sort_way.asc,
          target: {
            pair: "asset_code",
            price: "price",
            change: "change",
            volume: "volume",
          },
        },
      },

      fw_table: {
        is_no_data: false,
      },
    };
  },

  created() {
    this.__init();
  },

  mounted() {
    this.__loaded();
  },

  computed: {
    rounded__get() {
      return {
        "border-radius": this.rounded + "px !important",
      };
    },

    is_no_data_show() {
      return this.fw_table.is_no_data;
    },
  },

  methods: {
    //==============================================================================
    // ##event
    __init() {
      this.$fw.lang.add(obj_lang);
    },

    __loaded() {
      this.favorite__load();
    },

    __icon__click__favorite(_asset_code, _market_code) {
      this.favorite__set(_asset_code, _market_code);
      this.stock__proc();
    },

    __icon__click__sort(_sort_target) {
      this.ui.filter.selected = _sort_target;
      this.sort__set(_sort_target);
      this.stock__proc();
    },

    __tab__click__market(_tab_name) {
      this.ui.tab.selected = _tab_name;
      this.stock__proc();
    },

    __inp__search__market() {
      this.stock__proc();
    },

    __select__change__market() {
      this.stock__proc();
    },

    __row__click__pair(_stock) {
      this.$emit("click:pair", _stock);
    },

    //======================================================================
    // ##methods

    // 종목 리스트

    // 기본 설정
    $_init() {
      this.$_init_reset();
    },

    // 값 초기화
    $_init_reset() {
      this.model.stock_list = {
        raw: {},
        proc: [],
      };

      this.model.favo_list = [];
      this.ui.form.inp.search.value = "";
      this.ui.tab.selected = "all";
      this.ui.sort.selected = "";
      this.ui.sort.way = def_sort_way.asc;
    },

    // 종목 현재가 설정
    $_set_stock_item(
      _asset_code, // 종목 코드
      _asset_name, // 종목 이름
      _asset_precision, // 종목 소수점
      _market_code, // 마켓코드
      _market_name, // 마켓이름
      _close_price, // 종가
      _percent, // 변동 퍼센트
      _volume_price // 거래대금
    ) {
      this.stock__set(
        _asset_code,
        _asset_name,
        _asset_precision,
        _market_code,
        _market_name,
        _close_price,
        _percent,
        _volume_price
      );
    },

    //===============================================================================
    // ##stock
    stock__set(
      _asset_code,
      _asset_name,
      _asset_precision,
      _market_code,
      _market_name,
      _close_price,
      _percent,
      _volume_price
    ) {
      if (typeof _asset_code == "undefined") {
        return;
      }

      let pair = _asset_code + "/" + _market_code;
      let unique_key = pair;

      if (typeof this.model.stock_list.raw[unique_key] == "undefined") {
        let obj_market = {
          asset_code: _asset_code,
          asset_precision: _asset_precision,
          asset_name: _asset_name,
          market_name: _market_name,
          market_code: _market_code,
          price: _close_price,
          change: _percent,
          volume: _volume_price,
        };

        this.model.stock_list.raw[unique_key] = obj_market;
      }

      this.market_tab__set();
      this.stock__proc();
    },

    stock__proc() {
      let stock_list = [];

      stock_list = this.computed__render__stock_list__by_tab();
      stock_list = this.computed__render__stock_list__sort(stock_list);
      stock_list = this.computed__render__stock_list__search(stock_list);

      this.table__is_no_data__set(stock_list);

      this.model.stock_list.proc = stock_list;
      this.$forceUpdate();
    },

    //===============================================================================
    // ##market_tab
    market_tab__set() {
      let arr_market_tab = [];

      for (let market in this.model.stock_list.raw) {
        let market_code = this.model.stock_list.raw[market].market_code;

        if (arr_market_tab.indexOf(market_code) == def_a_is_not_exists) {
          arr_market_tab.push(market_code);
        }
      }

      arr_market_tab.map((_market_name) => {
        let filtered = [];

        filtered = this.model.market_list.filter((_market) => _market.value == _market_name);

        if (filtered.length == 0) {
          this.model.market_list.push({
            text: _market_name.toUpperCase(),
            value: _market_name,
            order: 1,
          });
        }

        this.model.market_list.sort((a, b) => {
          return a.order - b.order;
        });
      });
    },

    //=========================================================================================
    // ##favorite
    favorite__load() {
      let local_storage__favorite = localStorage.getItem(this.storageKeyFavorite);

      if (local_storage__favorite != null) {
        this.model.favo_list = JSON.parse(local_storage__favorite);
      }
    },

    favorite__is_active(_asset_code, _market_code) {
      let pair = _asset_code + "/" + _market_code;

      let is_exists = this.model.favo_list.indexOf(pair);

      if (is_exists == def_a_is_not_exists) {
        return false;
      } else {
        return true;
      }
    },

    favorite__set(_asset_code, _market_code) {
      let pair = _asset_code + "/" + _market_code;

      if (this.model.favo_list == null) {
        this.model.favo_list = [];
        this.model.favo_list.push(pair);
        this.favorite__set__localstorage();
        return;
      }

      let idx_duplicate = this.model.favo_list.indexOf(pair);

      if (idx_duplicate != def_a_is_not_exists) {
        this.model.favo_list.splice(idx_duplicate, 1);
      } else {
        this.model.favo_list.push(pair);
      }

      this.favorite__set__localstorage();
    },

    favorite__set__localstorage() {
      localStorage.removeItem(this.storageKeyFavorite);
      localStorage.setItem(this.storageKeyFavorite, JSON.stringify(this.model.favo_list));
      this.favorite__load();
    },

    //=========================================================================================
    // ##computed
    computed__render__stock_list__by_tab() {
      let obj_market_list__proc = [];
      let tab__selected = this.ui.tab.selected;

      if (tab__selected == "all") {
        return this.model.stock_list.raw;
      }

      if (tab__selected == "favo") {
        for (let market in this.model.stock_list.raw) {
          let pair = this.model.stock_list.raw[market].asset_code + "/" + this.model.stock_list.raw[market].market_code;

          if (this.model.favo_list.indexOf(pair) != def_a_is_not_exists) {
            obj_market_list__proc.push(this.model.stock_list.raw[market]);
          }
        }

        return obj_market_list__proc;
      }

      for (let market in this.model.stock_list.raw) {
        if (this.model.stock_list.raw[market].market_code == tab__selected) {
          obj_market_list__proc.push(this.model.stock_list.raw[market]);
        }
      }

      return obj_market_list__proc;
    },

    computed__render__stock_list__sort(_stock_list) {
      let arr_stock_list = [];

      for (let stock in _stock_list) {
        arr_stock_list.push(_stock_list[stock]);
      }

      let arr_result = [];
      let sort__selected = this.ui.sort.selected;

      if (sort__selected == "") {
        return arr_stock_list;
      }

      if (this.ui.sort.way == def_sort_way.desc) {
        arr_result = arr_stock_list.sort((a, b) => {
          return a[sort__selected] < b[sort__selected] ? -1 : a[sort__selected] > b[sort__selected] ? 1 : 0;
        });
      }

      if (this.ui.sort.way == def_sort_way.asc) {
        arr_result = arr_stock_list.sort((a, b) => {
          return a[sort__selected] > b[sort__selected] ? -1 : a[sort__selected] < b[sort__selected] ? 1 : 0;
        });
      }

      return arr_result;
    },

    computed__render__stock_list__search(_stock_list) {
      let arr_result = [];
      let search_value = this.ui.form.inp.search.value;

      if (search_value == null || search_value == "") {
        return _stock_list;
      }

      if (_stock_list.length <= 0) {
        return _stock_list;
      }

      _stock_list.map((_stock) => {
        let asset_code = _stock.asset_code.toLowerCase();
        let asset_name = _stock.asset_name.toLowerCase();

        let search_keyword = search_value.toLowerCase();

        if (
          asset_code.indexOf(search_keyword) != def_a_is_not_exists ||
          asset_name.indexOf(search_keyword) != def_a_is_not_exists
        ) {
          arr_result.push(_stock);
        }
      });

      return arr_result;
    },

    //================================================================
    // ##sort
    sort__set(_sort_target) {
      if (this.ui.sort.selected != _sort_target) {
        this.ui.sort.way = def_sort_way.asc;
        this.ui.sort.selected = _sort_target;
      } else {
        if (this.ui.sort.way == def_sort_way.asc) {
          this.ui.sort.way = def_sort_way.desc;
        } else {
          this.ui.sort.way = def_sort_way.asc;
        }
      }
    },

    //=================================================================
    // ##ui
    ui__change_color__get(_market_change) {
      const color_rise = "text-rise";
      const color_fall = "text-fall";
      const color_default = "";

      let change_percent = parseFloat(_market_change);

      if (isNaN(change_percent) || change_percent == 0.0) {
        return color_default;
      }

      if (change_percent > 0.0) {
        return color_rise;
      } else {
        return color_fall;
      }
    },

    ui__change_sign__get(_market_change) {
      let change_percent = parseFloat(_market_change);

      if (isNaN(change_percent) || change_percent == 0.0) {
        return "";
      }

      if (change_percent > 0.0) {
        return "+";
      } else {
        return "";
      }
    },

    //================================================================
    // etc
    table__is_no_data__set(_stock_list) {
      if (_stock_list.length == 0) {
        this.fw_table.is_no_data = true;
      } else {
        this.fw_table.is_no_data = false;
      }
    },
  },
};
</script>

<style lang="scss">
@use "@/fw_config/config.scss" as *;

.fw-stock-list {
  background-color: $ctrl-stock-list-background-color;
}
</style>
