<template>
  <div class="l-row-c fw-diff-area" :ref="main.ref" :style="getHeightValue" v-resize="main.init">
    <slot></slot>
  </div>
</template>

<script>
import { onMounted, ref, reactive, computed } from "vue";

export default {
  name: "FwDiffArea",
  components: {},
  props: {
    subject: {
      // 임시 네이밍
      type: String,
      default: "",
    },
    subjectHeightType: {
      type: String,
      default: "offsetHeight",
    },
    targets: {
      // 임시 네이밍
      type: Array,
      default: () => [],
    },
    targetsHeightType: {
      type: String,
      default: "offsetHeight",
    },
    heightType: {
      type: String,
      default: "minHeight",
    },
    flexible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    onMounted(() => {
      main.init();
    });

    const main = {
      ref: ref(null),
      data: reactive({
        heightValue: null,
      }),
      init() {
        if (props.flexible) return;
        if (!props.subject) return;
        if (!props.targets) return;

        let subject;

        if (props.subject == "window" || props.subject == "document") {
          subject = window;
        } else {
          subject = document.querySelector(props.subject);
        }

        if( subject == null ) return;

        let targets = props.targets.map((target) => {
          return document.querySelector(target);
        });

        if (!subject) return;
        if (!targets.length) return;

        let subjectHeight = subject[props.subjectHeightType];

        let targetsHeight = targets.reduce((acc, cur) => {
          if( cur == null ) return 0;
          acc += cur.offsetHeight || 0;
          return acc;
        }, 0);

        main.data.heightValue = subjectHeight - targetsHeight;
      },
    };

    const getHeightValue = computed(() => {
      return {
        [props.heightType]: main.data.heightValue + "px",
      };
    });

    return {
      main,
      getHeightValue,
    };
  },
};
</script>

<style lang="scss">
.fw-diff-area {
  position: relative;
  max-width: 100%;
  width: 100%;
}
</style>
