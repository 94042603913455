<template>
  <Field
    type="checkbox"
    v-model="inputValue"
    v-slot="{ field }"
    :id="name"
    :name="name"
    :value="value"
    :rules="rulesExecutor"
    :disabled="disabled"
  >
    <label class="fw-ctrl fw-checkbox text-only pa-0 ma-0" :class="classes">
      <input type="checkbox" v-bind="field" :value="value" :disabled="disabled" @click="onClick" />

      <slot name="off-icon" v-if="!computedIsChecked">
        <fw-icon :icon="offIcon" size="24" style="color: inherit" />
      </slot>

      <slot name="on-icon" v-else>
        <fw-icon :icon="onIcon" size="24" style="color: inherit"></fw-icon>
      </slot>

      <slot name="label" :checked="computedIsChecked">
        <span class="label">{{ label }}</span>
      </slot>

      <div v-if="showValid" class="fw-checkbox__valid_detail px-3 pb-1">
        <transition>
          <ErrorMessage :name="name" class="d-block text-error"></ErrorMessage>
        </transition>
      </div>
    </label>
  </Field>
</template>

<script>
import { defineComponent, computed, reactive, toRefs, watch, onMounted } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import { v4 } from "uuid";

export default defineComponent({
  name: "FwCheckbox",
  components: {
    Field,
    ErrorMessage,
  },
  props: {
    class: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number, Array, Boolean],
      default: false,
    },
    name: {
      type: String,
      default: () => v4(),
    },
    value: {
      default: true,
    },
    rules: {
      type: Function,
      default: () => true,
    },
    showValid: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    onIcon: {
      type: String,
      default: "check",
    },
    offIcon: {
      type: String,
      default: "checkbox-blank-outline",
    },
  },

  emits: ["change", "update:modelValue"],

  setup(props, { emit }) {
    const stateValid = reactive({
      inputValue: false,
      isValid: true,
    });

    const onClick = () => {
      if (props.disabled) {
        return;
      }

      if (Array.isArray(props.modelValue)) {
        let arr_model = Object.values(props.modelValue);
        if (arr_model.includes(props.value)) {
          arr_model = arr_model.filter((item) => item !== props.value);
        } else {
          arr_model.push(props.value);
        }
        emit("update:modelValue", arr_model);
      } else {
        if (isModelValueBoolean()) {
          emit("update:modelValue", !props.modelValue);
        } else {
          if (props.modelValue != props.value) {
            emit("update:modelValue", props.value);
          }
        }
      }
    };

    // ==========================================
    // rules 실행
    const rulesExecutor = () => {
      return props.rules(stateValid.inputValue);
    };

    // ==========================================
    // class & style
    const classes = computed(() => {
      return [
        props.class,
        {
          "not-checked": !computedIsChecked.value,
          checked: computedIsChecked.value,
          disabled: props.disabled,
        },
      ];
    });

    function isModelValueBoolean() {
      return typeof props.modelValue === "boolean";
    }

    const computedIsChecked = computed(() => {
      let value = props.value;
      let modelValue = props.modelValue;
      let isChecked = false;

      if (Array.isArray(modelValue)) {
        if (modelValue.includes(value)) {
          isChecked = true;
        } else {
          isChecked = false;
        }
      } else {
        if (isModelValueBoolean()) {
          isChecked = modelValue;
        } else {
          isChecked = modelValue == value;
        }
      }

      return isChecked;
    });

    watch(
      () => stateValid.inputValue,
      () => {
        if (isModelValueBoolean()) {
          emit("update:modelValue", stateValid.inputValue === true);
        }
        emit("change", stateValid.inputValue);
        if (Array.isArray(props.modelValue)) {
          //
        } else {
          // emit("update:modelValue", stateValid.inputValue); // 임시 문제 있음(modelValue와 value가 string, number 일 경우 radio와 같은 기능을 희망하였지만 해당 구문때문에 불가. 차후 삭제 예정 )
        }
      }
    );

    onMounted(() => {
      stateValid.inputValue = props.modelValue;
    });

    return {
      Field,
      ErrorMessage,
      ...toRefs(stateValid),
      onClick,
      rulesExecutor,

      classes,
      computedIsChecked,
    };
  },
});
</script>

<style lang="scss">
@use "sass:map";
@use "@/fw_config/config.scss" as *;

.fw-checkbox {
  display: flex;
  align-items: center;
  background-color: transparent !important;
  border: none !important;
  cursor: pointer;
  
  &.not-checked {
    color: $color-border !important;
  }

  .label {
    color: map.get($color-text, "normal");
  }

  input {
    display: none;
  }
}
</style>
