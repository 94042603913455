
import { defineComponent, capitalize, Prop, PropType, computed } from "vue";

// Types
const breakpoints = ["sm", "md", "lg", "xl", "xxl"] as const; // no xs

const breakpointProps = (() => {
  return breakpoints.reduce((props, val) => {
    props[val] = {
      type: [Boolean, String, Number],
      default: false,
    };
    return props;
  }, {} as Record<string, Prop<boolean | string | number, false>>);
})();

const offsetProps = (() => {
  return breakpoints.reduce((props, val) => {
    props["offset" + capitalize(val)] = {
      type: [String, Number],
      default: null,
    };
    return props;
  }, {} as Record<string, Prop<string | number, null>>);
})();

const orderProps = (() => {
  return breakpoints.reduce((props, val) => {
    props["order" + capitalize(val)] = {
      type: [String, Number],
      default: null,
    };
    return props;
  }, {} as Record<string, Prop<string | number, null>>);
})();

const propMap = {
  col: Object.keys(breakpointProps),
  offset: Object.keys(offsetProps),
  order: Object.keys(orderProps),
};

function breakpointClass(type: keyof typeof propMap, prop: string, val: boolean | string | number) {
  let className: string = type;
  if (val == null || val === false) {
    return undefined;
  }
  if (prop) {
    const breakpoint = prop.replace(type, "");
    className += `-${breakpoint}`;
  }
  if (type === "col") {
    className = "l-" + className;
  }
  // Handling the boolean style prop when accepting [Boolean, String, Number]
  // means Vue will not convert <l-col sm></l-col> to sm: true for us.
  // Since the default is false, an empty string indicates the prop's presence.
  if (type === "col" && (val === "" || val === true)) {
    // .l-col-md
    return className.toLowerCase();
  }
  // .order-md-6
  className += `-${val}`;
  return className.toLowerCase();
}

const ALIGN_SELF_VALUES = ["auto", "start", "end", "center", "baseline", "stretch"] as const;

export default defineComponent({
  name: "FwCol",
  props: {
    cols: {
      type: [Boolean, String, Number],
      default: false,
    },
    ...breakpointProps,
    offset: {
      type: [String, Number],
      default: null,
    },
    ...offsetProps,
    order: {
      type: [String, Number],
      default: null,
    },
    ...orderProps,
    alignSelf: {
      type: String as PropType<typeof ALIGN_SELF_VALUES[number]>,
      default: null,
      validator: (str: any) => ALIGN_SELF_VALUES.includes(str),
    },
  },

  setup(props) {
    const classes = computed(() => {
      const classList: any[] = [];

      // Loop through `col`, `offset`, `order` breakpoint props
      let type: keyof typeof propMap;
      for (type in propMap) {
        propMap[type].forEach((prop) => {
          const value: string | number | boolean = (props as any)[prop];
          const className = breakpointClass(type, prop, value);
          if (className) classList!.push(className);
        });
      }

      const hasColClasses = classList.some((className) => className.startsWith("l-col-"));

      classList.push({
        // Default to .l-col if no other col-{bp}-* classes generated nor `cols` specified.
        "l-col": !hasColClasses || !props.cols,
        [`l-col-${props.cols}`]: props.cols,
        [`offset-${props.offset}`]: props.offset,
        [`order-${props.order}`]: props.order,
        [`align-self-${props.alignSelf}`]: props.alignSelf,
      });

      return classList;
    });

    return {
      classes,
    };
  },
});
