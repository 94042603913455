<template>
  <div class="fw-order-book" color="transparent" v-dimension="{ width: '100%', height: '100%' }">
    <l-row no-gutters>
      <div
        :class="[`l-col-${cols__get}`, !$fw.display.smAndDown ? 'flex-column text-body-xl' : 'text-body-md']"
        class="d-flex text-left py-2 pl-2"
      >
        <span>
          {{ $tt("order_book.price") }}
        </span>
      </div>

      <div
        :class="[`l-col-${cols__get}`, !$fw.display.smAndDown ? 'flex-column text-body-xl' : 'text-body-md']"
        class="d-flex text-right py-2 px-2"
      >
        <p class="mb-0">
          {{ $tt("order_book.quantity") }}
        </p>
      </div>

      <div
        v-show="!hideVolumeColumn"
        class="l-col-4 text-right py-2 pr-2"
        :class="!$fw.display.smAndDown ? 'flex-column text-body-xl' : 'text-body-md'"
      >
        <span>
          {{ $tt("order_book.total") }}
        </span>
      </div>
    </l-row>

    <div class="order-book-row-wrap sell-wrap" ref="order_book__sell__wrap">
      <div
        v-for="(order, idx) in render__order_book__sell"
        :key="idx + '_sell'"
        v-show="ui.tab.selected == ui.tab.all || ui.tab.selected == ui.tab.sell"
        class="l-row order-book-row flex-nowrap no-gutter"
        @click="__row__click__emit(order)"
      >
        <div :class="`l-col-${cols__get}`" class="d-flex flex-column justify-center text-left">
          <span class="text-body-xl font-weight-regular text-sell">
            {{ $fw.func.num.comma.insert(order.price) || "&nbsp;" }}
          </span>
          <span
            v-if="showPricePercent"
            class="font-weight-medium text-sell"
            :class="$fw.display.smAndDown ? 'text-body-lg' : 'text-body-md'"
          >
            {{ $fw.func.num.comma.insert(order.percent) || "&nbsp;" }}
          </span>
        </div>

        <div :class="`l-col-${cols__get}`" class="d-flex align-center justify-end">
          <span>
            {{ $fw.func.num.comma.insert(order.amount) || "&nbsp;" }}
          </span>
        </div>

        <div v-if="!hideVolumeColumn" class="l-col-4 d-flex align-center justify-end">
          <span v-if="!hideVolumeColumn">
            {{ $fw.func.num.comma.insert(order.total) || "&nbsp;" }}
          </span>
        </div>

        <div
          class="l-col progress-bar sell-bar"
          :class="{ 'no-spacing': !barSpacing }"
          :style="{
            transform: `translateX( ${ui__bar_position__get(order.amount)})`,
          }"
        >
          &nbsp;
        </div>
      </div>
    </div>

    <div class="l-row order-book-row pa-2 py-3 align-center no-gutter">
      <div class="l-col-12 d-flex align-center text-left">
        <fw-icon v-if="change_is_positive__get == 'rise'" icon="arrow-up" class="text-rise mr-2 mb-1" />

        <fw-icon v-else-if="change_is_positive__get == 'fall'" icon="arrow-down" class="text-sell mr-2 mb-1" />

        <span class="text-body-xl font-weight-bold mr-2" :class="price_color__get">
          {{ $fw.func.num.comma.insert(priceNow) }}
        </span>

        <span class="text-body-lg mx-1" :class="price_color__get">
          {{ change_is_positive__get == "rise" ? "+" : "" }}
          {{ $fw.func.num.comma.insert(changePercent) }}%
        </span>
      </div>
    </div>

    <div class="order-book-row-wrap buy-wrap">
      <div
        v-for="(order, idx) in render__order_book__buy"
        :key="idx + '_buy'"
        v-show="ui.tab.selected == ui.tab.all || ui.tab.selected == ui.tab.buy"
        class="l-row order-book-row flex-nowrap no-gutter"
        @click="__row__click__emit(order)"
      >
        <div :class="`l-col-${cols__get}`" class="d-flex flex-column justify-center text-left">
          <span class="text-body-xl font-weight-regular text-buy">
            {{ $fw.func.num.comma.insert(order.price) || "&nbsp;" }}
          </span>
          <span v-if="showPricePercent" class="text-body-lg font-weight-medium text-buy">
            {{ $fw.func.num.comma.insert(order.percent) || "&nbsp;" }}
          </span>
        </div>

        <div :class="`l-col-${cols__get}`" class="d-flex align-center justify-end">
          <span>
            {{ $fw.func.num.comma.insert(order.amount) || "&nbsp;" }}
          </span>
        </div>

        <div v-if="!hideVolumeColumn" class="l-col-4 d-flex align-center justify-end">
          <span v-if="!hideVolumeColumn">
            {{ $fw.func.num.comma.insert(order.total) || "&nbsp;" }}
          </span>
        </div>
        <div
          class="l-col progress-bar buy-bar"
          :class="{ 'no-spacing': !barSpacing }"
          :style="{
            transform: `translateX( ${ui__bar_position__get(order.amount)})`,
          }"
        >
          &nbsp;
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

let obj_lang = {
  order_book: {
    price: {
      en: "Price",
      ko: "가격",
    },
    quantity: {
      en: "Amount",
      ko: "수량",
    },
    total: {
      en: "Volume",
      ko: "볼륨",
    },
  },
};

//-------------------------------------
// define local consts
const def_sort_type = {
  asc: "asc",
  desc: "desc",
};

const def_pct_bar_type = {
  accumulate: "accumulate",
  higher: "higher",
};

const def_b_greater_than_a = -1;

export default defineComponent({
  props: {
    "token-code": {
      type: String,
      default: "",
    },
    "market-code": {
      type: String,
      default: "",
    },
    "max-row": {
      // buy, sell의 보여줄 항목 수 설정 (ex. 12 -> buy: 12, sell: 12 총: 24 )
      type: [String, Number],
      default: 12,
    },

    "bar-align": {
      // 호가항목의 퍼센트바 시작 기준점 left or right
      type: String,
      default: "right",
    },

    "bar-spacing": {
      // 각 호가의 수량 퍼센트바 위아래 간격 유무
      type: Boolean,
      default: false,
    },

    "hide-volume-column": {
      // volume 항목을 숨김 ( price, quantity 만 출력)
      type: Boolean,
      default: false,
    },

    "show-price-percent": {
      // 각 호가의 현재 가격대비 퍼센트 표시
      type: Boolean,
      default: false,
    },

    width: {
      type: [String, Number],
      default: "100%",
    },

    "price-now": {
      type: [String, Number],
      default: 0,
    },

    "change-percent": {
      type: [String, Number],
      default: 0,
    },
  },

  data() {
    return {
      model: {
        price_now: "",
        change_percent: "",
        order_book: {
          raw: {},
          proc: {
            buy: [],
            sell: [],
          },
        },
      },

      ui: {
        tab: {
          selected: 0,
          all: 0,
          buy: 1,
          sell: 2,
        },
      },

      accumulated_amount: {
        all: 0,
        buy: 0,
        sell: 0,
      },

      higher_amount: {
        all: 0,
        buy: 0,
        sell: 0,
      },

      options: {
        pct_standard: def_pct_bar_type.higher,
      },
    };
  },

  created() {
    this.__init();
  },

  mounted() {
    this.__loaded();
  },

  computed: {
    cols__get() {
      let hide_total = this.hideVolumeColumn;

      if (hide_total) {
        return 6;
      } else {
        return 4;
      }
    },

    render__order_book__buy() {
      let arr_order_book__buy = [...this.model.order_book.proc.buy];

      if (this.ui.tab.selected == this.ui.tab.all) {
        let max_row = this.maxRow; // props
        let cnt_empty_order = max_row - arr_order_book__buy.length;

        // max_row보다 부족할 경우
        if (cnt_empty_order > 0) {
          let obj_order_book__empty_data = {
            price: "",
            amount: "",
            total: "",
          };

          for (let i = 0; i < cnt_empty_order; i++) {
            arr_order_book__buy.push(obj_order_book__empty_data);
          }
        }

        // max_row을 초과하였을 경우
        if (cnt_empty_order < 0) {
          arr_order_book__buy.splice(arr_order_book__buy.length - Math.abs(cnt_empty_order));
        }
      }

      return arr_order_book__buy;
    },

    render__order_book__sell() {
      let arr_order_book__sell = [...this.model.order_book.proc.sell];

      if (this.ui.tab.selected == this.ui.tab.all) {
        let max_row = this.maxRow; // props
        let cnt_empty_order = max_row - arr_order_book__sell.length;

        // max_row보다 부족할 경우
        if (cnt_empty_order > 0) {
          let obj_order_book__empty_data = {
            price: "",
            amount: "",
            total: "",
          };

          for (let i = 0; i < cnt_empty_order; i++) {
            arr_order_book__sell.unshift(obj_order_book__empty_data);
          }
        }

        // max_row을 초과하였을 경우
        if (cnt_empty_order < 0) {
          arr_order_book__sell.splice(0 - Math.abs(cnt_empty_order));
        }
      }
      return arr_order_book__sell;
    },

    price_color__get() {
      const color_rise = "text-buy";
      const color_fall = "text-sell";
      const color_default = "";

      let change_percent = parseFloat(this.changePercent);

      if (isNaN(change_percent) || change_percent == 0.0) {
        return color_default;
      }

      if (change_percent > 0.0) {
        return color_rise;
      } else {
        return color_fall;
      }
    },

    change_is_positive__get() {
      let change_percent = parseFloat(this.changePercent);

      if (isNaN(change_percent) || change_percent == 0.0) {
        return "not-changed";
      }

      if (change_percent > 0.0) {
        return "rise";
      } else {
        return "fall";
      }
    },
  },

  watch: {
    maxRow() {
      this.order_book__proc();
    },
  },

  methods: {
    __init() {
      this.$fw.lang.add(obj_lang);
    },

    __loaded() {
      //
    },

    __row__click__emit(_order) {
      this.$emit("click:price", _order);
    },

    //======================================================================
    // ##methods

    // 기본 설정
    $_init() {
      this.$_init_reset();
    },

    // 값 초기화
    $_init_reset() {
      this.model.order_book.raw = {};
      this.model.order_book.proc.buy = [];
      this.model.order_book.proc.sell = [];
      this.accumulated_amount.all = 0;
      this.accumulated_amount.buy = 0;
      this.accumulated_amount.sell = 0;
      this.higher_amount.all = 0;
      this.higher_amount.buy = 0;
      this.higher_amount.sell = 0;
    },

    // 호가 항목 추가
    $_add_order(
      _price, // 호가
      _amount, // 호가 수량
      _order_way // 주문타입(buy, sell)
    ) {
      this.order_book__set(_price, _amount, _order_way);
    },

    //============================================================================
    // ##order_book
    order_book__set(_order_price, _order_amount, _order_way) {
      let unique_key = _order_price;

      if (parseFloat(_order_amount) == 0) {
        delete this.model.order_book.raw[unique_key];
        this.order_book__proc();
        return;
      }

      let b_price = this.$fw.big(_order_price);
      let b_amount = this.$fw.big(_order_amount);
      let total_price = b_price.mul(b_amount).toFixed();

      let obj_order = {
        price: b_price.toFixed(),
        amount: b_amount.toFixed(),
        order_way: _order_way,
        total: total_price,
      };

      this.model.order_book.raw[unique_key] = obj_order;
      this.order_book__proc();
    },

    order_book__proc() {
      let obj_order_book__raw = this.model.order_book.raw;
      let arr_order_book__buy = [];
      let arr_order_book__sell = [];

      for (let key in obj_order_book__raw) {
        if (obj_order_book__raw[key].order_way == "buy") {
          arr_order_book__buy.push(obj_order_book__raw[key]);
        }

        if (obj_order_book__raw[key].order_way == "sell") {
          arr_order_book__sell.push(obj_order_book__raw[key]);
        }
      }

      this.order_book__proc__sort(arr_order_book__buy, arr_order_book__sell);
      this.order_book__proc__accumulate_amount__set();
      this.order_book__proc__higher_amount__set();
      this.order_book__proc__percent__set();
    },

    order_book__proc__sort(_arr_order_book__buy, _arr_order_book__sell) {
      this.model.order_book.proc.buy = this.order_book__sort__by_price(_arr_order_book__buy, def_sort_type.desc);
      this.model.order_book.proc.sell = this.order_book__sort__by_price(_arr_order_book__sell, def_sort_type.desc);

      let max_row = this.maxRow;
      let cnt_buy_over_max = this.model.order_book.proc.buy.length - max_row;
      let cnt_sell_over_max = this.model.order_book.proc.sell.length - max_row;

      if (cnt_buy_over_max > 0) {
        for (let i = 0; i < cnt_buy_over_max; i++) {
          this.model.order_book.proc.buy.pop();
        }
      }

      if (cnt_sell_over_max > 0) {
        for (let i = 0; i < cnt_sell_over_max; i++) {
          this.model.order_book.proc.sell.shift();
        }
      }
    },

    order_book__proc__accumulate_amount__set() {
      let b_accumulated_amount__all = this.$fw.big(0);
      let b_accumulated_amount__buy = this.$fw.big(0);
      let b_accumulated_amount__sell = this.$fw.big(0);

      this.model.order_book.proc.buy.map((_order_buy) => {
        let b_amount = this.$fw.big(_order_buy.total);
        b_accumulated_amount__buy = b_accumulated_amount__buy.plus(b_amount);
      });

      this.model.order_book.proc.sell.map((_order_sell) => {
        let b_amount = this.$fw.big(_order_sell.total);
        b_accumulated_amount__sell = b_accumulated_amount__sell.plus(b_amount);
      });

      b_accumulated_amount__all = b_accumulated_amount__buy.plus(b_accumulated_amount__sell);

      this.accumulated_amount.all = b_accumulated_amount__all.toString();
      this.accumulated_amount.buy = b_accumulated_amount__buy.toString();
      this.accumulated_amount.sell = b_accumulated_amount__sell.toString();
    },

    order_book__proc__higher_amount__set() {
      let b_higher_amount__all = this.$fw.big(0);
      let b_higher_amount__buy = this.$fw.big(0);
      let b_higher_amount__sell = this.$fw.big(0);

      this.model.order_book.proc.buy.map((_order_buy) => {
        let b_amount = this.$fw.big(_order_buy.amount);

        if (b_higher_amount__buy.cmp(b_amount) == def_b_greater_than_a) {
          b_higher_amount__buy = b_amount;
        }
      });

      this.model.order_book.proc.sell.map((_order_sell) => {
        let b_amount = this.$fw.big(_order_sell.amount);

        if (b_higher_amount__sell.cmp(b_amount) == def_b_greater_than_a) {
          b_higher_amount__sell = b_amount;
        }
      });

      b_higher_amount__all = b_higher_amount__buy;

      if (b_higher_amount__buy.cmp(b_higher_amount__sell) == def_b_greater_than_a) {
        b_higher_amount__all = b_higher_amount__sell;
      }

      this.higher_amount.all = b_higher_amount__all.toFixed();
      this.higher_amount.buy = b_higher_amount__buy.toFixed();
      this.higher_amount.sell = b_higher_amount__sell.toFixed();
    },

    order_book__proc__percent__set() {
      let price_now = this.priceNow;

      if (price_now == "" || price_now == "-") {
        return;
      }

      let b_price_now = this.$fw.big(price_now);

      this.model.order_book.proc.buy.map((_order_buy) => {
        let b_order_price = this.$fw.big(_order_buy.price);

        let percent = b_order_price.div(b_price_now).mul(100).minus(100).toFixed(2);
        _order_buy.percent = percent + "%";
      });

      this.model.order_book.proc.sell.map((_order_sell) => {
        let b_order_price = this.$fw.big(_order_sell.price);

        let percent = b_order_price.div(b_price_now).mul(100).minus(100).toFixed(2);
        _order_sell.percent = percent + "%";
      });
    },

    order_book__sort__by_price(_arr_obj_book__view, _sort_type) {
      let arr_result = [];

      if (_sort_type == def_sort_type.asc) {
        arr_result = _arr_obj_book__view.sort((a, b) => {
          return Number(a["price"]) < Number(b["price"]) ? -1 : Number(a["price"]) > Number(b["price"]) ? 1 : 0;
        });
      }

      if (_sort_type == def_sort_type.desc) {
        arr_result = _arr_obj_book__view.sort((a, b) => {
          return Number(a["price"]) > Number(b["price"]) ? -1 : Number(a["price"]) < Number(b["price"]) ? 1 : 0;
        });
      }
      return arr_result;
    },

    //============================================================================
    // ##ui
    ui__bar_position__get(_amount) {
      let pos_bar_start = 100;

      if (Number(_amount) == 0 || _amount == "") {
        return pos_bar_start + "%";
      }

      let b_amount = this.$fw.big(_amount);
      let pos_bar_result;

      let b_higher_amount__all = this.$fw.big(this.higher_amount.all);
      let higher_amount = b_higher_amount__all;

      pos_bar_result = this.$fw.big(pos_bar_start).minus(b_amount.div(higher_amount).mul(100)).toFixed();

      return this.barAlign == "left" ? "-" + pos_bar_result + "%" : pos_bar_result + "%";
    },
  },
});
</script>

<style lang="scss">
@use "sass:map";
@use "@/fw_config/config.scss" as *;

.fw-order-book {
  overflow-x: hidden;
  border-radius: 0px !important;

  .all {
    color: map.get($color-status, "primary");
  }

  .buy {
    color: $ctrl-order-book-buy-color;
  }

  .sell {
    color: $ctrl-order-book-sell-color;
  }

  .order-book-row-wrap {
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;

    &.sell-wrap {
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $ctrl-order-book-sell-color;
        opacity: 0.2;
      }
    }

    &.buy-wrap {
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $ctrl-order-book-buy-color;
        opacity: 0.2;
      }
    }

    .order-book-row {
      position: relative;
      padding: $ctrl-order-book-row-padding;
      cursor: pointer;

      .col-4 {
        z-index: 3 !important;
      }

      .progress-bar {
        position: absolute;
        right: 0px;
        top: 0px;
        z-index: 1;
        opacity: 0.3;
        width: 100%;
        height: 90%;

        &.no-spacing {
          height: 100%;
        }

        &.buy-bar {
          background-color: $ctrl-order-book-buy-color;
        }

        &.sell-bar {
          background-color: $ctrl-order-book-sell-color;
        }
      }
    }
  }
}
</style>
