<template>
  <div v-dimension="{ minHeight: 56, width: '100%' }">
    <div class="l-row" v-for="(row, key) in ui.model.pad.config" :key="key" no-gutters>
      <div class="l-col-4" v-for="(num, idx) in row" :key="idx">
        <div
          class="d-flex align-center justify-center cursor-pointer"
          v-dimension="{ minHeight: 56, width: '100%' }"
          ripple
          @click="__button__click__pad(num)"
        >
          <span class="text-title-sm font-weight-bold">
            {{ num }}
          </span>
        </div>
      </div>
    </div>
    <div class="l-row no-gutter">
      <div class="l-col-4">
        <div
          v-if="dot"
          class="d-flex align-center justify-center cursor-pointer"
          v-dimension="{ minHeight: 56, width: '100%' }"
          v-ripple
          @click="__button__click__dot"
        >
          <span class="text-title-sm font-weight-bold"> . </span>
        </div>
      </div>
      <div class="l-col-4">
        <div
          class="d-flex align-center justify-center cursor-pointer"
          v-dimension="{ minHeight: 56, width: '100%' }"
          v-ripple
          @click="__button__click__pad(0)"
        >
          <span class="text-title-sm font-weight-bold"> 0 </span>
        </div>
      </div>
      <div class="l-col-4">
        <div
          class="d-flex align-center justify-center cursor-pointer"
          v-dimension="{ minHeight: 56, width: '100%' }"
          v-ripple
          @click="__button__click__backspace"
        >
          <span class="text-title-sm font-weight-bold">
            <fw-icon icon="backspace-outline" height="26" width="42" />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let obj_lang = {
  number_pad: {},
};

export default {
  props: {
    modelValue: {},
    dot: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      ui: {
        model: {
          pad: {
            value: "",
            config: {
              row_1: [1, 2, 3],
              row_2: [4, 5, 6],
              row_3: [7, 8, 9],
            },
          },
        },
      },
    };
  },

  created() {
    this.__init();
  },

  mounted() {
    this.__loaded();
  },

  computed: {
    pad_value: {
      get() {
        return this.modelValue;
      },
      set(_value) {
        this.$emit("update:modelValue", _value);
      },
    },
  },

  watch: {
    //
  },

  methods: {
    __init() {
      this.$fw.lang.add(obj_lang);
    },

    __loaded() {
      //
    },

    __button__click__pad(_value) {
      this.pad_value = this.pad_value + "" + _value;
      this.$emit("change", _value);
    },

    __button__click__dot() {
      if (this.pad_value.indexOf(".") != -1) {
        return;
      }

      if (this.pad_value.length == 0) {
        this.pad_value = "0.";
      } else {
        this.pad_value = this.pad_value + ".";
      }
      this.$emit("change");
    },

    __button__click__backspace() {
      this.pad_value = this.pad_value.slice(0, -1);
      this.$emit("click:remove");
    },
  },
};
</script>
