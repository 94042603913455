
import { defineComponent, PropType } from "vue";
import { Line } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler,
  Plugin,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, LineElement, LinearScale, CategoryScale, PointElement, Filler);

export default defineComponent({
  name: "CompLineChart",
  components: {
    Line,
  },
  props: {
    chartValue: {
      type: Array as PropType<Array<number>>,
      default: () => [],
    },

    labels: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },

    label: {
      type: String,
      default: "Data",
    },

    labelPosition: {
      type: String,
      default: "top",
    },

    hideLabel: {
      type: Boolean,
      default: false,
    },

    labelAlign: {
      type: String,
      default: "center",
    },

    smooth: {
      type: Boolean,
      default: true,
    },

    removeGridX: {
      type: Boolean,
      default: false,
    },

    removeGridY: {
      type: Boolean,
      default: false,
    },

    lineColor: {
      type: String,
      default: "#000000",
    },

    lineWidth: {
      type: Number,
      default: 1,
    },

    hidePoint: {
      type: Boolean,
      default: false,
    },

    backgroundColor: {
      type: [String, Function],
      default: "transparent",
    },

    chartId: {
      type: String,
      default: "line-chart",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object as PropType<Partial<CSSStyleDeclaration>>,
      default: () => {}, // eslint-disable-line
    },
    plugins: {
      type: Array as PropType<Plugin<"line">[]>,
      default: () => [],
    },
  },

  computed: {
    chartData() {
      return {
        labels: this.labelsGenerator,
        datasets: [
          {
            label: this.label,
            borderColor: this.lineColor,
            fill: "start",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            backgroundColor: (_ctx: any) => {
              let backgroundColor;

              if (typeof this.backgroundColor == "function") {
                backgroundColor = this.backgroundColor(_ctx);
              } else {
                backgroundColor = this.backgroundColor;
              }

              return backgroundColor;
            },
            borderWidth: this.lineWidth,
            pointRadius: this.hidePoint ? 0 : 3,
            tension: this.smooth ? 0.4 : 0.1,
            data: this.chartValue,
          },
        ],
      };
    },

    labelsGenerator() {
      let labels = [];

      if (this.labels.length == 0) {
        for (let i = 0; i < this.chartValue.length; ++i) {
          labels.push("Data-" + (i + 1));
        }
      } else {
        labels = this.labels;
      }
      return labels;
    },

    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: !this.hideLabel,
            position: this.labelPosition,
            align: this.labelAlign,
          },
        },
        animation: {
          duration: 0,
        },
        tooltips: {
          enabled: false,
        },
        hover: {
          onHover: null,
          mode: null,
        },
        scales: {
          x: {
            grid: {
              drawOnChartArea: !this.removeGridX,
              drawBorder: !this.removeGridX,
              drawTicks: !this.removeGridX,
            },
            ticks: {
              display: !this.removeGridX,
            },
          },
          y: {
            grid: {
              drawOnChartArea: !this.removeGridY,
              drawBorder: !this.removeGridY,
              drawTicks: !this.removeGridY,
            },
            ticks: {
              display: !this.removeGridY,
            },
          },
        },
      };
    },
  },
});
