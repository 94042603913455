<template>
  <footer class="comp-footer bg-layer-1">
    <div
      class="l-container max-width-lg"
      :class="$fw.display.smAndDown ? 'pt-13 px-10 pb-24' : 'pt-15 px-23 pb-25'"
    >
      <div class="l-row-c" :class="$fw.display.smAndDown ? 'mb-8' : 'mb-15'">
        <fw-img
          :src="require('./res/img/img__logo__footer.png')"
          v-dimension="{
            width: '100%',
            maxWidth: $fw.display.smAndDown ? 102 : 124,
          }"
          class="cursor-pointer"
          @click="footer.event.click.logo"
        ></fw-img>
      </div>

      <div class="l-row-c mb-3">
        <span class="text-body-xxs text-secondary lh-15 ls-5">
          주소 : 경기도 안양시 동안구 시민대로 361 에이스평촌타워 1106호<br />
          대표자 곽현혁 | 사업자등록번호 411-87-01122 | 전화 070-4366-0198
        </span>
      </div>

      <router-link custom to="/privacy-policy" v-slot="{ navigate }">
        <div class="l-row">
          <span
            @click="navigate()"
            class="l-col-auto text-body-xxs text-secondary text-decoration-underline cursor-pointer"
            :class="$fw.display.smAndDown ? 'mb-13' : 'mb-9'"
          >
            개인정보 처리방침
          </span>
        </div>
      </router-link>

      <div class="l-row no-gutter">
        <div
          v-for="(menuItem, key) in ui.footer.menu.list"
          :key="key"
          class="l-col-auto"
          :class="$fw.display.smAndDown ? 'mr-5' : 'mr-8'"
        >
          <span
            class="text-body-xxs text-secondary cursor-pointer"
            @click="footer.event.click.menuItem(menuItem.path)"
          >
            {{ menuItem.name }}
          </span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { defineComponent, reactive, toRefs, computed } from "vue";
import { useFw } from "@/fw";
import { useRouter } from "vue-router";
import FaqMenuJson from "../res/data/data__menu.json";

export default defineComponent({
  name: "CompFooter",

  setup() {
    const { display } = useFw();
    const router = useRouter();

    const state = reactive({
      ui: {
        footer: {
          menu: {
            list: FaqMenuJson.menu_list,
          },
        },
      },
    });

    const footer = {
      event: {
        click: {
          logo: () => {
            router.push("/");
          },

          menuItem: (_menu_path) => {
            router.push(_menu_path);
          },
        },
      },
    };

    const linkListGap = computed(() => {
      switch (display.name) {
        case "xs":
        case "sm":
          return "mb-5";
        case "md":
          return "mb-11";
        default:
          return "mr-20";
      }
    });

    return {
      ...toRefs(state),
      footer,
      linkListGap,
    };
  },
});
</script>

<style lang="scss" scoped>
.pb-30 {
  padding-bottom: 120px;
}
</style>
