<template>
  <div v-show="isSelected" class="fw-tab--item">
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    value: {
      required: true,
    },
  },
  data() {
    return {
      selected: "",
    };
  },

  computed: {
    isSelected() {
      return this.$parent.selected == this.value;
    },

    selected_value() {
      return this.$parent.selected;
    }
  },
});
</script>

<style lang="scss">
  .fw-tab--item {
    height: inherit;
  }
</style>
