// Utilities
import { inject, reactive, ref, toRefs, watchEffect } from "vue";
import { mergeDeep } from "@/fw/js/util";
import type { App } from "vue";

// Globals
import { IN_BROWSER, SUPPORTS_TOUCH } from "@/fw/js/util/globals";

// Types
import type { InjectionKey, ToRefs } from "vue";

export type DisplayBreakpoint = keyof DisplayThresholds;

export interface DisplayThresholds {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
  xxl: number;
}

export interface DisplayOptions {
  mobileBreakpoint?: number | DisplayBreakpoint;
  thresholds?: Partial<DisplayThresholds>;
}

export interface InternalDisplayOptions {
  mobileBreakpoint: number | DisplayBreakpoint;
  thresholds: DisplayThresholds;
}

export interface DisplayPlatform {
  android: boolean;
  ios: boolean;
  cordova: boolean;
  electron: boolean;
  chrome: boolean;
  edge: boolean;
  firefox: boolean;
  opera: boolean;
  win: boolean;
  mac: boolean;
  linux: boolean;
  touch: boolean;
  ssr: boolean;
}

export interface DisplayInstance {
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
  xxl: boolean;
  smAndUp: boolean;
  mdAndUp: boolean;
  lgAndUp: boolean;
  xlAndUp: boolean;
  smAndDown: boolean;
  mdAndDown: boolean;
  lgAndDown: boolean;
  xlAndDown: boolean;
  name: DisplayBreakpoint;
  height: number;
  width: number;
  mobile: boolean;
  mobileBreakpoint: number | DisplayBreakpoint;
  platform: DisplayPlatform;
  thresholds: DisplayThresholds;
}

export const DisplaySymbol: InjectionKey<ToRefs<DisplayInstance>> = Symbol.for("fw:display");

function getCSSGridBreakpointValue(displayName: string) {
  const cssVariableName = "--breakpoint-" + displayName;
  const cssGridBreakpoint = getComputedStyle(document.documentElement).getPropertyValue(cssVariableName);
  return parseInt(cssGridBreakpoint, 10);
}

function getClientWidth(isHydrate?: boolean) {
  return IN_BROWSER && !isHydrate ? window.innerWidth : 0;
}

function getClientHeight(isHydrate?: boolean) {
  return IN_BROWSER && !isHydrate ? window.innerHeight : 0;
}

function getPlatform(): DisplayPlatform {
  const userAgent = IN_BROWSER ? window.navigator.userAgent : "ssr";

  function match(regexp: RegExp) {
    return Boolean(userAgent.match(regexp));
  }

  const android = match(/android/i);
  const ios = match(/iphone|ipad|ipod/i);
  const cordova = match(/cordova/i);
  const electron = match(/electron/i);
  const chrome = match(/chrome/i);
  const edge = match(/edge/i);
  const firefox = match(/firefox/i);
  const opera = match(/opera/i);
  const win = match(/win/i);
  const mac = match(/mac/i);
  const linux = match(/linux/i);
  const ssr = match(/ssr/i);

  return {
    android,
    ios,
    cordova,
    electron,
    chrome,
    edge,
    firefox,
    opera,
    win,
    mac,
    linux,
    touch: SUPPORTS_TOUCH,
    ssr,
  };
}

export function createDisplay(options?: DisplayOptions, isHydrate?: boolean): ToRefs<DisplayInstance> {
  const height = ref(getClientHeight(isHydrate));
  const platform = getPlatform();
  const state = reactive({} as DisplayInstance);
  const width = ref(getClientWidth(isHydrate));

  function onResize() {
    height.value = getClientHeight();
    width.value = getClientWidth();
  }

  if (isHydrate && IN_BROWSER) {
    requestAnimationFrame(() => onResize());
  }

  // eslint-disable-next-line max-statements
  watchEffect(() => {
    const thresholdsValues: DisplayThresholds = {
      xs: getCSSGridBreakpointValue("xs"),
      sm: getCSSGridBreakpointValue("sm"),
      md: getCSSGridBreakpointValue("md"),
      lg: getCSSGridBreakpointValue("lg"),
      xl: getCSSGridBreakpointValue("xl"),
      xxl: getCSSGridBreakpointValue("xxl"),
    };

    const xs = width.value < thresholdsValues.sm;
    const sm = width.value < thresholdsValues.md && !xs;
    const md = width.value < thresholdsValues.lg && !(sm || xs);
    const lg = width.value < thresholdsValues.xl && !(md || sm || xs);
    const xl = width.value < thresholdsValues.xxl && !(lg || md || sm || xs);
    const xxl = width.value >= thresholdsValues.xxl;
    const name = xs ? "xs" : sm ? "sm" : md ? "md" : lg ? "lg" : xl ? "xl" : "xxl";

    state.xs = xs;
    state.sm = sm;
    state.md = md;
    state.lg = lg;
    state.xl = xl;
    state.xxl = xxl;
    state.smAndUp = !xs;
    state.mdAndUp = !(xs || sm);
    state.lgAndUp = !(xs || sm || md);
    state.xlAndUp = !(xs || sm || md || lg);
    state.smAndDown = !(md || lg || xl || xxl);
    state.mdAndDown = !(lg || xl || xxl);
    state.lgAndDown = !(xl || xxl);
    state.xlAndDown = !xxl;
    state.name = name;
    state.height = height.value;
    state.width = width.value;
    state.platform = platform;
    state.thresholds = thresholdsValues;
  });

  if (IN_BROWSER) {
    window.addEventListener("resize", onResize, { passive: true });
  }

  return toRefs(state);
}

export function useDisplay() {
  const display = inject(DisplaySymbol);

  if (!display) throw new Error("Could not find display injection");

  return display;
}
