<template>
  <hr class="fw-line" v-dimension="{ height: height }" />
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "FwLine",

  props: {
    height: {
      type: [Number, String],
      default: 1,
    },
  },

  emits: ["click"],

  setup(props) {

    return {
    };
  },
});
</script>

<style lang="scss">
@use "sass:map";
@use "@/fw/scss/util.scss" as *;
@use "@/fw_config/config.scss" as *;

.fw-line {
  border-style: map.get($border-style, "normal");
}
</style>
