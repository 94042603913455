
import { computed, defineComponent, ref } from 'vue'

import FwDialogContent from './fw-dialog-content.vue'

export default defineComponent({
  name: 'DialogFrame', 
  components: {
    FwDialogContent,
  },
  inheritAttrs: false,
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },

    zIndex: {
      type: Number,
      required: true,
    },

    background: {
      type: [Boolean, String],
      required: true,
    },

    borderRadius: {
      type: [Boolean, Number, String],
      required: true,
    },

    contentClass: {
      type: String,
      required: true,
    },

    depressed: {
      type: Boolean,
      required: true,
    },

    fullscreen: {
      type: Boolean,
      required: true,
    },

    height: {
      type: [String, Number],
      required: true,
    },

    local: {
      type: Boolean,
      required: true,
    },

    maxWidth: {
      type: [String, Number],
      required: true,
    },

    scrollable: {
      type: Boolean,
      required: true,
    },

    bottom: {
      type: Boolean,
      required: false,
    },

    transition: {
      type: String,
      required: true,
    },

    width: {
      type: [String, Number],
      required: true,
    },
  },

  setup(props) {
    const frameElement = ref<Element>()

    // Add a quick "bounce" animation to the content
    const animateClick = () => {
      frameElement.value?.animate?.([
        { transformOrigin: 'center' },
        { transform: 'scale(1.03)' },
        { transformOrigin: 'center' },
      ], {
        duration: 150,
        easing: 'cubic-bezier(0.4, 0, 0.2, 1)',
      })
    }

    const classes = computed(() => [
      'fw-dialog-frame',
      {
        'fw-dialog-frame--active': props.isActive,
        'fw-dialog-frame--local': props.local,
        'fw-dialog-frame--bottom': props.bottom,
      },
    ])

    const styles = computed(() => ({
      zIndex: props.zIndex,
    }))

    return {
      frameElement,
      classes,
      styles,
      animateClick,
    }
  },
})
