<template>
  <div class="fw-progress-bar pos-relative text-only" :class="trackClasses" v-dimension="{ height: height }">
    <div class="progress-bar" :class="progressBarClasses" v-dimension="{ width: progressBarWidth }"></div>
    <div v-if="slotsExist" class="progress-bar__slot-container pos-absolute">
      <div>
        <slot :data="progressBarWidth"> </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { makeRoundedProps, useRounded } from "@/fw/composables/rounded";
import { defineComponent, reactive, toRefs, computed } from "vue";

export default defineComponent({
  name: "FwProgressBar",

  components: {
    //
  },

  props: {
    value: {
      type: Number,
      default: 0,
    },

    totalStep: {
      type: Number,
      default: 100,
    },

    height: {
      type: [Number, String],
      default: 8,
    },

    reverse: {
      type: Boolean,
      default: false,
    },

    striped: {
      type: Boolean,
      default: false,
    },

    ...makeRoundedProps(),
  },

  setup(props, { slots }) {
    const { roundedClasses } = useRounded(props);

    const state = reactive({
      //
    });

    const trackClasses = computed(() => {
      return [roundedClasses.value, props.striped ? "progress-striped active" : ""];
    });

    const progressBarClasses = computed(() => {
      return [props.reverse ? "ml-auto" : "", roundedClasses.value];
    });

    const progressBarWidth = computed(() => {
      if (!props.totalStep) {
        return "0%";
      }

      if (props.value > props.totalStep) {
        return "100%";
      }

      return `${(props.value / props.totalStep) * 100}%`;
    });

    const slotsExist = computed(() => {
      return slots.default;
    });

    return {
      ...toRefs(state),
      progressBarWidth,
      trackClasses,
      progressBarClasses,
      slotsExist,
    };
  },
});
</script>

<style lang="scss">
@use "sass:map";
@use "@/fw/scss/util.scss" as *;
@use "@/fw_config/config.scss" as *;

@mixin gradient-striped($color: rgba(255, 255, 255, 0.35), $angle: 45deg) {
  background-image: -webkit-linear-gradient(
    $angle,
    $color 25%,
    transparent 25%,
    transparent 50%,
    $color 50%,
    $color 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    $angle,
    $color 25%,
    transparent 25%,
    transparent 50%,
    $color 50%,
    $color 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    $angle,
    $color 25%,
    transparent 25%,
    transparent 50%,
    $color 50%,
    $color 75%,
    transparent 75%,
    transparent
  );
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: px(40) 0;
  }
  to {
    background-position: 0 0;
  }
}

// Spec and IE10+
@keyframes progress-bar-stripes {
  from {
    background-position: px(40) 0;
  }
  to {
    background-position: 0 0;
  }
}

@mixin animation($animation) {
  -webkit-animation: $animation;
  -o-animation: $animation;
  animation: $animation;
}

.fw-progress-bar {
  color: map.get($color-status, "normal");
  background-color: transparent !important;
  border: none !important;

  .progress-bar {
    background-color: currentColor;
    transition: width 0.3s ease-in-out;
    height: 100%;
  }

  .fw-progress-bar__slot-container {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &.active .progress-bar {
    @include animation(progress-bar-stripes 2s linear infinite);
  }

  &.progress-striped .progress-bar {
    @include gradient-striped;
    background-size: px(40) px(40);
    background-repeat: repeat;
  }
}
</style>
