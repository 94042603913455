<template>
	<div
		class="fw-avatar"
		v-html="avatar__get"
		flat
		v-dimension="{width: size, height: size}"
		:class="classes"
		@click="$emit('click')"
	>
	</div>
</template>

<script>
import { defineComponent } from "vue";
import { identicon } from "./fw-avatar__lib";

export default defineComponent({
	props: {
		"value": {
			type: String,
			default: "",
		},

		"size": {
			type: [Number, String],
			default: 30,
		},

		"badge": {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			
		};
	},

	computed: {
		avatar__get() {
			return identicon(this.value);
		},

		classes() {
			if(this.badge){
				return "rounded-circle bg-layer-2";
			}

			return "";
		},
	},
});
</script>