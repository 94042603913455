<template>
  <fw-carousel draggable hide-dot hideArrow>
    <div
      v-for="(market, pair) in model.markets.raw"
      :key="pair"
      class="l-card bg-layer-3 pa-4 mr-2"
      @click="__card__click__market(market)"
    >
      <p class="text-title-sm text-uppercase font-weight-bold mb-1">
        {{ market.token_code }} / {{ market.market_code }}
      </p>
      <div class="d-flex align-center flex-wrap gap-2">
        <fw-num-formatter
          class="text-title-sm font-weight-medium"
          :value="market.close_price"
          :precision="market.token_precision"
          :class="ui__get__price_color(market.change_rate)"
        />

        <div class="d-flex align-center" :class="ui__get__price_color(market.change_rate)">
          <span class="font-weight-regular"> {{ ui__get__price_sign(market.change_rate) }} </span>
          <fw-num-formatter :value="market.change_rate" :precision="market.token_precision" unit="%" sign />
        </div>
      </div>
    </div>
  </fw-carousel>
</template>

<script>
export default {
  name: "FwMarketCards",
  components: {},
  data() {
    return {
      model: {
        markets: {
          raw: {},
          proc: [],
        },
      },
    };
  },
  methods: {
    __init() {},

    __loaded() {
      //
    },

    $_init_reset() {
      this.model.markets.raw = {};
      this.model.markets.proc = [];
    },

    __card__click__market(_market_info) {
      this.$emit("click:market", _market_info);
    },

    $_set_market_item(_token_code, _token_precision, _market_code, _close_price, _change_rate, _volume_price) {
      this.markets__set(_token_code, _token_precision, _market_code, _close_price, _change_rate, _volume_price);
    },

    markets__set(_token_code, _token_precision, _market_code, _close_price, _change_rate, _volume_price) {
      if (typeof _token_code == "undefined") {
        return;
      }

      let market = _token_code + "_" + _market_code;

      this.model.markets.raw[market] = {
        token_code: _token_code,
        token_precision: _token_precision,
        market_code: _market_code,
        close_price: _close_price,
        change_rate: _change_rate,
        volume_price: _volume_price,
      };
    },

    ui__get__price_color(_change_rate) {
      const color_rise = "text-rise";
      const color_fall = "text-fall";
      const color_default = "";

      let change_percent = parseFloat(_change_rate);

      if (isNaN(change_percent) || change_percent == 0.0) {
        return color_default;
      }

      if (change_percent > 0.0) {
        return color_rise;
      } else {
        return color_fall;
      }
    },

    ui__get__price_sign(_change_rate) {
      const color_rise = "▲";
      const color_fall = "▼";
      const color_default = "";

      let change_percent = parseFloat(_change_rate);

      if (isNaN(change_percent) || change_percent == 0.0) {
        return color_default;
      }

      if (change_percent > 0.0) {
        return color_rise;
      } else {
        return color_fall;
      }
    },
  },
};
</script>
