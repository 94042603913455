<template>
  <div style="height: 100%">
    <div :id="container_id" style="height: inherit"></div>
  </div>
</template>

<script>
const SCRIPT_ID = "tradingview-widget-script";

export default {
  props: {
    "container-id": {
      type: String,
      default: "vue-trading-view",
    },
  },

  data() {
    return {
      container_id: this.containerId,
      options: {},
    };
  },

  mounted() {
    // this.init__trading_view();
  },

  watch: {
    "$fw.theme.name": {
      handler() {
        this.init__trading_view();
      },
      immediate: true,
    },
  },

  methods: {
    can_usd_dom() {
      return typeof window !== "undefined" && window.document && window.document.createElement;
    },

    init__trading_view() {
      const theme = "Light";

      this.options = {
        symbol: "BTCUSD",
        theme: theme,
        height: "100%",
        width: "100%",
        enabled_features: ["header_in_fullscreen_mode"],
        disabled_features: [
          "save_chart_properties_to_local_storage",
          "show_interval_dialog_on_key_press",
          "volume_force_overlay",
          "symbol_search_hot_key",

          // header
          "header_saveload",
          "header_symbol_search",
          "header_chart_type",
          "header_screenshot",
          "header_indicators",
          "header_compare",
          "header_undo_redo",
          "header_interval_dialog_button",

          "left_toolbar",
          "popup_hints",
          "symbol_info",

          // legend
          // "legend_widget",
          "object_tree_legend_mode",
          "show_hide_button_in_legend",
          "format_button_in_legend",
          "delete_button_in_legend",
          "study_templates",

          // menus
          "context_menus",
          "pane_context_menu",
          "scales_context_menu",
          "legend_context_menu",
          "main_series_scale_menu",
          "remove_library_container_border",

          // property
          "property_pages",
          "chart_property_page_style",
          "chart_property_page_scales",
          "chart_property_page_background",
          "constraint_dialogs_movement",

          "axis_pressed_mouse_move_scale",
          "vert_touch_drag_scroll",
          "source_selection_markers",
          "chart_crosshair_menu",
          "display_market_status",
          "save_shortcut",
          "control_bar",
          "show_chart_property_page",
          "study_dialog_search_control",
        ],
      };

      this.script_element__append(this.trading_view__init);
    },

    //====================================================================================
    // ##script_element
    script_element__append(_cb_trading_view__init) {
      if (!this.can_usd_dom()) {
        _cb_trading_view__init();
        return;
      }

      if (this.script_element__is_exist()) {
        if (typeof TradingView === "undefined") {
          this.script_element__on_load(_cb_trading_view__init);
          return;
        }

        _cb_trading_view__init();
        return;
      }
      const script = document.createElement("script");

      script.id = SCRIPT_ID;
      script.type = "text/javascript";
      script.async = true;
      script.src = "https://s3.tradingview.com/tv.js";
      script.onload = _cb_trading_view__init;
      document.getElementsByTagName("head")[0].appendChild(script);
    },

    script_element__get() {
      return document.getElementById(SCRIPT_ID);
    },

    script_element__on_load(onload) {
      const script = this.script_element__get();
      const oldOnload = script.onload;
      return (script.onload = () => {
        oldOnload();
        onload();
      });
    },

    script_element__is_exist() {
      return this.script_element__get() !== null;
    },

    //====================================================================================
    // ##trading_view
    trading_view__init() {
      if (typeof TradingView === "undefined") {
        return;
      }

      try {
        new window.TradingView.widget(Object.assign({ container_id: this.container_id }, this.options));
      } catch (error) {
        setTimeout(() => {
          this.trading_view__init();
        }, 1000);
      }
    },
  },
};
</script>

<style lang="scss"></style>
