//====================================
// ## vue
import { reactive } from 'vue';
import type { App } from 'vue'

//====================================
// ## js
import c_big from "./js/big";
import { c_func } from "./js/functions.js";
import { c_router } from "./js/router";
import { c_store } from "./js/vuex";
import { c_i18n, lang, LangSymbol } from "./js/i18n";

//====================================
// ## directives
import c_directives from "./directives/index";

//====================================
// ## global components
import c_comp from "./components/global_components";

//====================================
// ## composables 
import { createDisplay, DisplaySymbol } from '@/fw/composables/display'; 
// import { createTheme, ThemeSymbol } from '@/fw/composables/theme'

//====================================
// ## scss
import "./scss/main.scss";

//====================================
// ## util
import { inject } from "@/fw/js/util";

//====================================
// ## type
declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $fw: any; // eslint-disable-line
    $tt: any; // eslint-disable-line
    $snackbar: any; // eslint-disable-line
    $dialog: any; // eslint-disable-line
  }
}

const display = createDisplay();
// const theme = createTheme();

export default {
  install: (app: App) => { 
    // plugins
    app.use(c_i18n);
    app.use(c_router);
    app.use(c_store);
    app.use(c_comp);
    app.use(c_directives);
    // theme.install(app);

    // global properties
    app.config.globalProperties.$tt = lang.trans;

    app.provide(DisplaySymbol, display);
    // app.provide(ThemeSymbol, theme)
    app.provide(LangSymbol, lang);

    app.mixin({
      // todo 
      // - lang , func, big 도 display와 같이 composable 화 필요 
      computed: {
        $fw () {
          return reactive({
            display: inject.call(this, DisplaySymbol),
            // theme: inject.call(this, ThemeSymbol),
            lang: lang, 
            func: c_func,
            big: c_big,
          })
        },
      },
    })
  },

  isReady: c_router.isReady,
};


import { inject as appInject } from "vue";

export const useFw = () => {
  return reactive({
    display: appInject(DisplaySymbol),
    // theme: appInject(ThemeSymbol),
    lang: appInject(LangSymbol), 
    func: c_func,
    big: c_big,
  })
}