<template>
  <header
    class="pos-sticky comp-header-container bg-layer-0"
    :class="
      ui.scroll.isDown && $fw.display.mdAndDown ? 'header-hide' : 'header-show'
    "
  >
    <div
      class="comp-header l-container max-width-lg py-0"
      :class="[
        $fw.display.smAndDown
          ? 'comp-header-mobile px-6'
          : 'comp-header-pc px-10',
        ui.header.menu.isActive ? 'border-bottom' : '',
      ]"
    >
      <div class="l-row align-center no-gutter">
        <div class="l-col-auto">
          <fw-img
            v-if="$fw.display.smAndDown"
            class="vertical-align-bottom cursor-pointer"
            :src="require('./res/img/img__logo__mobile.png')"
            v-dimension="{ maxWidth: 97, width: '100%' }"
            @click="header.event.click.logo"
          ></fw-img>

          <fw-img
            v-else
            class="vertical-align-bottom cursor-pointer"
            :src="require('./res/img/img__logo__pc.png')"
            v-dimension="{ maxWidth: 120, width: '100%' }"
            @click="header.event.click.logo"
          ></fw-img>
        </div>

        <div class="l-spacer"></div>

        <div class="l-col-auto">
          <fw-button
            v-if="$fw.display.smAndDown"
            class="icon"
            v-dimension="{ width: 32, height: 32 }"
            v-ripple
            @click="header.event.click.menuIcon"
          >
            <fw-img
              :src="require(`./res/img/${menuIconPathGet}`)"
              v-dimension="{ height: 14 }"
            ></fw-img>
          </fw-button>

          <Transition name="drawer">
            <div
              v-if="!$fw.display.smAndDown || ui.header.menu.isActive"
              class="l-row comp-header-menu-list no-gutter bg-layer-0"
              :class="
                $fw.display.smAndDown
                  ? 'pos-absolute flex-column pt-10'
                  : 'align-center'
              "
            >
              <div
                v-for="(menuItem, key) in ui.header.menu.list"
                :key="key"
                class="l-col-auto comp-header-menu-item d-flex align-center"
                :class="
                  $fw.display.smAndDown
                    ? 'text-body-md font-weight-medium ml-7'
                    : 'ml-8'
                "
              >
                <router-link
                  custom
                  :to="menuItem.path"
                  v-slot="{ navigate, href, isActive }"
                >
                  <div
                    @click="header.menuClose(), navigate()"
                    :href="href"
                    class="cursor-pointer"
                    :class="[
                      isActive ? 'router-link-active' : '',
                      $fw.display.smAndDown
                        ? 'd-flex align-center w-100 h-75 mr-5'
                        : '',
                    ]"
                  >
                    <span>
                      {{ menuItem.name }}
                    </span>
                  </div>
                </router-link>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </div>
  </header>

  <fw-overlay
    v-if="$fw.display.smAndDown"
    v-model="ui.header.menu.isActive"
    class="bg-layer-8"
  ></fw-overlay>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  watch,
  onBeforeMount,
  computed,
} from "vue";
import { useFw } from "@/fw";
import { useRouter } from "vue-router";
import FaqMenuJson from "../res/data/data__menu.json";
import { useOnScrollDown } from "@/composables/useOnScrollDown";

let obj_lang = {
  header: {
    company: {
      en: "Company",
      ko: "회사소개",
    },

    business: {
      en: "Business",
      ko: "사업소개",
    },

    recruit: {
      en: "Recruit",
      ko: "채용",
    },

    news: {
      en: "News",
      ko: "뉴스룸",
    },
  },
};

export default defineComponent({
  name: "CompHeader",

  setup() {
    const { lang, display } = useFw();
    const router = useRouter();
    const state = reactive({
      ui: {
        header: {
          menu: {
            list: FaqMenuJson.menu_list,
            isActive: false,
          },
        },

        scroll: {
          isDown: false,
        },
      },
    });

    onBeforeMount(() => {
      lang.add(obj_lang);
    });

    const setScrollDirection = (_isDown) => {
      state.ui.scroll.isDown = _isDown;
    };

    const onScrollDown = useOnScrollDown(setScrollDirection);

    const header = {
      event: {
        click: {
          logo: () => {
            router.push("/");
            header.menuClose();
          },

          menuIcon: () => {
            state.ui.header.menu.isActive = !state.ui.header.menu.isActive;
          },

          menuItem: (_menu_path) => {
            router.push(_menu_path);
            header.menuClose();
          },
        },
      },

      menuClose: () => {
        state.ui.header.menu.isActive = false;
      },

      setScrollPrevent: () => {
        const elHtml = document.getElementsByTagName("html")[0];
        elHtml.classList.add("overflow-hidden");
      },

      clearScrollPrevent: () => {
        const elHtml = document.getElementsByTagName("html")[0];
        elHtml.classList.remove("overflow-hidden");
      },
    };

    const headerLogoWidth = computed(() => {
      return display.smAndDown ? "97" : "120";
    });

    const menuIconPathGet = computed(() => {
      return state.ui.header.menu.isActive
        ? "img__icon__exit.png"
        : "img__icon__menu.png";
    });

    watch(
      () => state.ui.header.menu.isActive,
      (_value) => {
        if (_value) {
          header.setScrollPrevent();
        } else {
          header.clearScrollPrevent();
        }
      }
    );

    watch(
      () => display.smAndDown,
      (_value) => {
        if (!_value) {
          header.menuClose();
        }
      }
    );

    return {
      ...toRefs(state),
      header,
      headerLogoWidth,
      menuIconPathGet,
    };
  },
});
</script>

<style lang="scss" scoped>
@use "sass:map";
@use "@/fw_config/config.scss" as *;

$header-height-pc: 4.25rem;
$header-height-mobile: 3.5rem;
$header-drawer-width: 15.625rem;
$header-drawer-menu-item-min-width: 3.81rem;
$header-drawer-menu-item-height: 3.75rem;

.comp-header-container {
  &.header-hide {
    transform: translateY(-100%);
  }
  &.header-show {
    transform: translateY(0);
  }

  transition: transform 0.5s ease-in-out;
  top: 0;
  left: 0;
  z-index: 10;
}
.comp-header {
  &.comp-header-pc {
    height: $header-height-pc;
    .comp-header-menu-item {
      min-width: $header-drawer-menu-item-min-width;
    }
  }

  &.comp-header-mobile {
    height: $header-height-mobile;
    .comp-header-menu-list {
      max-width: $header-drawer-width;
      width: 100%;
      height: calc(100vh - $header-height-mobile);
      top: $header-height-mobile;
      left: 0;

      .comp-header-menu-item {
        min-height: $header-drawer-menu-item-height;
      }
    }
  }
}

.drawer-enter-from,
.drawer-leave-to {
  transform: translateX(-100%);
}
.drawer-leave-active,
.drawer-enter-active {
  transition: transform 0.05s;
}

.router-link-active {
  color: map.get($color-status, "primary") !important;
}
</style>
