<template>
  <div>
    <TransitionGroup name="list">
      <slot></slot>
    </TransitionGroup>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";

export default defineComponent({
  name: "FwListTransition",

  // props: {
  //
  // },

  setup() {
    const state = reactive({
      //
    });

    return {
      ...toRefs(state),
    };
  },
});
</script>

<style lang="scss">
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
