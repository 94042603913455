import { onMounted, onUnmounted } from "vue";

export function useOnScrollDown(_callback) {
  onMounted(() => {
    onScrollDown.set();
  });

  onUnmounted(() => {
    onScrollDown.clear();
  });

  let prevScrollTop = 0;

  const onScrollDown = {
    set: () => {
      document.addEventListener("scroll", onScrollDown.get);
    },

    get: () => {
      let nextScrollTop = window.pageYOffset || 0;

      if (nextScrollTop < prevScrollTop || window.pageYOffset < 100) {
        if (_callback) {
          _callback(false);
        }

      } else if (nextScrollTop > prevScrollTop) {
        if (_callback) {
          _callback(true);
        }
      }

      prevScrollTop = nextScrollTop;
    },

    clear: () => {
      document.removeEventListener("scroll", onScrollDown.get);
    },
  };
}
