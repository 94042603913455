<template>
  <div class="fw-trade-history mb-4" v-dimension="{ height: 40 }">
    <fw-select
      v-dimension="{ width: 130 }"
      v-model="ui.form.select.filter.value"
      :options="ui.form.select.filter.options"
      @change="__select__change__asset"
    >
      <template #selected="{ selected }">
        <p class="text-capitalize">{{ selected.text || selected }}</p>
      </template>

      <template #options="{ options, onClick }">
        <div v-for="(option, key) in options" :key="key" @click="onClick(option.value)">
          <div class="px-3 py-2">
            {{ option.text }}
          </div>
        </div>
      </template>
    </fw-select>
  </div>

  <fw-scroll>
    <div v-dimension="{ height: scrollHeight }">
      <div
        v-for="(history_group_by_date, date) in model.history.proc"
        :key="date"
        :class="{ 'mb-8': history_group_by_date.length != 0 }"
      >
        <p class="text-body-lg text-desc font-weight-medium mb-3" v-if="history_group_by_date.length != 0">
          {{ date }}
        </p>
        <fw-list-item
          class="d-flex align-center rounded-0 px-3 py-4 cursor-pointer"
          background="layer1"
          v-for="(history, key) in history_group_by_date"
          :key="key"
          @click="__item__click__open_order(history)"
        >
          <fw-symbol-img class="mr-2" :asset-code="history.token_code" :folder-path="pathSymbol" size="42" />

          <div>
            <p
              class="text-body-xl font-weight-bold text-capitalize mb-1"
              :class="get__orderway_color(history.order_way)"
            >
              {{ history.order_way }}
            </p>

            <p class="text-desc">
              <!-- {{ format(new Date(history.time), "PPpp") }} -->
              {{ history.time }}
            </p>
          </div>

          <div class="l-spacer" />

          <div class="d-flex flex-column align-end">
            <fw-num-formatter
              class="text-body-xl font-weight-regular mb-1"
              :value="history.order_amount"
              :precision="8"
              :unit="history.token_code"
              unit-class="font-weight-bold"
            />

            <div class="d-flex align-center text-desc">
              <span class="mr-1 mb-1"> Price: </span>

              <fw-num-formatter
                class="font-weight-regular mb-1"
                :value="history.order_price"
                :precision="8"
                :unit="history.market_code"
              />
            </div>
          </div>
        </fw-list-item>
      </div>
      <fw-no-data v-model="is_show_no_data" />
    </div>
    <comp-modal-transfer-detail ref="txDetail" v-model="ui.modal.transfer_detail.is_show" :path-symbol="pathSymbol" />
  </fw-scroll>
</template>

<script>
import { format } from "date-fns";
import CompModalTransferDetail from "./fw-trade-history-detail-popup.vue";

let obj_lang = {
  comp_activity: {},
};

export default {
  components: {
    CompModalTransferDetail,
  },

  props: {
    "path-symbol": {
      type: String,
      default: undefined,
    },
    scrollHeight: {
      type: Number,
      default: 330,
    },
  },

  data() {
    return {
      model: {
        history: {
          raw: [],
          proc: {},
        },
      },

      ui: {
        form: {
          select: {
            filter: {
              value: "all",
              options: [
                { value: "all", text: "All" },
                { value: "buy", text: "Buy" },
                { value: "sell", text: "Sell" },
              ],
            },
          },
        },
        modal: {
          transfer_detail: {
            is_show: false,
          },
        },
      },
    };
  },

  created() {
    this.__init();
  },

  mounted() {
    this.__loaded();
  },

  computed: {
    is_show_no_data() {
      let is_show = false;

      const history = Object.values(this.model.history.proc);

      if (history.length == 0) return true;

      for (let i = 0; i < history.length; i++) {
        if (history[i].length != 0) {
          is_show = false;
          break;
        } else {
          is_show = true;
        }
      }

      return is_show;
    },
  },

  methods: {
    format,

    __init() {
      this.$fw.lang.add(obj_lang);
    },

    __loaded() {
      //
    },

    get__orderway_color(_order_way) {
      if (_order_way == "buy") {
        return "text-buy";
      } else if (_order_way == "sell") {
        return "text-sell";
      }
    },

    __select__change__asset() {
      this.tx_history_proc();
    },

    __item__click__open_order(_open_order) {
      this.ui.modal.transfer_detail.is_show = true;

      this.$refs.txDetail.set_transfer_details(
        _open_order.seq,
        _open_order.token_code,
        _open_order.token_name,
        _open_order.market_code,
        _open_order.order_way,
        _open_order.order_type,
        _open_order.order_price,
        _open_order.order_amount,
        0,
        _open_order.order_fee,
        _open_order.tx_hash,
        _open_order.total_price,
        "complete",
        _open_order.time
      );
    },

    //=======================================================
    // ##activity
    $_init_reset() {
      this.model.history.raw = [];
      this.model.history.proc = {};
    },

    $_set_history_item(
      _seq, // 주문번호
      _token_code, // 토큰 코드
      _token_name, // 토큰 이름
      _market_code, // 마켓 코드
      _order_way, // 주문 방향
      _order_type, // 주문 타입
      _order_price, // 주문 가격
      _order_amount, // 주문 수량
      _fee, // 수수료
      _tx_hash, // 거래 해시
      _total_price, // 주문 총액
      _order_status, // 주문 상태
      _order_time // 주문 시간
    ) {
      let obj_history = {
        seq: _seq,
        token_code: _token_code,
        token_name: _token_name,
        market_code: _market_code,
        order_way: _order_way,
        order_price: _order_price,
        order_amount: _order_amount,
        time: _order_time,
        order_status: _order_status,
        order_type: _order_type,
        tx_hash: _tx_hash,
        order_fee: _fee,
        total_price: _total_price,
      };

      this.model.history.raw.push(obj_history);
      this.tx_history_proc();
    },

    // 동일한 거래일별로 그룹핑
    tx_history_proc() {
      let obj_proc = {};

      this.model.history.raw.map((_tx) => {
        // 년, 월, 일 추출
        let date = new Date(_tx.time);
        let year = date.getFullYear();
        let month = date.getMonth();
        let day = date.getDate();

        let str_date = format(new Date(year, month, day), "PP");
        // str_data를 읽기 편하도록 정리

        if (obj_proc[str_date] == undefined) {
          obj_proc[str_date] = [];
        }

        // order_way all, buy, sell 필터링
        let filter = this.ui.form.select.filter.value;

        if (filter == "all") {
          obj_proc[str_date].push(_tx);
        } else {
          if (this.ui.form.select.filter.value == _tx.order_way.toLowerCase()) {
            obj_proc[str_date].push(_tx);
          }
        }

        this.model.history.proc = obj_proc;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
