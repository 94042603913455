<template>
  <div
    class="fw-file-input d-flex justify-center align-center cursor-pointer pa-4"
    :class="classes"
    @dragenter="event.dragenter"
    @dragover="event.dragover"
    @dragleave="event.dragleave"
    @drop="event.drop"
  >
    <div v-if="ui.form.file_input.files.length < 1">
      <span v-if="!ui.form.file_input.is_active" class="fw-file-input__message font-weight-medium text-title-xs">
        <slot name="drag_message">
          {{ $tt("file_input.drag_here") }}
        </slot>
      </span>

      <span v-else class="fw-file-input__message font-weight-medium text-title-xs">
        {{ $tt("file_input.drop_here") }}
      </span>
    </div>

    <div v-else>
      <div v-if="!showImage" class="d-flex flex-wrap justify-center">
        <div
          v-for="(file, key) in ui.form.file_input.files"
          :key="key"
          class="fw-file-input__file ma-2"
          v-dimension="{ maxWidth: '135' }"
        >
          <fw-icon icon="file-outline" class="mr-2"></fw-icon>

          <span>
            {{ file.name }}
          </span>
        </div>
      </div>

      <div v-else class="d-flex justify-center flex-wrap gap-10">
        <div v-for="(file, key) in ui.form.file_input.base64_storage" :key="key">
          <fw-img v-if="checkFileTypeImage(file.type)" :src="file.base64" height="100%" width="100%" />

          <div v-else class="l-card text-truncate ma-2">
            <fw-icon icon="file-outline" class="mr-2"></fw-icon>
            <span>
              {{ file.name }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <fw-button class="fw-file-input__button ml-auto" rounded="sm">
      <span class="text-body-md">
        {{ $tt("file_input.upload") }}
      </span>
    </fw-button>

    <div :class="ui.form.file_input.is_hover ? `fw-file-input__is_hover` : ''" />

    <input
      id="fw_file_input"
      class="fw-file-input__input cursor-pointer h-100 w-100 pa-0"
      type="file"
      :multiple="multiple"
      @change="event.change"
      @mouseover="event.mouseover"
      @mouseout="event.mouseout"
    />
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onBeforeMount, computed } from "vue";
import { useFw } from "@/fw";

let obj_lang = {
  file_input: {
    drag_here: {
      en: "Drag file here",
      ko: "파일을 이곳으로 드래그하세요",
    },
    drop_here: {
      en: "Drop!",
      ko: "놓으세요!",
    },
    upload: {
      en: "Upload",
      ko: "업로드",
    },
  },
};

export default defineComponent({
  name: "FwFileInput",

  props: {
    class: {
      type: String,
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    activeClass: {
      type: String,
      default: "primary",
    },

    showImage: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    "change:file", // (구) 파일 업로드시 base64를 인코딩 promise 객체로 반환 (해당 emit 사용중인 작업물 확인 후 삭제 예정)
    "upload", // 이미지를 인코딩한 base64값으로 반환
  ],

  setup(props, { emit }) {
    const { lang, func } = useFw();

    const state = reactive({
      ui: {
        form: {
          file_input: {
            files: [],
            is_active: false,
            is_hover: false,
            base64_storage: [],
          },
        },
      },
    });

    const classes = computed(() => {
      return [props.class, state.ui.form.file_input.is_active ? `fw-file-input__active` : ""];
    });

    const api = {
      clear: () => {
        state.ui.form.file_input.files = [];
        state.ui.form.file_input.base64_storage = [];
      },
    };

    const event = {
      change: (_event) => {
        state.ui.form.file_input.files = [];
        Object.values(_event.target.files).map((_file) => {
          _file.base64 = func.util.img.encode_base64(_file);
          state.ui.form.file_input.files.push(_file);
        });

        emit("change:file", state.ui.form.file_input.files);
        inputStatus.removeActive();

        base64Storage.listSet();
      },

      dragenter: () => {
        inputStatus.setActive();
      },

      dragover: () => {
        inputStatus.setActive();
      },

      dragleave: () => {
        inputStatus.removeActive();
      },

      drop: () => {
        inputStatus.removeActive();
      },

      mouseover: () => {
        inputStatus.setHover();
      },

      mouseout: () => {
        inputStatus.removeHover();
      },
    };

    const base64Storage = {
      listSet: () => {
        state.ui.form.file_input.base64_storage = [];

        state.ui.form.file_input.files.map((_file) => {
          _file.base64
            .then((file_base64) => {
              let file_info = {
                name: _file.name,
                type: _file.type,
                base64: file_base64,
              };
              state.ui.form.file_input.base64_storage.push(file_info);
            })
            .catch((_err) => {
              console.error(_err);
            });
        });

        if (state.ui.form.file_input.files.length > 0) {
          emit("upload", state.ui.form.file_input.files);
        }
      },
    };

    const checkFileTypeImage = (_file) => {
      return _file && _file.split("/")[0] === "image";
    };

    const inputStatus = {
      setActive: () => {
        if (!state.ui.form.file_input.is_active) {
          state.ui.form.file_input.is_active = true;
        }
      },

      removeActive: () => {
        if (state.ui.form.file_input.is_active) {
          state.ui.form.file_input.is_active = false;
        }
      },

      setHover: () => {
        if (!state.ui.form.file_input.is_hover) {
          state.ui.form.file_input.is_hover = true;
        }
      },

      removeHover: () => {
        if (state.ui.form.file_input.is_hover) {
          state.ui.form.file_input.is_hover = false;
        }
      },
    };

    onBeforeMount(() => {
      lang.add(obj_lang);
    });

    return {
      ...toRefs(state),
      classes,
      api,
      event,
      checkFileTypeImage,
    };
  },
});
</script>

<style lang="scss" scoped>
@use "@/fw/scss/util.scss" as *;
@use "@/fw_config/config.scss" as *;

.fw-file-input {
  min-height: $ctrl-file-input-height;
  position: relative;
  border: 1px solid $ctrl-file-input-border-color;
  background-color: $ctrl-file-input-background-color;
  color: $ctrl-file-input-font-color;
  border-radius: px($ctrl-file-input-border-radius);

  .fw-file-input__input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0 !important;
  }

  .fw-file-input__button {
    position: absolute;
    top: px(16);
    right: px(16);
    background-color: $ctrl-file-input-button-color;
  }

  .fw-file-input__message {
    color: $ctrl-file-input-message-color;
    transition: 0.2s;
  }

  .fw-file-input__file {
    background-color: $ctrl-file-input-file-box-background-color;
    color: $ctrl-file-input-file-box-font-color;
    padding: $ctrl-file-input-file-box-padding;
    border-radius: px($ctrl-file-input-file-box-border-radius);
  }

  &.fw-file-input__active {
    border: 1px solid currentColor;
  }

  .fw-file-input__hover-bg {
    color: inherit;
  }

  .fw-file-input__is_hover {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    width: 100%;
    height: 100%;
    background: currentColor;
    opacity: 0.05;
    border-radius: inherit;
    pointer-events: none;
  }
}
</style>
