<template>
  <div v-if="modelValue" class="fw-no-data d-flex flex-column justify-center align-center w-100">
    <fw-img :src="getNoDataImg" alt="No Data" class="flex-grow-0" />

    <slot name="message">
      <div class="text-center">
        <span class="text-body-md">
          {{ message || $tt("no_data.no_data") }}
        </span>
      </div>
    </slot>
  </div>
</template>

<script>
import { computed } from "vue";

let obj_lang = {
  no_data: {
    no_data: {
      en: "No Data",
      ko: "데이터가 없어요",
    },
  },
};

export default {
  name: "FwNoData",

  props: {
    modelValue: {},
    message: {
      type: String,
    },
    img: {
      type: String,
    },
  },

  created() {
    this.$fw.lang.add(obj_lang);
  },

  setup(props) {
    const getNoDataImg = computed(() => {
      return props.img || require("./fw-no-data__img.png");
    });

    return {
      getNoDataImg,
    };
  },
};
</script>
