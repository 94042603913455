<template>
  <div
    class="fw-dropdown-menu"
    :class="classes"
    ref="dropdown_menu"
    v-click-outside="{
      handler: onClickOutside,
      include: onClickOutsideIncluded,
    }"
  >
    <div class="fw-dropdown-menu--content" v-if="ui.status.is_show">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    active: { type: Boolean, default: false },

    clickOnClose: {
      type: Boolean,
      default: true,
    },

    fluid: {
      type: Boolean,
      default: false,
    },

    layer: {
      type: Boolean,
      default: false,
    },

    nowrap: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      ui: {
        status: {
          is_show: false,
        },
      },
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.isActive = this.ui.status.is_show;
      let el_parent = this.$refs.dropdown_menu.parentElement;

      el_parent.classList.add("fw-dropdown-menu--parent");
      el_parent.addEventListener("click", this.handleContentVisibility);
    });
  },

  beforeUnmount() {
    this.$refs.dropdown_menu.parentElement.removeEventListener("click", this.handleContentVisibility);
  },

  computed: {
    classes() {
      return {
        "fw-dropdown-menu__fluid": this.fluid,
        "fw-dropdown-menu__layer": this.layer,
        "fw-dropdown-menu__nowrap": this.nowrap,
      };
    },
  },

  methods: {
    handleContentVisibility(_event) {
      if (!this.ui.status.is_show) {
        this.ui.status.is_show = true;
        this.emitChange();
        return;
      }

      let is_clicked_content = _event.target.closest(".fw-dropdown-menu--content") != null;

      if (is_clicked_content && !this.clickOnClose) {
        return;
      }

      if (this.ui.status.is_show) {
        this.ui.status.is_show = false;
        this.emitChange();
        return;
      }
    },

    onClickOutside() {
      if (!this.ui.status.is_show) {
        return;
      }

      this.ui.status.is_show = false;
      this.emitChange();
    },

    onClickOutsideIncluded() {
      let el_parent = this.$refs.dropdown_menu.parentElement;
      return [el_parent];
    },

    emitChange() {
      this.$emit("change", this.ui.status.is_show);
    },
  },
});
</script>

<style lang="scss">
@use "sass:map";
@use "@/fw/scss/util.scss" as *;
@use "@/fw_config/config.scss" as *;

//------------------------------------------//
// config
$dropdown__radius: map.get($border-rounded, "md"); // 임시
//===========================================//

.fw-dropdown-menu--parent {
  position: relative;
}

.fw-dropdown-menu {
  min-width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  background-color: inherit;
  z-index: 1000;
  border-radius: inherit;

  &.fw-dropdown-menu__fluid {
    right: auto;

    .fw-dropdown-menu--content {
      white-space: nowrap;
    }
  }

  &.fw-dropdown-menu__layer {
    left: auto;
    right: 0;
  }

  &.fw-dropdown-menu__nowrap {
    .fw-dropdown-menu--content {
      white-space: nowrap;
    }
  }

  .fw-dropdown-menu--content {
    // border-radius: px($dropdown__radius);
    height: 100%;
    box-shadow: px(0) px(10) px(5) px(5) rgba(0, 0, 0, 0.1);
    border-radius: inherit;
    background: inherit;
    animation: menu 0.3s ease forwards;
    color: inherit;
    white-space: initial;
    line-height: initial;
    text-align: left;
  }

  @keyframes menu {
    from {
      transform: translate3d(0, 0px, 0);
    }

    to {
      transform: translate3d(0, 0px, 0);
    }
  }
}
</style>
