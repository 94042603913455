
import {
  computed,
  defineComponent,
  getCurrentInstance,
  nextTick,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "vue";

import { IN_BROWSER } from "@/fw/js/util";
import { useStack } from "@/fw/composables/stack";
import { useOverlay } from "@/fw/composables/overlay";
import { useLazyActivation } from "@/fw/composables/lazyActivation";
import { useScroll } from "@/fw/composables/scroll";
import { useWindowEventListener } from "@/fw/composables/window";

import FwOverlay from "./fw-overlay.vue";
import FwDialogFrame from "./fw-dialog-frame.vue";

export default defineComponent({
  name: "FwDialog",
  components: {
    FwOverlay,
    FwDialogFrame,
  },

  inheritAttrs: false,
  props: {
    modelValue: Boolean,

    background: {
      type: [Boolean, String],
      default: true,
    },

    borderRadius: {
      type: [Boolean, Number, String],
      default: true,
    },

    contentClass: {
      type: String,
      default: "",
    },

    depressed: Boolean,

    fullscreen: {
      type: Boolean,
      default: false,
    },

    height: {
      type: [String, Number],
      default: "auto",
    },

    local: {
      type: Boolean,
      default: false,
    },

    maxWidth: {
      type: [String, Number],
      default: "none",
    },

    noClickAnimation: Boolean,

    overlayBackground: {
      type: [Boolean, String],
      default: true,
    },

    persistent: Boolean,

    scrollable: {
      type: Boolean,
      default: true,
    },

    disableTeleport: Boolean,

    transition: {
      type: String,
      default: "fw-dialog-transition",
    },

    width: {
      type: [String, Number],
      default: "32rem",
    },

    closeOnBack: {
      type: Boolean,
      default: true,
    },

    bottom: {
      type: Boolean,
      required: false,
    },
  },

  emits: {
    //
  },

  setup(props, { emit }) {
    const overlayComponent = ref<InstanceType<typeof FwOverlay> | null>(null);
    const frameComponent = ref<InstanceType<typeof FwDialogFrame> | null>(null);

    const overlayElement = computed<Element | undefined>(() => overlayComponent.value?.$el as Element);
    const frameElement = computed<Element | undefined>(() => frameComponent.value?.$el as Element);

    const scopedModelValue = ref(false);
    const { isTop } = useStack(scopedModelValue);

    if (IN_BROWSER && props.modelValue) {
      nextTick(() => {
        scopedModelValue.value = true;
      });
    }

    watch(
      () => props.modelValue,
      (val) => {
        scopedModelValue.value = val;
      }
    );

    const onClose = () => {
      scopedModelValue.value = false;
      emit("update:modelValue", false);
    };

    const onOpen = () => {
      scopedModelValue.value = true;
      emit("update:modelValue", true);
    };

    const { activatedOnce, active: isActive } = useLazyActivation(scopedModelValue);

    const { zIndex } = useOverlay(isActive);

    const animateClick = () => {
      if (props.noClickAnimation) {
        return;
      }

      frameComponent.value?.animateClick();
    };

    useWindowEventListener(
      "keyup",
      (_event) => {
        if (_event.key === "Escape" && isTop.value) {
          if (!props.persistent) {
            onClose();
          } else {
            animateClick();
          }
        }
      },
      { passive: true }
    );

    // scroll
    const { enableScroll, disableScroll } = useScroll({
      overlay: overlayElement,
      content: frameElement,
      contentActiveClass: "fw-dialog-frame--active",
      fullscreen: props.fullscreen,
      contentFullscreenClass: "fw-dialog-frame--fullscreen",
    });

    watch(isActive, (active) => {
      if (props.local) {
        return;
      }

      if (active) {
        disableScroll();
      } else {
        enableScroll();
      }
    });

    onBeforeUnmount(() => {
      enableScroll();
    });

    // click outside
    const onClickOutside = () => {
      if (!props.persistent) {
        onClose();
      } else {
        animateClick();
      }
    };

    // close on back button
    if (IN_BROWSER && props.closeOnBack) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const instance = getCurrentInstance()!;

      let currentDialogHash = `#dialog-${instance.uid}`;

      if (window.location.hash) {
        currentDialogHash = `${window.location.hash.slice(1)}-${currentDialogHash}`;
      }

      const popstateListener = () => {
        // if (window.location.hash.includes(currentDialogHash) || !scopedModelValue.value) {
        //   return;
        // }

        // onClose();
        // console.log("close5"); // 임시
      };

      onMounted(() => {
        if (scopedModelValue.value) {
          window.location.hash = currentDialogHash;
        }

        window.addEventListener("popstate", popstateListener);
      });

      onUnmounted(() => {
        window.removeEventListener("popstate", popstateListener);
      });

      watch(scopedModelValue, (_value) => {
        if (!_value && window.location.hash.includes(currentDialogHash)) {
          // window.history.back(); // 임시 - 이거로 인해 잠금해제, 뒤로가기 비정상작동 - 만악의 근원이었음
        } else if (_value) {
          if (window.location.hash) {
            currentDialogHash = `${window.location.hash.slice(1)}#${`dialog-${instance.uid}`}`;
          } else {
            currentDialogHash = `#dialog-${instance.uid}`;
          }

          window.location.hash = `${currentDialogHash}`;
        }
      });
    }

    // activator slot
    const activatorAttrs = {
      onClick() {
        onOpen();
      },
    };

    return {
      overlayComponent,
      frameComponent,
      activatedOnce,
      zIndex,
      isActive,
      activatorAttrs,
      onClickOutside,
      onClose,
    };
  },
});
