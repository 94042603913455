<template>
  <comp-header></comp-header>
  <router-view />
  <comp-footer></comp-footer>
</template>

<script>
  import CompHeader from "@/components/navigation/header/comp__header.vue";
  import CompFooter from "@/components/navigation/footer/comp__footer.vue";

  export default {
    components: {
      CompHeader,
      CompFooter,
    }
  };
</script>

<style lang="scss">
@use "@/styles/class.scss";
</style>
