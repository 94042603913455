<template>
  <div
    ref="refListItem"
    class="fw-list-item d-flex align-center"
    :class="[isSelected ? 'fw-list-item--active' : '']"
    @click="event.click.listItem"
  >
    <div class="fw-list-item__slot d-flex align-center w-100">
      <slot></slot>
    </div>

    <div class="fw-list-item__bg" :class="`text-${getActiveColor}`" />

    <div v-if="isSelected && !hideCheckIcon" class="text-center ml-auto" v-dimension="{ minWidth: 50 }">
      <fw-icon
        v-if="!hideCheckIcon"
        class="fw-list-item__icon"
        :class="`text-${getActiveColor}`"
        size="24"
        icon="check-circle"
      ></fw-icon>
    </div>

    <div v-if="$slots.append" v-dimension="{ minWidth: 50 }">
      <slot name="append"></slot>
    </div>
  </div>
</template>

<script>
import { computed, ref, getCurrentInstance } from "vue";
import { v4 } from "uuid";

export default {
  name: "FwListItem",

  props: {
    modelValue: {},
    value: {
      type: [String, Number],
    },
    selected: {
      type: Boolean,
      default: false,
    },
    disabled: Boolean,
    activeColor: {
      type: String,
      default: "normal",
    },
    hideCheckIcon: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["click", "update:modelValue"],

  setup(props, { emit }) {
    const instance = getCurrentInstance();

    const refListItem = ref(null);

    const event = {
      click: {
        listItem: () => {
          computedModelValue.value = computedValue.value;
          emit("click");
        },
      },
    };

    const computedValue = computed(() => {
      if (props.value) {
        return props.value;
      }

      if (instance.vnode.key) {
        return instance.vnode.key;
      } else {
        return v4();
      }
    });

    const getActiveColor = computed(() => {
      if (props.selected) {
        return props.activeColor;
      }

      return "normal";
    });

    const isSelected = computed(() => {
      if (Array.isArray(props.modelValue)) {
        return props.selected || props.modelValue.includes(computedValue.value);
      }

      return props.selected || props.modelValue === computedValue.value;
    });

    const backgroundColor = computed(() => {
      return isSelected.value ? `bg-${getActiveColor.value}` : "";
    });

    const computedModelValue = computed({
      get() {
        return props.modelValue;
      },
      set(_value) {
        if (!Array.isArray(props.modelValue)) {
          emit("update:modelValue", computedValue.value);
        } else {
          if (props.modelValue.includes(_value)) {
            let arr_modelValue = [...props.modelValue].filter((_item) => _item !== _value);
            emit("update:modelValue", arr_modelValue);
          } else {
            let arr_modelValue = [...props.modelValue, _value];
            emit("update:modelValue", arr_modelValue);
          }
        }
      },
    });

    return {
      refListItem,
      event,
      isSelected,
      getActiveColor,
      backgroundColor,
    };
  },
};
</script>

<style lang="scss" scoped>
@use "@/fw/scss/util.scss" as *;
@use "@/fw_config/config.scss" as *;

.fw-list-item {
  position: relative !important;
  z-index: 1;
  background-color: $ctrl-list-item-background-color;
  color: $ctrl-list-item-font-color;
  padding: $ctrl-list-item-padding;
  min-height: $ctrl-list-item-min-height;
  border-radius: px($ctrl-list-item-border-radius);

  .fw-list-item__icon {
    color: inherit;
  }

  .fw-list-item__slot {
    z-index: 2;
  }

  &.fw-list-item--active {
    .fw-list-item__bg {
      &::before {
        z-index: 0;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        content: "";
        width: 100%;
        height: 100%;
        background: currentColor;
        opacity: $ctrl-list-item-active-opacity;
        border-radius: px($ctrl-list-item-border-radius);
        pointer-events: none;
      }
    }

    .fw-list-item__active-icon {
      display: block;
    }
  }

  &:not(.fw-list-item--active):hover {
    .fw-list-item__bg {
      &::before {
        z-index: 0;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        content: "";
        width: 100%;
        height: 100%;
        background: currentColor;
        opacity: $ctrl-list-item-hover-opacity;
        border-radius: px($ctrl-list-item-border-radius);
        pointer-events: none;
      }
    }
  }
}
</style>
