import { convertToUnit } from "@/fw/js/util";

function makeDimensionStyle(_options) {
  const dimensionStyles = {
    height: convertToUnit(_options.height),
    maxHeight: convertToUnit(_options.maxHeight),
    maxWidth: convertToUnit(_options.maxWidth),
    minHeight: convertToUnit(_options.minHeight),
    minWidth: convertToUnit(_options.minWidth),
    width: convertToUnit(_options.width),
  };

  return { dimensionStyles };
}

export default {
  name: "VDimension",
  install(_c_vue, _options) {
    _c_vue.directive("dimension", {
      mounted(_el, _binding, _vnode) {
        const values = _binding.value;
        const styles = makeDimensionStyle(values);
        Object.assign(_el.style, styles.dimensionStyles);
      },

      updated(_el, _binding, _vnode, _old_vnode) {
        const values = _binding.value;
        const styles = makeDimensionStyle(values);
        Object.assign(_el.style, styles.dimensionStyles);
      },
    });
  }
}