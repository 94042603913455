<template>
  <Field
    :id="name"
    :name="name"
    v-model="inputValue"
    :value="modelValue"
    :rules="rulesExecutor"
    :standalone="standalone"
    :validate-on-mount="immediatelyValid"
    v-slot="{ field }"
    @input="updateValue"
  >
    <div class="fw-ctrl fw-input no-hover" :class="classes">
      <div class="prepend-inner text-only mr-2" v-if="$slots['prepend-inner']">
        <slot name="prepend-inner"></slot>
      </div>
      <div class="inp-wrapper">
        <input
          ref="fw__input"
          class="inp mr-3"
          :class="coreInputClass"
          v-bind="field"
          :type="type"
          :placeholder="placeholder"
          :disabled="disabled"
          :readonly="readonly"
          autocomplete="off"
          @keyup="onKeyup"
          @keydown="onKeydown"
          @focus="onFocus"
          @blur="onBlur"
          @change="onChange"
          @keypress="onKeypress"
          @paste="onPaste"
        />
        <label class="label" :title="label" :data-title="label"></label>
      </div>

      <div v-if="clearable && !disabled && inputValue" class="append-inner clearable" @click="onClickClear">
        <fw-icon class="text-desc cursor-pointer" icon="close" size="20" />
      </div>

      <div class="append-inner text-only ml-2" v-if="$slots['append-inner']">
        <slot name="append-inner"></slot>
      </div>
    </div>

    <div v-if="showValid" class="fw-input__detail px-3 py-1">
      <transition>
        <ErrorMessage :name="name" class="d-block text-error"></ErrorMessage>
      </transition>
    </div>
  </Field>
</template>

<script>
/*
  prop 으로 받는 애들중
      디자인 과 기능으로 나뉜다.
      디자인 = ex > 아웃라인, 색, 곡률, 플레이스 홀더, 컨트롤의 크기
      기능 = validation, 숫자만 입력, 텍스트의 좌/우측 정렬, X 버튼을 누르면 인풋의 텍스트를 지우기, 빈값일 경우 플레이스 홀더를 보여주기


  1. 인풋의 텍스트의 좌/우측 정렬
  2. validation에 실패할 경우 인풋 하단에 에러메시지 출력
  3. X 버튼을 누르면 인풋의 텍스트를 지우기
  4. 숫자만 입력되로록 하기
  5. 숫자만 입력되기 기능 활성화 시
    - 3자리마다 콤마를 찍기
    - 양수만 입력되게 하기 
    - 소수점 이하 자릿수를 지정하게 그 이상 입력이 안되게 하기 
  6. 빈값일 경우 기본으로 출력될 값 지정하기
*/
import { defineComponent, onMounted, ref, reactive, toRefs, computed, watch, nextTick } from "vue";
import { Form, Field, ErrorMessage, GenericValidateFunction, useFieldError, useValidateField } from "vee-validate";
import { c_func } from "@/fw/js/functions";
import { v4 } from "uuid";

export default defineComponent({
  name: "FwInput",

  components: {
    Field,
    ErrorMessage,
  },

  props: {
    class: {
      type: String,
    },
    modelValue: {
      // v-model
      type: [String, Number],
    },
    name: {
      type: String,
      default: () => v4(),
    },
    rules: {
      type: [Function, Array, Boolean, String],
      default: () => true,
    },
    standalone: {
      type: Boolean,
      default: false,
    },
    showValid: {
      type: Boolean,
      default: false,
    },
    immediatelyValid: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    noFocus: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    disabled: Boolean,
    readonly: {
      type: Boolean,
      default: false,
    },
    // number only
    numberOnly: {
      type: Boolean,
      default: false,
    },
    precision: {
      type: Number,
      default: 10,
    },
    maxDecimal: {
      type: Number,
      default: 12,
    },
    priceFormat: {
      type: Boolean,
      default: true,
    },
    positive: {
      type: Boolean,
      default: false,
    },
    valueWhenEmpty: {
      type: String,
      default: "", // "0" or "" or null
    },
  },

  emits: [
    "input",
    "keyup",
    "keydown",
    "click",
    "click:clear",
    "update:modelValue",
    "inputValid",
    "focus",
    "blur",
    "change",
  ],

  setup(props, { emit }) {
    const fw__input = ref();

    const stateFocus = ref(false);

    const stateValid = reactive({
      inputValue: "",
      isValid: true,
    });

    const computedModelValue = computed(() => {
      return props.modelValue;
    });

    const inheritClass = computed(() => props.class);

    const classes = computed(() => {
      return [
        props.disabled ? "disabled" : "",
        stateFocus.value ? "focus" : "",
        stateValid.isValid ? "" : "fw-input__invalid",
        inheritClass.value,
      ];
    });

    const coreInputClass = computed(() => {
      return [stateValid.inputValue ? "fw-input__has-value" : ""];
    });

    // ==========================================
    // rules 실행
    const rulesExecutor = () => {
      if (Array.isArray(props.rules)) {
        let ruleCatched = props.rules.find((_rule) => typeof _rule(stateValid.inputValue) === "string");
        if (ruleCatched === undefined) {
          return true;
        }
        return ruleCatched(stateValid.inputValue);
      } else if (typeof props.rules === "function") {
        return props.rules(stateValid.inputValue);
      } else {
        return props.rules;
      }
    };

    const validate = useValidateField(props.name);
    const message = useFieldError(props.name);

    function focus() {
      fw__input.value.focus();
    }

    // 이벤트 전달
    function onKeyup() {
      emit("keyup");
    }

    function onKeydown() {
      emit("keydown");
    }

    function onFocus() {
      emit("focus");
      stateFocus.value = true;
    }

    function onBlur() {
      emit("blur");
      stateFocus.value = false;
    }

    function onChange() {
      emit("change");
    }

    function onClickClear() {
      if (props.disabled) {
        return;
      }

      let el_input = fw__input.value;
      stateValid.inputValue = "";
      el_input.value = "";
      emit("click:clear");
      emit("update:modelValue", "");
    }

    function onPaste(_event) {
      // 붙여넣기시 소수점과 숫자만 입력되도록
      let _text = _event.clipboardData.getData("text/plain");
      if (props.numberOnly) {
        _text = _text.replace(/[^0-9.]/g, "");
      }
      _event.preventDefault();
      document.execCommand("insertText", false, _text);
    }

    function onKeypress(_event) {
      // 숫자 콤마 소수점만 입력가능
      // 1. 숫자만 입력
      // 2. 소수점은 한번만 입력
      // 3. 소수점이 있을 경우 소수점 뒤는 2자리까지만 입력가능
      // 4. 소수점이 없을 경우 12자리까지만 입력가능
      // 5. 소수점을 입력한 후 0을 입력하면 0이 입력되지 않음
      // 6. 첫번째 입력이 소수점이면 0을 앞에 붙여줌
      // 7. 첫번째 입력이 0일 경우 두번째 입력이 0이 아닌 숫자일 경우 0이 입력되지 않음
      // 8. 0으로 시작하는 숫자를 입력할 경우 0이 입력되지 않음
      // 9. 0으로 시작하는 숫자를 입력할 경우 0을 제외하고 입력
      // 10. 복사 붙여넣기 가능
      // 11. 정수부에는 가격단위 콤마 삽입
      // 12. 마이너스 입력 가능
      // 13. 마이너스는 한번만 입력 가능
      // 14. 마이너스는 첫번째 입력만 가능
      // 15. positive 속성이 true일 경우 마이너스 입력 불가능
      // 16. 소수점 끝에 0이 이미 있을 경우 0 입력 불가능
      // 17. 정수부에 가격단위 콤마 삽입

      if (!props.numberOnly) {
        return;
      }

      // 숫자만 입력
      let key = _event.key;

      if (isNaN(key) && key !== "." && key !== "-") {
        _event.preventDefault();
        return;
      }

      let el_input = fw__input.value;
      let value = el_input.value;

      // 첫번째 입력이 소수점이면 0을 앞에 붙여줌
      if (key === "." && value === "") {
        el_input.value = "0.";
        _event.preventDefault();
        return;
      }

      // 소수점은 한번만 입력
      if (key === "." && value.indexOf(".") !== -1) {
        _event.preventDefault();
        return;
      }

      if (value.indexOf(".") > -1 && key !== ".") {
        // 소수점이 있을 경우 소수점 뒤는 props.precision자리까지만 입력가능
        let afterDot = value.split(".")[1];
        if (afterDot.length >= props.precision) {
          _event.preventDefault();
          return;
        }
      }

      if (value.indexOf(".") === -1 && key !== ".") {
        // 소수점이 없을 경우 콤마가 있다면 콤마를 제거하고 props.maxDecimal자리까지만 입력가능
        let noComma = value.replace(",", "");
        if (noComma.length >= props.maxDecimal) {
          _event.preventDefault();
          return;
        }
      }

      if (value.indexOf("-") > -1 && key === "-") {
        // 마이너스는 한번만 입력 가능
        _event.preventDefault();
        return;
      }

      if (value.indexOf("-") === -1 && key === "-") {
        // 마이너스는 첫번째 입력만 가능
        if (value !== "") {
          _event.preventDefault();
          return;
        }
      }

      if (props.positive && key === "-") {
        // positive 속성이 true일 경우 마이너스 입력 불가능
        _event.preventDefault();
        return;
      }
    }

    const updateValue = (_event) => {
      let target = _event.target;
      stateValid.inputValue = target.value;
      emit("update:modelValue", stateValid.inputValue);
      validate();
    };

    const inputValueSet = (_value) => {
      stateValid.inputValue = _value;
    };

    watch(
      () => stateValid.inputValue,
      (_value) => {
        if (props.priceFormat && props.numberOnly) {
          stateValid.inputValue = c_func.num.comma.insert(_value);

          emit("input", c_func.num.comma.remove(stateValid.inputValue));
          emit("update:modelValue", c_func.num.comma.remove(stateValid.inputValue));
          return;
        }

        stateValid.inputValue = _value;
        emit("input", stateValid.inputValue);
        emit("update:modelValue", _value);
      }
    );

    watch(
      () => message.value,
      (_value) => {
        stateValid.isValid = typeof _value === "string" ? false : true;
      }
    );

    watch(
      () => computedModelValue.value,
      (_value) => {
        stateValid.inputValue = _value;
      }
    );

    onMounted(() => {
      fw__input.value;
      stateValid.inputValue = props.modelValue;
    });

    return {
      fw__input,
      Form,
      Field,
      ErrorMessage,

      validate,
      message,

      ...toRefs(stateValid),

      rulesExecutor,

      updateValue,
      onKeyup,
      onKeydown,
      onFocus,
      onBlur,
      onChange,
      onPaste,
      onClickClear,
      onKeypress,
      classes,
      coreInputClass,
      inheritClass,

      inputValueSet,
      focus,
    };
  },
});
</script>

<style lang="scss">
@use "sass:map";
@use "@/fw/scss/util.scss" as *;
@use "@/fw_config/config.scss" as *;

.fw-input {
  display: flex;
  color: map.get($color-text, "normal") !important;
  border-color: $color-border !important;

  .inp-wrapper {
    flex: 1;

    .inp {
      width: 100%;
      height: 100%;
      text-align: inherit;
      background-color: transparent;
      outline: none;
      color: map.get($color-text, "normal") !important;
      border-color: $color-border !important;

      &::placeholder {
        color: $ctrl-input-placeholder;
      }
    }
  }

  &.fw-input__invalid {
    border-color: map.get($color-status, "error") !important;
  }
}
</style>
