
import { defineComponent, computed, reactive, ref, watch } from "vue";

export default defineComponent({
  props: {
    value: {
      type: String,
      default: "",
    }, // v-model

    top: {
      // 툴팁위치 top | bottom
      type: Boolean,
      default: true,
    },

    hideTooltip: {
      // 툴팁안보이기
      type: Boolean,
      default: false,
    },

    tooltipClass: {
      // 툴팁 클래스
      type: String,
      default: "",
    },

    visibleStringLength: {
      // 보이는 텍스트 길이
      type: Number,
      default: 5,
    },
  },

  setup(props, { emit }) {
    const tooltip__position = computed(() => {
      return props.top ? "top" : "bottom";
    });

    const tooltip__class = computed(() => {
      return props.tooltipClass;
    });

    const truncate__string__get = (_string: string, _number__visible = 5) => {
      if (!_string || _number__visible <= 0) {
        return "-";
      }

      if (_number__visible * 2 > _string.length) {
        return _string;
      }

      let text__start = _string.substring(0, _number__visible);
      let text__end = _string.substring(_string.length - _number__visible);

      return `${text__start}...${text__end}`;
    };

    const tooltip_options = reactive({
      content: props.value,
      placement: tooltip__position,
      tooltip_class: tooltip__class,
    });

    // 강제업데이트 (툴팁옵션)
    const updateKey = ref(0);

    const forceUpdate = () => {
      updateKey.value++;
    };

    watch(
      tooltip_options,
      () => {
        forceUpdate();
      },
      { deep: true }
    );

    watch(
      props,
      () => {
        tooltip_options.content = props.value;
        forceUpdate();
      },
      { deep: true }
    );

    return {
      truncate__string__get,
      tooltip_options,
      updateKey,
      forceUpdate,
    };
  },
});
