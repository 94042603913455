<template>
  <div v-dimension="{ height: 'inherit' }">
    <div class="l-row no-gutter">
      <div class="l-col text-left px-3 py-2">
        <span class="text-desc"> {{ $tt("comp_trades.price") }} </span>
      </div>
      <div class="l-col text-right px-3 py-2">
        <span class="text-desc"> {{ $tt("comp_trades.amount") }} </span>
      </div>
      <div class="l-col text-right px-3 py-2">
        <span class="text-desc"> {{ $tt("comp_trades.time") }} </span>
      </div>
    </div>
    <fw-scroll>
      <div class="l-row no-gutter" v-for="(trade, idx) in model.trades.raw" :key="idx">
        <div class="l-col text-left px-3 py-2">
          <fw-num-formatter
            class="text-body-xl font-weight-medium"
            :class="trade.order_way === 'buy' ? 'text-buy' : 'text-sell'"
            :value="trade.order_price"
            :precision="trade.token_precision"
          />
        </div>
        <div class="l-col text-right px-3 py-2">
          <fw-num-formatter :value="trade.order_amount" :precision="trade.token_precision" />
        </div>
        <div class="l-col text-right px-3 py-2">
          <span class="text-desc"> {{ trade.time }} </span>
        </div>
      </div>
    </fw-scroll>
    <fw-no-data v-model="is_show_no_data" />
  </div>
</template>

<script>
const obj_lang = {
  comp_trades: {
    price: {
      en: "Price",
      ko: "가격",
    },
    amount: {
      en: "Amount",
      ko: "수량",
    },
    time: {
      en: "Time",
      ko: "시간",
    },
  },
};
export default {
  name: "FwTrades",

  data() {
    return {
      model: {
        trades: {
          raw: [],
          proc: {},
        },
      },
    };
  },

  created() {
    this.$fw.lang.add(obj_lang);
  },

  computed: {
    is_show_no_data() {
      return this.model.trades.raw.length === 0;
    },
  },

  methods: {
    $_init_reset() {
      this.model.trades.raw = [];
      this.model.trades.proc = {};
    },

    $_add_trade_item(_order_way, _order_price, _token_precision, _order_amount, _time) {
      let obj_trade_item = {
        order_way: _order_way,
        order_price: _order_price,
        token_precision: _token_precision,
        order_amount: _order_amount,
        time: _time,
      };

      this.model.trades.raw.push(obj_trade_item);
    },

    ui__change_color__get(_market_change) {
      const color_rise = "text-trade-rise";
      const color_fall = "text-trade-fall";
      const color_default = "";

      let change_percent = parseFloat(_market_change);

      if (isNaN(change_percent) || change_percent == 0.0) {
        return color_default;
      }

      if (change_percent > 0.0) {
        return color_rise;
      } else {
        return color_fall;
      }
    },
  },
};
</script>
