import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d469c4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fw-datepicker-input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fw_icon = _resolveComponent("fw-icon")!
  const _component_fw_input = _resolveComponent("fw-input")!
  const _component_fw_date_picker = _resolveComponent("fw-date-picker")!

  return (_openBlock(), _createBlock(_component_fw_date_picker, {
    modelValue: _ctx.computedModelValue,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedModelValue) = $event)),
    trigger: "",
    range: _ctx.range,
    disabled: _ctx.disabled,
    "until-today": _ctx.untilToday,
    "default-period": _ctx.defaultPeriod
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_fw_input, {
          ref: "fw_datepicker_input",
          class: _normalizeClass(["no-hover", _ctx.computedClass]),
          modelValue: _ctx.inputValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
          readonly: "",
          standalone: "",
          placeholder: _ctx.placeholder,
          disabled: _ctx.disabled,
          onClick: _ctx.onClickDatePickerInput
        }, {
          "prepend-inner": _withCtx(() => [
            _createVNode(_component_fw_icon, {
              icon: "calendar-blank",
              class: "text-normal"
            })
          ]),
          _: 1
        }, 8, ["class", "modelValue", "placeholder", "disabled", "onClick"])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "range", "disabled", "until-today", "default-period"]))
}