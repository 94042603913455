<template>
  <fw-img :src="coin_image__get" v-dimension="{ maxHeight: size, maxWidth: size, height: '100%', width: '100%' }" />
</template>

<script>
let obj_lang = {
  comp_coin_img: {
    //
  },
};

export default {
  name: "FwSymbolImg",
  components: {
    //
  },

  props: {
    "asset-code": {
      type: String,
      required: true,
    },

    "folder-path": {
      type: String,
      default: "",
    },

    "fallback-img": {
      type: String,
      default: null,
    },

    size: {
      type: [Number, String],
      default: "28",
    },

    prefix: {
      type: String,
      default: "coin_",
    },
  },

  data() {
    return {
      //
    };
  },

  created() {
    this.__init();
  },

  mounted() {
    this.__loaded();
  },

  computed: {
    coin_image__get() {
      let image_src;

      let asset_code = "";

      try {
        asset_code = this.assetCode.toLowerCase();
      } catch (error) {() => {}}
      let image_name = this.prefix + asset_code;

      let folder_path;

      if (this.folderPath[this.folderPath.length - 1] == "/") {
        folder_path = this.folderPath;
      } else {
        folder_path = this.folderPath + "/";
      }

      let image_url_without_expression = folder_path + image_name;

      let symbol_default = this.symbol_load__default__get(image_url_without_expression);
      let symbol_light = this.symbol_load__light__get(image_url_without_expression);
      let symbol_dark = this.symbol_load__dark__get(image_url_without_expression);

      if (symbol_light == false || symbol_dark == false) {
        image_src = symbol_default;
      } else {
        if (this.$fw.theme.name == "dark") {
          image_src = symbol_dark;
        } else {
          image_src = symbol_light;
        }
      }

      return image_src;
    },
  },

  watch: {
    //
  },

  methods: {
    __init() {
      this.$fw.lang.add(obj_lang);
    },

    __loaded() {
      //
    },

    //===============================================================
    // ##img_load
    symbol_load__default__get(_image_url_without_expression) {
      let src_symbol_default;

      try {
        src_symbol_default = require(`@/${_image_url_without_expression}.png`);
      } catch (error) {
        if (this.fallbackImg != null) {
          src_symbol_default = this.fallbackImg;
        } else {
          src_symbol_default = require("./fw-symbol-img__fallback.png");
        }
      }

      return src_symbol_default;
    },

    symbol_load__light__get(_image_url_without_expression) {
      let src_symbol_light;

      try {
        src_symbol_light = require(`@/${_image_url_without_expression}__light.png`);
      } catch (error) {
        src_symbol_light = false;
      }

      return src_symbol_light;
    },

    symbol_load__dark__get(_image_url_without_expression) {
      let src_symbol_dark;

      try {
        src_symbol_dark = require(`@/${_image_url_without_expression}__dark.png`);
      } catch (error) {
        src_symbol_dark = false;
      }

      return src_symbol_dark;
    },
  },
};
</script>