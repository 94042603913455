<template>
  <section
    class="ps-container"
    :class="showScrollAlways"
    ref="scroll"
    @ps-scroll-y="scrollHandle"
    @ps-scroll-x="scrollHandle"
    @ps-scroll-up="scrollHandle"
    @ps-scroll-down="scrollHandle"
    @ps-scroll-left="scrollHandle"
    @ps-scroll-right="scrollHandle"
    @ps-y-reach-start="scrollHandle"
    @ps-y-reach-end="scrollHandle"
    @ps-x-reach-start="scrollHandle"
    @ps-x-reach-end="scrollHandle"
  >
    <slot></slot>
  </section>
</template>

<script>
import PerfectScrollbar from "@/fw/js/lib/perfect-scrollbar/src/index";

export default {
  props: {
    swicher: {
      type: Boolean,
      default: true,
    },

    suppressScrollX: {
      type: Boolean,
      default: false,
    },

    suppressScrollY: {
      type: Boolean,
      default: false,
    },
    alwaysShow: {
      type: Boolean,
      default: true,
    }
  },

  data() {
    return {
      ps: null,
    };
  },

  mounted() {
    setTimeout(() => {
      this.__init();
    })
  },

  updated() {
    this.$nextTick(this.update);
  },

  activated() {
    this.__init();
  },

  deactivated() {
    this.__uninit();
  },

  beforeUnmount() {
    this.__uninit();
  },

  computed: {
    showScrollAlways() {
      return this.alwaysShow? 'ps-alwaysShow' : '';
    },  
    settings() {
      return {
        suppressScrollX: this.suppressScrollX,
        suppressScrollY: this.suppressScrollY,
      };
    },
  },

  watch: {
    swicher(val) {
      if (val && !this._ps_inited) {
        this.__init();
      }
      if (!val && this._ps_inited) {
        this.__uninit();
      }
    },
    suppressScrollX() {
      this.__update();
    },

    suppressScrollY() {
      this.__update();
    },
  },

  methods: {
    __init() {
      this._ps_inited = true;
      try {
        this.ps = new PerfectScrollbar(this.$refs.scroll, this.settings);
      } catch (error) {
        //
      }
    },

    __uninit() {
      if (this.ps) {
        this.ps.destroy();
        this.ps = null;
        this._ps_inited = false;
      }
    },

    __update() {
      this.__uninit();
      this.__init();
    },

    scrollHandle(evt) {
      this.$emit(evt.type, evt);
    },
  },
};
</script>

<style lang="scss">
.ps-container {
  position: relative;
}

.ps-container.mobile {
  overflow: auto !important;
}

.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}

.ps__rail-x {
  height: 15px;
  bottom: 0;
}

.ps__rail-x,
.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  position: absolute;
  z-index: 10 !important;
}

.ps__rail-y {
  width: 15px;
  right: 0;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}

.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y,
.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y {
  opacity: 0.6;
}

.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-x:focus,
.ps .ps__rail-x:hover,
.ps .ps__rail-y.ps--clicking,
.ps .ps__rail-y:focus,
.ps .ps__rail-y:hover {
  background-color: #eee;
  opacity: 0.9;
}

.ps__thumb-x {
  transition: background-color 0.2s linear, height 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
  height: 6px;
  bottom: 2px;
}

.ps__thumb-x,
.ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  position: absolute;
}

.ps__thumb-y {
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
  width: 6px;
  right: 2px;
}

.ps__rail-x.ps--clicking .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x:hover > .ps__thumb-x {
  background-color: #999;
  height: 11px;
}

.ps__rail-y.ps--clicking .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y:hover > .ps__thumb-y {
  background-color: #999;
  width: 11px;
}

@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .ps {
    overflow: auto !important;
  }
}

.ps-container {
  position: relative;
  height: inherit !important;
}

.ps-alwaysShow {
  .ps__rail-x,
  .ps__rail-y {
    opacity: 0.6 !important;
  }
}
</style>
