<template>
  <button class="fw-ctrl fw-button filled" :disabled="disabled" @touchend="$emit('touch')">
    <slot></slot>
  </button>
</template>

<!--------------------------------------------------------------------------------------------------------------->

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "FwButton",

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    return {};
  },
});
</script>

<!--------------------------------------------------------------------------------------------------------------->

<style lang="scss">
@use "sass:map";
@use "@/fw/scss/util.scss" as *;
@use "@/fw_config/config.scss" as *;

.fw-button {
  text-align: center;
  vertical-align: middle;
  border-radius: px(map.get($border-rounded, "md")); // 기본은 normal = sm

  cursor: pointer;

  // icon
  &.icon {
    background-color: transparent !important;
    border: none;
    padding: 0px;
  }

  &.text-only {
    border: none !important;
  }
}
</style>
