/* eslint-disable */

//---------------------------------------------------------------------------------------------------//
// import
import { createI18n } from "vue-i18n";
import { s_lang__default, obj_lang__data } from "@/fw_config/i18n.js";
//---------------------------------------------------------------------------------------------------//
// logic

let obj_user_data = fn_data__conv(obj_lang__data);

const c_i18n = createI18n({
  locale: s_lang__default, // default locate
  allowComposition: true, // you need to specify that!
  messages: obj_user_data,
  warnHtmlInMessage: 'off',
  fallbackLocale: s_lang__default,
});

const lang = {
  add: (_obj_lang__data) => {
    fn_data__add(_obj_lang__data);
  },
  trans: (_key, ..._params) => c_i18n.global.t(_key, _params),
  locale_current: () => c_i18n.global.locale,
  locale_list: () => c_i18n.global.availableLocales,
  locale_change: (_locale_code) => {
    c_i18n.global.locale = _locale_code;
  },
};

function fn_data__conv(_obj_data__now, _arr_key, _obj_ret) {
  if (typeof _arr_key == "undefined") {
    _arr_key = [];
  }
  if (typeof _obj_ret == "undefined") {
    _obj_ret = new Object();
  }
  if (typeof _obj_data__now != "string") {
    if (typeof _obj_data__now != "object") {
      console.log("error");
      return;
    }
    for (const s_key in _obj_data__now) {
      _arr_key[_arr_key.length] = s_key;
      fn_data__conv(_obj_data__now[s_key], _arr_key, _obj_ret);
      _arr_key.pop();
    }
    return _obj_ret;
  }
  const s_text = _obj_data__now;
  const s_lang = _arr_key[_arr_key.length - 1];

  if (typeof _obj_ret[s_lang] == "undefined") {
    _obj_ret[s_lang] = new Object();
  }

  let obj_now_pos = _obj_ret[s_lang];
  for (let i = 0; i < _arr_key.length - 1; i++) {
    const now_category = _arr_key[i];
    if (typeof obj_now_pos[now_category] == "undefined") {
      obj_now_pos[now_category] = new Object();
    }

    if (i + 1 == _arr_key.length - 1) {
      obj_now_pos[now_category] = s_text;
    } else {
      obj_now_pos = obj_now_pos[now_category];
    }
  }
  return _obj_ret;
}

function fn_data__add(_obj_data) {
  const obj_user_data__new = fn_data__conv(_obj_data);

  for (const key in obj_user_data__new) {
    Object.assign(
      obj_user_data[key],
      obj_user_data__new[key]
    );
  }
}

const LangSymbol = Symbol.for('fw:lang');

//---------------------------------------------------------------------------------------------------//
// export

export { c_i18n, lang, LangSymbol };
