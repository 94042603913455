import FwTooltip from "./v-tooltip";
import FwRipple from "./v-ripple";
import FwClickOutside from "./v-click-outside";
import FwDimensions from "./v-dimension";
import FwResize from "./v-resize";

export default {
    install: (app: any) => { // eslint-disable-line
      app.use(FwTooltip);
      app.use(FwRipple);
      app.use(FwClickOutside);
      app.use(FwResize);
      app.use(FwDimensions);
    },
};
  