
import { onBeforeMount, defineComponent, reactive, watchEffect } from "vue";

export default defineComponent({
  props: {
    icon: {
      // 아이콘 이름  ex) mdi-check => "check"
      type: String,
      required: true,
      default: "help-circle",
    },

    size: {
      // 폰트사이즈  ex) 55, "66"
      type: [String, Number],
      default: "24",
    },

    color: {
      // 폰트컬러   ex) error, red, info
      type: String,},
  },

  setup(props) {
    onBeforeMount(() => {
      const el_icon_title = "material-icon";

      let is_already_imported = false;

      const el_links: HTMLCollection = document.getElementsByTagName("link");

      for (let i = 0; i < el_links.length; i++) {
        let node: Element = el_links[i];
        let title = node.getAttribute("title");

        if (title == el_icon_title) {
          is_already_imported = true;
        }
      }

      if (is_already_imported) {
        return;
      }

      const icon_link = document.createElement("LINK");
      icon_link.setAttribute("title", el_icon_title);
      icon_link.setAttribute("rel", "stylesheet");
      icon_link.setAttribute(
        "href",
        "https://cdn.jsdelivr.net/npm/@mdi/font@6.9.96/css/materialdesignicons.min.css"
      );
      document.head.appendChild(icon_link);
    });

    const icon__class__set = reactive({
      mdi_icon: "",
      color: "",
    });

    const icon__style__set = reactive({
      fontSize: "",
      color: "",
      verticalAlign: 'middle',
    });

    watchEffect(() => {
      icon__class__set.mdi_icon = `mdi-${props.icon}`;
      icon__style__set.fontSize = `${props.size}px !important`;
      icon__style__set.color = `${props.color}`;
    });

    return {
      icon__class__set,
      icon__style__set,
    };
  },
});
