<template>
  <div class="fw-ctrl fw-toggle-switch text-only" :class="classes" @click="onChange">
    <input
      type="checkbox"
      v-model="computedModelValue"
      :value="value"
      :checked="computedIsChecked"
      :id="labelId"
      :disabled="disabled"
    />

    <label :for="labelId">
      <div class="switch">
        <div class="dot"></div>
      </div>
      <span v-if="label" class="label ml-2">{{ label }}</span>
    </label>
  </div>
</template>
<script>
import { defineComponent, computed } from "vue";
import { v4 as uuid } from "uuid";

export default defineComponent({
  name: "FwSwitch",
  props: {
    modelValue: { type: [Array, Boolean] },
    value: {},
    label: {
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["change", "update:modelValue"],

  setup(props, { emit }) {
    const labelId = `fw-toggle-switch-${uuid()}`;

    const classes = computed(() => {
      if (Array.isArray(props.modelValue)) {
        return [props.modelValue.includes(props.value) ? "checked" : "not-checked"];
      } else {
        if (isModelValueBoolean()) {
          return [props.modelValue ? "checked" : "not-checked"];
        } else {
          return [props.modelValue == props.value ? "checked" : "not-checked"];
        }
      }
    });

    function isModelValueBoolean() {
      return typeof props.modelValue === "boolean";
    }

    const computedModelValue = computed({
      get() {
        return props.modelValue;
      },
      set(value) {
        emit("update:modelValue", value);
      },
    });

    function onChange() {
      emit("update:modelValue", props.modelValue);
      emit("change", props.modelValue);
    }

    const computedIsChecked = computed(() => {
      let value = props.value;
      let modelValue = props.modelValue;
      let isChecked = false;

      if (Array.isArray(modelValue)) {
        if (modelValue.includes(value)) {
          isChecked = true;
        } else {
          isChecked = false;
        }
      } else {
        isChecked = modelValue;
      }

      return isChecked;
    });

    return {
      labelId,
      computedModelValue,
      computedIsChecked,
      classes,
      onChange,
    };
  },
});
</script>

<style lang="scss">
@use "sass:map";
@use "@/fw/scss/util.scss" as *;
@use "@/fw_config/config.scss" as *;

.fw-toggle-switch {
  position: relative;
  transition: transform 0.3s;
  background-color: transparent !important;
  border: none !important;

  label {
    cursor: pointer;
  }

  &.not-checked {
    color: map.get($color-status, "normal") !important;
  }

  &.checked {
    input + label {
      color: inherit !important;
      --offset: 18px;
      --gradient: 0.3;
    }
  }

  .label {
    color: map.get($color-text, "normal");
  }

  input {
    display: none;

    & + label {
      display: flex;
      align-items: center;
      border-radius: inherit;

      .switch {
        position: relative;
        display: inline-block;
        z-index: 1;
        vertical-align: top;
        height: rem(14);
        width: rem(34);
        border-radius: inherit;
        background: rgba(map.get($color-status, "normal"), 0.2);
        color: currentColor;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          border-radius: inherit;
          background: currentColor;
          opacity: var(--gradient, 0);
          transition: opacity 0.4s;
        }

        .dot {
          background: currentColor;
          position: absolute;
          width: rem(20);
          height: rem(20);
          border-radius: 50%;
          left: -1px;
          top: -3px;
          transform: translateX(var(--offset, 0));
          transition: transform 0.4s, box-shadow 0.4s;

          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            border-radius: inherit;
            opacity: var(--gradient, 0);
            transition: opacity 0.4s;
          }
        }
      }
    }
  }
}
</style>
