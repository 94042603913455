<template>
  <article class="fw-snackbar-message" :class="[messageClass, borderClass]">
    <div class="fw-snackbar-message-wrapper">
      <div class="fw-snackbar-message-icon mr-2">
        <fw-icon :icon="computedIconName" size="24" />
      </div>
      <div class="fw-snackbar-message-content">
        <div class="fw-snackbar-message-title">
          {{ message.title || message.text }}
        </div>
        <div v-if="message.title && message.text" class="fw-snackbar-message-additional">
          {{ message.text }}
        </div>
      </div>
      <div class="spacer"></div>
      <div class="fw-snackbar-message-close cursor-pointer">
        <fw-icon icon="close" size="24" @click="dismissClick" />
      </div>
    </div>
  </article>
</template>

<script>
import { defineComponent, computed, onMounted, watch, ref } from "vue";
import { FwIcon } from "@/fw/components";

export default defineComponent({
  components: {
    FwIcon,
  },

  props: {
    borderClass: {
      type: String,
      default: "",
    },
    message: {
      type: Object,
      default: () => ({}),
    },
    messageClass: {
      type: String,
      default: "",
    },
    dense: {
      type: Boolean,
      default: false,
    },
    background: {
      default: "info",
    },
  },

  emits: ["dismiss"],

  setup(props, { emit }) {
    let timeout = null,
      shakeTimeout = null;

    let hasShake = ref(false);

    const setMessageTimeout = () => {
      const messageDuration = !props.message.duration && !props.message.dismissible ? 4000 : props.message.duration;
      timeout = setTimeout(dismiss, messageDuration);
    };

    onMounted(() => {
      setMessageTimeout();
    });

    watch(
      () => props.message.count,
      (v) => {
        if (v === 1) return false;
        clearTimeout(timeout);
        clearTimeout(shakeTimeout);
        shakeTimeout = setTimeout(() => {
          hasShake = false;
        }, 1000);
        hasShake = true;
        setMessageTimeout();
      }
    );
    const dismissClick = () => {
      if (timeout) clearTimeout(timeout);
      dismiss();
    };
    const dismiss = () => {
      emit("dismiss", props.message);
    };

    const computedIconName = computed(() => {
      const types = {
        success: "check",
        info: "information",
        warning: "alert",
        error: "alert",
      };

      const icon = types[props.message.type];
      return icon;
    });

    return {
      hasShake,
      computedIconName,
      dismissClick,
    };
  },
});
</script>
<style lang="scss"></style>
