import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createSlots as _createSlots, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-center justify-center" }
const _hoisted_2 = { class: "font-weight-bold text-body-lg pt-1" }
const _hoisted_3 = { class: "font-weight-bold text-body-lg pt-1" }
const _hoisted_4 = { class: "font-weight-medium text-body-md" }
const _hoisted_5 = { class: "dp__cell_inner__text_wrapper d-flex justify-center align-center w-100 h-100" }
const _hoisted_6 = { class: "dp__cell_inner__text text-body-md" }
const _hoisted_7 = { class: "d-flex align-center justify-end pa-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fw_button = _resolveComponent("fw-button")!
  const _component_Datepicker = _resolveComponent("Datepicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Datepicker, {
      ref: "fw_datepicker",
      modelValue: _ctx.computedModelValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedModelValue) = $event)),
      enableTimePicker: false,
      format: _ctx.dateFormatGet,
      range: _ctx.range,
      disabled: _ctx.disabled,
      maxDate: _ctx.maxDateSet,
      clearable: false,
      inline: !_ctx.trigger
    }, _createSlots({
      month: _withCtx(({ text }) => [
        _createElementVNode("div", _hoisted_2, _toDisplayString(text), 1)
      ]),
      year: _withCtx(({ year }) => [
        _createElementVNode("div", _hoisted_3, _toDisplayString(year), 1)
      ]),
      "calendar-header": _withCtx(({ day }) => [
        _createElementVNode("div", _hoisted_4, _toDisplayString(day), 1)
      ]),
      day: _withCtx(({ day }) => [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, _toDisplayString(day), 1)
        ])
      ]),
      "action-select": _withCtx(() => [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_fw_button, {
            class: "size-sm mr-2",
            onClick: _ctx.onClickCancel
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$tt("date_picker.cancel")), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_fw_button, {
            class: "status-primary size-sm",
            onClick: _ctx.onClickOk
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$tt("date_picker.ok")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _: 2
    }, [
      (_ctx.trigger)
        ? {
            name: "trigger",
            fn: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["modelValue", "format", "range", "disabled", "maxDate", "inline"])
  ]))
}