import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, mergeProps as _mergeProps, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "fw-checkbox-card__label l-row align-center no-wrap" }
const _hoisted_2 = { class: "fw-checkbox-card__em" }
const _hoisted_3 = { class: "fw-checkbox-card__content" }
const _hoisted_4 = { class: "fw-checkbox-card__content" }
const _hoisted_5 = {
  key: 0,
  class: "fw-checkbox-card__valid_detail px-3 py-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fw_icon = _resolveComponent("fw-icon")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createBlock(_component_Field, {
    type: "checkbox",
    modelValue: _ctx.inputValue,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inputValue) = $event)),
    id: _ctx.name,
    name: _ctx.name,
    value: true,
    rules: _ctx.rulesExecutor,
    "unchecked-value": false
  }, {
    default: _withCtx(({ field }) => [
      _createElementVNode("div", {
        class: _normalizeClass(["l-card fw-checkbox-card", _ctx.classes])
      }, [
        _createElementVNode("label", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass(["fw-checkbox-card__checkbox d-flex justify-center align-center pa-0", _ctx.reverse ? 'ml-3' : 'mr-3'])
          }, [
            _withDirectives(_createVNode(_component_fw_icon, { icon: _ctx.iconCheckbox }, null, 8, ["icon"]), [
              [_vShow, _ctx.iconIsShow || _ctx.onIconNone || _ctx.offIconNone]
            ]),
            _createElementVNode("input", _mergeProps({
              type: "checkbox",
              class: "d-none checkbox fw-checkbox__core",
              value: true
            }, field, {
              onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)), ["prevent"]))
            }), null, 16),
            _withDirectives(_createElementVNode("em", _hoisted_2, null, 512), [
              [_vShow, !_ctx.iconIsShow]
            ])
          ], 2),
          _createElementVNode("span", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, [
              _renderSlot(_ctx.$slots, "default")
            ])
          ])
        ])
      ], 2),
      (_ctx.showValid)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_Transition, null, {
              default: _withCtx(() => [
                _createVNode(_component_ErrorMessage, {
                  name: _ctx.name,
                  class: "d-block text-error"
                }, null, 8, ["name"])
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["modelValue", "id", "name", "rules"]))
}