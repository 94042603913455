<template>
  <fw-button
    v-if="!iconOnly"
    class=""
    :class="buttonClass"
    @click="onClickCopy"
    v-tooltip.top.notrigger="{
      visible: ui.tooltip.is_show,
      content: $tt('btn_copy.copied'),
    }"
  >
    <fw-icon icon="content-copy" v-if="!textOnly" size="16" class="mr-2" />
    {{ $tt("btn_copy.copy") }}
  </fw-button>

  <fw-icon
    v-if="iconOnly"
    icon="content-copy"
    size="16"
    class="cursor-pointer text-desc"
    @click="onClickCopy"
    v-tooltip.top.notrigger="{
      visible: ui.tooltip.is_show,
      content: $tt('btn_copy.copied'),
    }"
  />
</template>

<script>
import { reactive, toRefs } from "vue";
import { c_func } from "@/fw/js/functions";

const obj_lang = {
  btn_copy: {
    copied: {
      en: "Copied",
      ko: "복사되었습니다",
    },
    copy: {
      en: "Copy",
      ko: "복사",
    },
  },
};

export default {
  name: "FwCopyButton",

  props: {
    target: {
      type: [String, Array],
      required: true,
    },

    iconOnly: {
      type: Boolean,
      default: false,
    },

    textOnly: {
      type: Boolean,
      default: false,
    },

    buttonClass: {
      type: String,
      default: "text-black rounded-lg size-sm px-3 ",
    },
  },

  created() {
    this.$fw.lang.add(obj_lang);
  },

  emits: ["click", "copy:success", "copy:error"],

  setup(props, { emit }) {
    const state = reactive({
      ui: {
        tooltip: {
          is_show: false,
        },
      },
    });

    const onClickCopy = () => {
      emit("click");

      try {
        c_func.util.copy_to_clipboard(props.target);
        state.ui.tooltip.is_show = true;
        emit("copy:success");
        setTimeout(() => {
          state.ui.tooltip.is_show = false;
        }, 2000);
      } catch (error) {
        console.log(error);
        emit("copy:error", error);
      }
    };

    return {
      ...toRefs(state),
      onClickCopy,
    };
  },
};
</script>
