<template>
  <fw-dialog v-model="computedIsShow" :fullscreen="$fw.display.smAndDown">
    <div class="l-card d-flex flex-column bg-layer-1 pa-1" v-dimension="{ height: '100%' }">
      <div class="d-flex align-center bg-layer-1 pa-5">
        <p class="text-title-xs font-weight-bold">
          {{ $tt(`transfer_detail.transfer_detail`) }}
        </p>
        <div class="l-spacer" />
        <fw-icon icon="close" class="cursor-pointer" color="font" @click="event.click.close" />
      </div>
      <div class="bg-layer-1 pa-5 mb-4">
        <div class="d-flex align-center align-start flex-wrap mb-2">
          <span
            class="text-title-sm font-weight-bold"
            v-html="$tt(`transfer_detail.${model.open_order.order_way}_asset`, model.open_order.token_name)"
          >
          </span>

          <fw-text-card
            class="px-1 ml-auto"
            :value="model.open_order.order_status"
            :color="status_chip__color__set"
            size="normal"
            contentClass="text-capitalize"
          />
        </div>

        <span class="text-desc text-body-lg">
          <!-- {{ date__format__change__list(model.open_order.order_time) }} -->
          {{ model.open_order.order_time }}
        </span>
      </div>

      <div class="bg-layer-1 text-body-xl line-height-1 mb-4">
        <div class="d-flex align-center flex-wrap no-bg pa-5" background="layer1">
          <span class="text-desc">
            {{ $tt("transfer_detail.market") }}
          </span>

          <div class="l-spacer" />

          <span class="text-body-xl font-weight-bold text-uppercase">
            {{ model.open_order.token_code }} -
            {{ model.open_order.market_code }}
          </span>
        </div>

        <div v-if="!is_receive__get" class="d-flex align-center flex-wrap no-bg pa-5" background="layer1">
          <span class="text-desc mr-2" v-if="model.open_order.order_type == 'limit'">
            {{ $tt("transfer_detail.price") }}
          </span>
          <span class="text-desc mr-2" v-else>
            {{ $tt("transfer_detail.price_avg") }}
          </span>

          <div class="l-spacer" />

          <fw-num-formatter
            class="text-body-xl"
            :value="model.open_order.order_price"
            :precision="10"
            :unit="model.open_order.order_way == 'buy' ? model.open_order.token_code : model.open_order.market_code"
            unitClass="font-weight-bold"
          />
        </div>

        <div v-if="!is_receive__get" class="d-flex align-center flex-wrap no-bg pa-5" background="layer1">
          <span class="text-body-xl text-desc mr-2">
            {{ $tt("transfer_detail.total_paid") }}
          </span>

          <div class="l-spacer" />

          <fw-num-formatter
            class="text-body-xl"
            :value="model.open_order.total_price"
            :precision="10"
            :unit="model.open_order.order_way == 'buy' ? model.open_order.token_code : model.open_order.market_code"
            unitClass="font-weight-bold"
          />
        </div>

        <div v-if="!is_receive__get" class="d-flex align-center flex-wrap no-bg pa-5" background="layer1">
          <span class="font-weight-bold mr-2">
            {{ $tt("transfer_detail.received") }}
          </span>

          <div class="l-spacer" />

          <fw-num-formatter
            class="text-body-xl"
            :value="model.open_order.order_amount"
            :precision="10"
            :unit="model.open_order.order_way == 'buy' ? model.open_order.token_code : model.open_order.market_code"
            unitClass="font-weight-bold"
          />
        </div>
      </div>

      <div class="bg-layer-1 flex-grow-1 pt-5 px-5 pb-4">
        <div background="layer2" class="rounded-md pa-4 mb-2">
          <div class="mb-3">
            <span class="text-desc text-body-lg"> Tx hash </span>
          </div>

          <div class="d-flex align-center">
            <div class="text-body-lg word-break-all mr-3">
              <span class="text-primary">
                {{ address__prepend__get(model.open_order.tx_hash) }}
              </span>
              <span>
                {{ address__center__get(model.open_order.tx_hash) }}
              </span>
              <span class="text-primary">
                {{ address__append__get(model.open_order.tx_hash) }}
              </span>
            </div>

            <fw-copy-button :target="model.open_order.tx_hash"></fw-copy-button>
          </div>
        </div>

        <div class="text-right">
          <fw-button
            class="border-none pl-3 pr-1"
            size="sm"
            color="primary"
            outlined
            @click="button__click__view_rigoscan"
          >
            {{ $tt("transfer_detail.view_on_rigoscan") }}
            <fw-icon icon="chevron-right"></fw-icon>
          </fw-button>
        </div>
      </div>

      <div class="flex-grow-1 bg-layer-1"></div>

      <div class="l-row l-row--dense bg-layer-1 flex-grow-0 pa-4">
        <div class="l-col">
          <fw-button class="status-primary size-lg text-truncate w-100" @click="button__click__done">
            <span class="text-body-xl font-weight-medium">
              {{ $tt("transfer_detail.done") }}
            </span>
          </fw-button>
        </div>
      </div>
    </div>
  </fw-dialog>
</template>

<script>
import { defineComponent, reactive, toRefs, computed } from "vue";
import { format } from "date-fns";

let obj_lang = {
  transfer_detail: {
    transfer_detail: {
      en: "Transfer Detail",
      ko: "트랜잭션 내역",
    },
    buy_asset: {
      en: "Buy {0}",
      ko: "{0} 매수",
    },
    sell_asset: {
      en: "Sell {0}",
      ko: "{0} 매도",
    },
    market: {
      en: "Market",
      ko: "마켓",
    },
    price: {
      en: "Price",
      ko: "가격",
    },
    price_avg: {
      en: "Price avg",
      ko: "매수평균가",
    },
    order_amount: {
      en: "Order Amount",
      ko: "주문 수량",
    },
    pending: {
      en: "Pending",
      ko: "대기중",
    },
    cancel_order: {
      en: "Cancel Order",
      ko: "주문 취소",
    },
    view_on_rigoscan: {
      en: "View on Rigoscan",
      ko: "Rigoscan에서 보기",
    },
    done: {
      en: "Done",
      ko: "확인",
    },
    total_paid: {
      en: "Total Paid",
      ko: "총 금액",
    },
    received: {
      en: "Received",
      ko: "거래수량",
    },
  },
};

const TRANSFER_STATUS = {
  ONGOING: "ongoing",
  COMPLETE: "complete",
};

const TRANSFER_SIDE = {
  SEND: "send",
  RECEIVE: "receive",
};

export default defineComponent({
  name: "CompWalletTransferDetails",

  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.$fw.lang.add(obj_lang);
  },

  emits: ["update:model Value", "click:done", "click:cancel", "click:view_rigoscan", "click:network_fee_info"],

  setup(props, { emit }) {
    const state = reactive({
      model: {
        open_order: {
          seq: "", // 주문번호
          token_code: "", // 토큰 코드
          market_code: "", // 마켓 코드
          order_way: "", // 주문 방향
          order_type: "", // 주문 타입
          order_price: "", // 주문 가격
          order_amount: "", // 주문 수량
          pending_amount: "", // 주문 수량
          fee: "", // 수수료
          tx_hash: "", // 거래 해시
          total_price: "", // 주문 총액
          order_status: "", // 주문 상태
          order_time: "", // 주문 시간
        },
      },
    });

    const computedIsShow = computed({
      get: () => {
        return props.modelValue;
      },
      set: (value) => {
        emit("update:modelValue", value);
      },
    });

    //==============================================================================
    // ##이벤트
    const button__click__done = () => {
      computedIsShow.value = false;
    };
    const button__click__view_rigoscan = () => {
      // emit("click:view_rigoscan", state.tx_hash);
    };
    const icon__click__network_fee_info = () => {
      emit("click:network_fee_info");
    };

    const button__click__cancel_order = () => {
      emit("click:cancel", state.model.open_order);
    };

    const event = {
      click: {
        close: () => {
          computedIsShow.value = false;
        },
      },
    };

    //==============================================================================
    // ##사용함수
    const set_transfer_details = (
      _seq, // 주문번호
      _token_code, // 토큰 코드
      _token_name, // 토큰 이름
      _market_code, // 마켓 코드
      _order_way, // 주문 방향
      _order_type, // 주문 타입
      _order_price, // 주문 가격
      _order_amount, // 주문 수량
      _pending_amount, // 주문 수량
      _fee, // 수수료
      _tx_hash, // 거래 해시
      _total_price, // 주문 총액
      _order_status, // 주문 상태
      _order_time // 주문 시간
    ) => {
      state.model.open_order.seq = _seq;
      state.model.open_order.token_code = _token_code;
      state.model.open_order.token_name = _token_name;
      state.model.open_order.market_code = _market_code;
      state.model.open_order.order_way = _order_way;
      state.model.open_order.order_type = _order_type;
      state.model.open_order.order_price = _order_price;
      state.model.open_order.order_amount = _order_amount;
      state.model.open_order.pending_amount = _pending_amount;
      state.model.open_order.fee = _fee;
      state.model.open_order.tx_hash = _tx_hash;
      state.model.open_order.total_price = _total_price;
      state.model.open_order.order_status = _order_status;
      state.model.open_order.order_time = _order_time;
    };

    //==============================================================================
    // ##transfer_details

    // 시간표기 변경
    const date__format__change__list = (_date) => {
      // ex) "2022-03-22 22:33:22"
      if (!_date) {
        return "-";
      }

      let str_date = new Date(_date);

      return format(str_date, "yyyy-MM-dd HH:mm:ss");
    };

    // ## 상태 판별
    const is_complete__get = computed(() => {
      if (state.model.open_order.order_status == TRANSFER_STATUS.COMPLETE) {
        return true;
      } else {
        return false;
      }
    });

    const is_receive__get = computed(() => {
      return state.model.open_order.order_way == TRANSFER_SIDE.RECEIVE;
    });

    // ## 테이블 출력
    const status_chip__color__set = computed(() => {
      if (is_complete__get.value) {
        return "success";
      } else {
        return "info";
      }
    });

    // ## tx, address 텍스트 앞/중간/뒤 절단
    const address__prepend__get = (_address) => {
      if (!_address || _address.length <= 10) {
        return "";
      }

      return _address.slice(0, 5);
    };

    const address__append__get = (_address) => {
      if (!_address || _address.length <= 10) {
        return "";
      }

      return _address.slice(-5);
    };

    const address__center__get = (_address) => {
      if (!_address || _address.length <= 10) {
        return _address;
      }

      return _address.slice(5, -5);
    };

    return {
      event,
      ...toRefs(state),
      button__click__done,
      button__click__view_rigoscan,
      button__click__cancel_order,
      icon__click__network_fee_info,

      set_transfer_details,

      is_receive__get,
      computedIsShow,

      status_chip__color__set,
      date__format__change__list,

      address__prepend__get,
      address__append__get,
      address__center__get,
    };
  },
});
</script>

<style lang="scss" scoped>
.word-break-all {
  word-break: break-all;
}
.line-height-1 {
  line-height: 1;
}
</style>
