<template>
  <fw-dialog v-model="visible" :width="width" :height="height">
    <div class="fw-dialog-simple pa-5 pa-md-9">
      <div class="d-flex align-center mb-5">
        <fw-icon v-if="icon.name != ''" class="mr-2" :icon="icon.name" :color="icon.color" size="24" />
        <p class="text-title-sm font-weight-bold mb-0" v-html="title"></p>
      </div>

      <div class="mb-8">
        <p class="text-body-lg" v-html="text"></p>
      </div>

      <div v-if="btn_left_text != ''" class="d-flex align-center justify-end">
        <fw-button class="size-lg mr-4" @click="__button__click__left">
          {{ btn_left_text }}
        </fw-button>
        <fw-button class="size-lg status-primary" @click="__button__click__right">
          {{ btn_right_text }}
        </fw-button>
      </div>

      <div v-else class="d-flex align-center justify-end">
        <fw-button class="size-lg status-primary" @click="__button__click__right">
          {{ btn_right_text }}
        </fw-button>
      </div>
    </div>
  </fw-dialog>
</template>

<script>
import { defineComponent } from "vue";

import { EventBus } from "@/fw/js/util";

let obj_lang = {
  simple_modal: {
    confirm: {
      en: "Confirm",
      ko: "확인",
    },
  },
};

export default defineComponent({
  name: "FwSimpleDialog",
  data() {
    return {
      visible: false,

      title: "",
      text: "",

      width: "",
      height: "",

      overlay_opacity: "",

      btn_left_text: "",
      btn_right_text: "",

      background_color: "",

      icon: {
        name: "",
        color: "",
      },

      transition: "",
    };
  },

  mounted() {
    EventBus.$on("show", (_options) => {
      this.$emit("show");
      this.__show(_options);
    });

    EventBus.$on("hide", () => {
      this.$emit("hide");
      this.__hide();
    });
  },

  beforeUnmount() {
    EventBus.$off("show");
    EventBus.$off("hide");
  },

  created() {
    this.$fw.lang.add(obj_lang);
  },

  watch: {
    visible(_is_show) {
      if (_is_show) {
        this.popup__detect_back_button__regist();
      } else {
        this.__reset();
        this.popup__detect_back_button__remove();
      }
    },
  },

  methods: {
    __hide() {
      this.visible = false;
    },

    __reset() {
      //
    },

    __button__click__left() {
      if (typeof this.click_left == "function") {
        this.click_left();
        this.__hide();
      } else {
        this.__hide();
      }
    },

    __button__click__right() {
      if (typeof this.click_right == "function") {
        this.click_right();
        this.__hide();
      } else {
        this.__hide();
      }
    },

    __show(_options) {
      this.visible = true;

      this.title = _options.title;
      this.text = _options.text;
      this.btn_left_text = _options.btn_left_text || "";
      this.btn_right_text = _options.btn_right_text || this.$tt("simple_modal.confirm");

      this.width = _options.width || "29rem";
      this.height = _options.height || "auto";
      this.background_color = _options.background_color || "bg-layer-1";

      this.overlay_opacity = _options.overlay_opacity || 0.7;

      this.transition = _options.transition || "";

      this.click_left = _options.click_left || "";
      this.click_right = _options.click_right || "";

      if (typeof _options.icon == "undefined") {
        this.icon = {
          name: "",
          color: "",
        };
      } else {
        this.icon = {
          name: _options.icon.name || "",
          color: _options.icon.color || "primary",
        };
      }
    },

    //===============================================================
    // ##popup
    popup__detect_back_button__regist() {
      window.history.pushState("popup-open", null, "");

      window.onpopstate = () => {
        this.is_show = false;
      };
    },

    popup__detect_back_button__remove() {
      if (history.state == "popup-open") {
        window.history.back();
      }
    },
  },
});
</script>

<style lang="scss">
@use "@/fw_config/config.scss" as *;

.fw-dialog-simple {
  background-color: $ctrl-dialog-simple-background-color;
  border-radius: px($ctrl-dialog-simple-border-radius);
  color: $ctrl-dialog-simple-font-color;
}
</style>
