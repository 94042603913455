
import { defineComponent, onMounted, ref, reactive, toRefs, computed, watch } from "vue";
import { Form, Field, ErrorMessage, GenericValidateFunction, useFieldError, useValidateField } from "vee-validate";
import { v4 } from "uuid";

export default defineComponent({
  name: "FwTextarea",

  components: {
    Field,
    ErrorMessage,
  },

  props: {
    class: {
      type: String,
    },
    modelValue: {
      type: [String, Number],
    },
    name: {
      type: String,
      default: () => v4(),
    },
    rules: {
      type: [Function, Array, Boolean, String],
      default: () => true,
    },
    rows: {
      type: [String, Number],
      default: 3,
    },
    showValid: {
      type: Boolean,
      default: false,
    },
    immediatelyValid: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    noFocus: {
      type: Boolean, // focus 효과 없애기
      default: false,
    },
    placeholder: {
      type: String,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    resize: {
      type: Boolean,
      default: false,
    },
    disabled: Boolean,
  },

  emits: ["input", "keyup", "keydown", "click", "update:modelValue", "inputValid", "focus", "blur", "change"],

  setup(props, { emit }) {
    const fw__textarea = ref();

    const stateFocus = ref(false);

    const stateValid = reactive({
      inputValue: "",
      isValid: true,
    });

    const computedModelValue = computed(() => {
      return props.modelValue;
    });

    const inheritClass = computed(() => props.class);

    const classes = computed(() => {
      return [
        stateValid.isValid ? "" : "fw-textarea__invalid",
        stateFocus.value && !props.noFocus ? "focus" : "",
      ];
    });

    const coreTextareaClasses = computed(() => {
      return [stateValid.inputValue ? "fw-textarea__has-value" : ""];
    });

    const coreTextareaStyles = computed(() => {
      return {
        resize: props.resize ? "vertical" : "none",
      } as any;
    });

    // ==========================================
    // rules 실행
    const rulesExecutor = (): any | GenericValidateFunction => {
      if (Array.isArray(props.rules)) {
        let ruleCatched: any = props.rules.find((_rule: any) => typeof _rule(stateValid.inputValue) === "string");
        if (ruleCatched === undefined) {
          return true;
        }
        return ruleCatched(stateValid.inputValue);
      } else if (typeof props.rules === "function") {
        return props.rules(stateValid.inputValue);
      } else {
        return props.rules;
      }
    };

    const validate = useValidateField(props.name);
    const message = useFieldError(props.name);

    function focus() {
      fw__textarea.value.focus();
    }

    // 이벤트 전달
    function onKeyup() {
      emit("keyup");
    }

    function onKeydown() {
      emit("keydown");
    }

    function onFocus() {
      emit("focus");
      stateFocus.value = true;
    }

    function onBlur() {
      emit("blur");
      stateFocus.value = false;
    }

    function onChange() {
      emit("change");
    }

    function onClickClear() {
      if (props.disabled) {
        return;
      }

      let el_input = fw__textarea.value as unknown as HTMLTextAreaElement;
      stateValid.inputValue = "";
      el_input.value = "";
      emit("update:modelValue", "");
    }

    const updateValue = (_event: InputEvent): void => {
      let target = _event.target as HTMLTextAreaElement;

      stateValid.inputValue = target.value;

      if (props.name.length !== 36) {
        validate();
      }
      emit("update:modelValue", target.value);
      emit("input", target.value);
    };

    watch(
      () => message.value,
      (_value) => {
        stateValid.isValid = typeof _value === "string" ? false : true;
      }
    );

    watch(
      () => computedModelValue.value,
      (_value) => {
        stateValid.inputValue = _value as string;
      }
    );

    onMounted(() => {
      fw__textarea.value;
      stateValid.inputValue = props.modelValue as string & number;
    });

    return {
      fw__textarea,
      Form,
      Field,
      ErrorMessage,

      validate,
      message,

      ...toRefs(stateValid),

      rulesExecutor,

      updateValue,
      onKeyup,
      onKeydown,
      onFocus,
      onBlur,
      onChange,
      onClickClear,
      inheritClass,

      classes,
      coreTextareaClasses,
      coreTextareaStyles,

      focus,
    };
  },
});
