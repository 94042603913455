
import { defineComponent, ref, onMounted, computed } from "vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { format } from "date-fns";

let obj_lang = {
  date_picker: {
    cancel: {
      en: "Cancel",
      ko: "취소",
    },

    ok: {
      en: "OK",
      ko: "선택",
    },
  },
};

export default defineComponent({
  name: "FwDatePicker",

  components: {
    Datepicker,
  },

  created() {
    this.$fw.lang.add(obj_lang);
  },

  props: {
    modelValue: {
      // v-model
      type: [String, Array], // range가 true일경우 [], false일 경우 ""
      default: "",
    },

    range: {
      // 기간 선택
      type: Boolean,
      default: false,
    },

    disabled: {
      // disabled
      type: Boolean,
      default: false,
    },

    untilToday: {
      // 오늘 이후는 선택 불가
      type: Boolean,
      default: false,
    },

    defaultPeriod: {
      // 기간 디폴트값 ( range )
      type: Number,
      default: 6,
    },

    trigger: {
      // input 관련
      type: Boolean,
      default: false,
    },
  },

  emits: ["update:modelValue"],

  setup(props, { emit }) {
    const fw_datepicker = ref();
    const computedModelValue = computed({
      get() {
        return props.modelValue;
      },
      set(_value: any) {
        if (!Array.isArray(_value)) {
          return emit("update:modelValue", format(_value, "yyyy-MM-dd"));
        }

        if (!_value[1]) {
          return emit("update:modelValue", [format(_value[0], "yyyy-MM-dd")]);
        } else {
          return emit("update:modelValue", [format(_value[0], "yyyy-MM-dd"), format(_value[1], "yyyy-MM-dd")]);
        }
      },
    });

    // 이벤트
    const onClickOk = () => {
      fw_datepicker.value.selectDate();
    };

    const onClickCancel = () => {
      fw_datepicker.value.closeMenu();
    };

    // 인풋 날짜형식 format - ex) 10 Nov, 22
    const dateFormatGet = (_date: Date | Date[]) => {
      if (!_date) {
        console.error("Please Select a date.");
        return "-";
      }

      if (!Array.isArray(_date)) {
        return format(_date, "dd MMM, yy");
      }

      if (!_date[1]) {
        return `${format(_date[0], "dd MMM, yy")} ~ ${format(_date[0], "dd MMM, yy")}`;
      } else {
        return `${format(_date[0], "dd MMM, yy")} ~ ${format(_date[1], "dd MMM, yy")}`;
      }
    };

    // maxDate
    const maxDateSet = computed(() => {
      if (props.untilToday) {
        return new Date();
      } else {
        return undefined;
      }
    });

    // default period
    const dateDefaultRangeSet = () => {
      if (computedModelValue.value.length === 0) {
        let date_start = new Date();
        date_start.setDate(date_start.getDate() - props.defaultPeriod);

        let str_date_start = format(date_start, "yyyy-MM-dd");
        let str_date_today = format(new Date(), "yyyy-MM-dd");

        if (str_date_start === str_date_today) {
          emit("update:modelValue", [str_date_start]);
        } else {
          emit("update:modelValue", [str_date_start, str_date_today]);
        }
      }
    };

    const dateDefaultDateSet = () => {
      if (!computedModelValue.value) {
        let str_date_today = format(new Date(), "yyyy-MM-dd");
        emit("update:modelValue", str_date_today);
      }
    };

    onMounted(() => {
      fw_datepicker.value;

      if (props.range) {
        dateDefaultRangeSet();
      } else {
        dateDefaultDateSet();
      }
    });

    return {
      fw_datepicker,
      computedModelValue,

      dateFormatGet,
      maxDateSet,

      onClickOk,
      onClickCancel,
    };
  },
});
