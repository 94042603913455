<template>
  <div class="fw-loader text-only no-hover">
    <Transition name="loading">
      <div v-if="defaultLayoutIsShow && computedModelValue">
        <slot>
          <div class="d-inline-flex">
            <div class="rotating-animation">
              <fw-icon icon="loading"></fw-icon>
            </div>
          </div>
        </slot>
      </div>
    </Transition>

    <fw-overlay v-if="typeLayoutIsShow" v-model="computedModelValue">
      <slot>
        <div class="d-inline-flex">
          <div class="rotating-animation">
            <fw-icon icon="loading" color="font-contrast"></fw-icon>
          </div>
        </div>
      </slot>
    </fw-overlay>

    <div ref="observation_target">
      <div
        v-if="typeScrollIsShow && computedModelValue"
        class="d-flex justify-center align-center"
        v-dimension="{
          height: 60,
        }"
      >
        <slot>
          <div class="rotating-animation">
            <fw-icon icon="loading"></fw-icon>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, toRefs, computed, onMounted, onUnmounted } from "vue";
// import { useFw } from "@/fw";
export default defineComponent({
  name: "FwLoader",
  components: {
    //
  },
  props: {
    modelValue: {
      type: Boolean,
    },
    type: {
      // ["overlay", "scroll", ]
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const observation_target = ref();

    const state = reactive({
      ui: {
        scroll: {
          io: null,
        },
      },
    });
    // v-model
    const computedModelValue = computed({
      get() {
        return props.modelValue;
      },
      set(_val) {
        emit("update:modelValue", _val);
      },
    });
    const io = {
      set: () => {
        state.ui.scroll.io = new IntersectionObserver(io.callback, { threshold: 0.7 });
        state.ui.scroll.io.observe(observation_target.value);
      },
      callback: (_entries) => {
        _entries.forEach((_entry) => {
          if (_entry.isIntersecting) {
            emit("scroll:reach-y-end");
          }
        });
      },
      disconnect: () => {
        state.ui.scroll.io.disconnect();
      },
    };
    const typeScrollIsShow = computed(() => {
      return props.type === "scroll";
    });
    const typeLayoutIsShow = computed(() => {
      return props.type === "overlay";
    });
    const defaultLayoutIsShow = computed(() => {
      return !typeLayoutIsShow.value && !typeScrollIsShow.value;
    });
    onMounted(() => {
      observation_target.value;
      io.set();
    });
    onUnmounted(() => {
      io.disconnect();
    });
    return {
      ...toRefs(state),
      observation_target,
      computedModelValue,
      typeScrollIsShow,
      typeLayoutIsShow,
      defaultLayoutIsShow,
    };
  },
});
</script>

<style lang="scss" scoped>
.fw-loader {
  background-color: transparent !important;
  border: none !important;
}
.rotating-animation {
  animation: rotating 0.7s linear infinite;
}
@keyframes rotating {
  100% {
    transform: rotate(360deg);
  }
}
.loading-enter-active,
.loading-leave-active {
  transition: all 0.5s ease;
}
.loading-enter-from,
.loading-leave-to {
  opacity: 0;
}
</style>
