
import { computed, defineComponent } from "vue";

import { useSizeStyle } from "@/fw/composables/sizeStyle";

function convertToUnit(str: string | number | null | undefined, unit = "px"): string | undefined {
  if (str == null || str === "") return undefined;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  else if (isNaN(+str!)) return String(str);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  else if (!isFinite(+str!)) return undefined;
  else return `${Number(str)}${unit}`;
}

export default defineComponent({
  name: "DialogContent",
  props: {
    background: {
      type: [Boolean, String],
      required: true,
    },

    borderRadius: {
      type: [Boolean, Number, String],
      required: true,
    },

    class: {
      type: String,
      required: true,
    },

    depressed: {
      type: Boolean,
      required: true,
    },

    fullscreen: Boolean,

    height: {
      type: [String, Number],
      required: true,
    },

    maxWidth: {
      type: [String, Number],
      required: true,
    },

    scrollable: {
      type: Boolean,
      required: true,
    },

    width: {
      type: [String, Number],
      required: true,
    },
  },

  setup(props) {
    const { sizeStyles: styles } = useSizeStyle(props);
    const classes = computed(() => [
      "fw-dialog-content",
      props.class,
      {
        "fw-dialog-content--scrollable": props.scrollable,
        "fw-dialog-content--depressed": props.depressed,
        "fw-dialog-content--fullscreen": props.fullscreen,
      },
    ]);

    const computedBackground = computed(() => {
      if (typeof props.background === "string") return props.background;
      else if (props.background) return "var(--content-bg)";

      return "transparent";
    });

    const computedBorderRadius = computed(() => {
      if (typeof props.borderRadius === "string") return convertToUnit(props.borderRadius);
      else if (props.borderRadius) return "var(--content-border-radius)";

      return "0";
    });

    return {
      styles,
      classes,
      computedBackground,
      computedBorderRadius,
    };
  },
});
