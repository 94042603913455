 <template>
  <fw-dialog v-model="is_show">
    <div class="l-card d-flex flex-column pa-4 pt-6">
      <fw-symbol-img
        class="mx-auto mb-5"
        :asset-code="model.order_info.token_code"
        :folder-path="pathSymbol"
        size="80"
      />

      <div class="text-center mb-2">
        <span
          class="text-title-md font-weight-bold"
          v-html="
            model.order_info.order_way == 'buy'
              ? $tt(
                  'order_confirm.buy_asset',
                  model.order_info.token_name
                )
              : $tt(
                  'order_confirm.sell_asset',
                  model.order_info.token_name
                )
          "
        >
        </span>
      </div>

      <div class="text-center mb-5">
        <span class="text-body-lg text-uppercase">
          {{ model.order_info.token_code }} / {{ model.order_info.market_code }}
        </span>
      </div>

      <div class="l-card bg-layer-3 pa-4 mb-8">
        <div class="d-flex flex-wrap align-center justify-space-between mb-6">
          <span class="text-body-lg text-desc">
            {{ $tt("order_confirm.price") }}
          </span>
          <span v-if="model.order_info.order_type == 'limit'" class="text-body-lg">
            {{ $fw.func.num.comma.insert(model.order_info.order_price) }}

            <span class="font-weight-bold text-uppercase ml-1">
              {{ model.order_info.market_code }}
            </span>
          </span>
          <span v-else class="text-body-lg">
            Market Price
          </span>
        </div>

        <div class="d-flex flex-wrap align-center justify-space-between mb-6">
          <span class="text-body-lg text-desc">
            {{ $tt("order_confirm.amount") }}
          </span>
          <span class="text-body-lg text-uppercase">
            {{ $fw.func.num.comma.insert(model.order_info.order_amount) }}

            <span class="font-weight-bold text-uppercase ml-1">
              {{ model.order_info.token_code }}
            </span>
          </span>
        </div>

        <div class="d-flex flex-wrap align-center justify-space-between mb-6">
          <span class="text-body-lg text-desc">
            {{ $tt("order_confirm.trade_fee") }}
          </span>
          <span class="text-body-lg text-uppercase">
            {{ $fw.func.num.comma.insert(model.order_info.order_fee) }}

            <span class="font-weight-bold text-uppercase ml-1">
              {{ model.order_info.market_code }}
            </span>
          </span>
        </div>

        <div class="d-flex flex-wrap align-center justify-space-between">
          <span class="text-body-lg">
            {{ $tt("order_confirm.total_pay") }}
          </span>
          <span class="text-body-lg text-uppercase">
            {{ $fw.func.num.comma.insert(total__get) }}

            <span class="text-uppercase font-weight-bold ml-1">
              {{ model.order_info.market_code }}
            </span>
          </span>
        </div>
      </div>

      <div class="l-spacer" />

      <div>
        <div class="l-row no-guter gap-2 flex-nowrap">
          <div class="l-col">
            <fw-button block size="large" @click="__button__click__cancel">
              {{ $tt("order_confirm.cancel") }}
            </fw-button>
          </div>
          <div class="l-col">
            <fw-button
              class="font-weight-bold"
              color="primary"
              block
              size="large"
              @click="__button__click__continue"
            >
              {{ $tt("order_confirm.continue") }}
            </fw-button>
          </div>
        </div>
      </div>
    </div>
  </fw-dialog>
</template>

<script>

let obj_lang = {
  order_confirm: {
    buy_asset: {
      en: `Buy <span class="text-uppercase">{0}</span>`,
      ko: `<span class="text-uppercase">{0}</span> 매수`,
    },

    sell_asset: {
      en: `Sell <span class="text-uppercase">{0}</span>`,
      ko: `<span class="text-uppercase">{0}</span> 매도`,
    },

    market: {
      en: "Market",
      ko: "마켓",
    },

    type: {
      en: "Type",
      ko: "주문 구분",
    },

    price: {
      en: "Price",
      ko: "가격",
    },

    amount: {
      en: "Amount",
      ko: "수량",
    },

    trade_fee: {
      en: "Est Fee",
      ko: "수수료",
    },

    total_pay: {
      en: "Total Pay",
      ko: "총 금액",
    },

    cancel: {
      en: "Cancel",
      ko: "취소",
    },

    continue: {
      en: "Continue",
      ko: "계속",
    },
  },
};

export default {
  props: {
    modelValue: Boolean,
    "path-symbol": {
      type: String,
      default: "",
    },
    "order-confirm-info": Object,
  },

  data() {
    return {
      model: {
        order_info: {
          token_code: "-",
          token_name: "-",
          market_code: "-",
          order_way: "-", // buy, sell
          order_type: "-", // limit, market
          order_price: "-",
          order_amount: "-",
          order_fee: "-",
          order_total: "-",
        },
      },
    };
  },

  created() {
    this.__init();
  },

  mounted() {
    this.__loaded();
  },

  computed: {
    is_show: {
      get() {
        return this.modelValue;
      },
      set(_value) {
        this.$emit("update:modelValue", _value);
      },
    },

    total__get() {
      let total = "-";
      let total_with_out_fee = this.model.order_info.order_total;
      let fee = this.model.order_info.order_fee;

      try {
        let b_total_with_out_fee = this.$fw.big(total_with_out_fee);
        let b_fee = this.$fw.big(fee);

        total = b_total_with_out_fee.plus(b_fee).toString();
      } catch (error) {
        //
      }

      return total;
    },
  },

  watch: {
    is_show(_value) {
      if (_value) {
        this.order_info__init();
      } else {
        this.order_info__reset();
      }
    },
  },

  methods: {
    __init() {
      this.$fw.lang.add(obj_lang);
    },

    __loaded() {
      //
    },

    __button__click__cancel() {
      this.is_show = false;
    },

    __button__click__continue() {
      this.$emit("click:continue");
    },

    order_info__init() {
      this.model.order_info = {
        token_code: this.orderConfirmInfo.token_code,
        token_name: this.orderConfirmInfo.token_name,
        market_code: this.orderConfirmInfo.market_code,
        order_way: this.orderConfirmInfo.order_way,
        order_type: this.orderConfirmInfo.order_type,
        order_price: this.orderConfirmInfo.order_price,
        order_amount: this.orderConfirmInfo.order_amount,
        order_fee: this.orderConfirmInfo.order_fee,
        order_total: this.orderConfirmInfo.order_total,
      };
    },

    order_info__reset() {
      this.model.order_info = {
        token_code: "-",
        token_name: "-",
        market_code: "-",
        order_way: "-",
        order_type: "-",
        order_price: "-",
        order_amount: "-",
        order_fee: "-",
        order_total: "-",
      };
    },
  },
};
</script>
